import React, { useState, createContext, useContext } from 'react';

const ResponseErrorContext = createContext();

export const ProvideResponseError = ({ children }) => {
  const error = useProvideResponseError();
  return (
    <ResponseErrorContext.Provider value={error}>
      {children}
    </ResponseErrorContext.Provider>
  );
};

export const useResponseError = () => {
  return useContext(ResponseErrorContext);
};

const useProvideResponseError = () => {
  const [error, setError] = useState(null);
  const removeError = () => setError(null);
  const addError = (message) => setError({ message });

  return {
    error,
    removeError,
    addError,
  };
};
