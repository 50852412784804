import React, { useState } from 'react';
import { Button, Alert, AlertTitle, TextField } from '@mui/material';
import Papa from 'papaparse';
import * as yup from 'yup';

// Validation schema using Yup
const validationSchema = yup.object().shape({
    user_email: yup.string().email('Invalid email format').required('Email is required'),
    user_first_name: yup.string().required('First name is required'),
    user_last_name: yup.string().required('Last name is required'),
    dependent_first_name: yup.string().required('Dependent first name is required'),
    profile_treehouse_unique_id: yup.string().required('Unique ID is required'),
    relationship_to_kid: yup.string().required('Relationship is required')
});

const InviteBulkUpload = () => {
    const [csvData, setCsvData] = useState([]);
    const [errors, setErrors] = useState([]);
    const [file, setFile] = useState(null);

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file);
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    setCsvData(results.data);
                    setErrors([]);  // Clear errors on new file upload
                }
            });
        }
    };

    const validateData = async () => {
        const errorList = [];
        for (const row of csvData) {
            try {
                await validationSchema.validate(row, { abortEarly: false });
            } catch (err) {
                errorList.push({
                    row,
                    errors: err.errors
                });
            }
        }
        setErrors(errorList);
    };

    const handleSubmit = () => {
        validateData().then(() => {
            if (errors.length === 0) {
                console.log("All data is valid. Ready to submit!");
                // Call API to submit data
            } else {
                console.log("There are validation errors.");
            }
        });
    };

    return (
        <div>
            <h2>Bulk Upload CSV</h2>
            <input type="file" accept=".csv" onChange={handleFileUpload} />
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!file}>
                Validate & Submit
            </Button>

            {/* Show validation errors */}
            {errors.length > 0 && (
                <div>
                    {errors.map((error, index) => (
                        <Alert severity="error" key={index} style={{ marginTop: '10px' }}>
                            <AlertTitle>Error in Row {index + 1}</AlertTitle>
                            {error.errors.map((errMsg, i) => (
                                <div key={i}>{errMsg}</div>
                            ))}
                        </Alert>
                    ))}
                </div>
            )}
        </div>
    );
};

export default InviteBulkUpload;
