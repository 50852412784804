import React from 'react';
import { Spinner, Button } from 'react-bootstrap';
import './scss/load.button.scss';

function LoadButton({
  text,
  isLoading,
  isDisabled = false,
  className = '',
  variant = 'primary',
  type = 'submit',
  spinnerSize = 'sm',
  testid = '',
  onClick = undefined,
}) {
  return (
    <>
      <Button
        className={`load-button-container ${className}`}
        variant={variant}
        type={type}
        disabled={isDisabled}
        data-testid={testid}
        onClick={onClick}
      >
        <Spinner
          as="span"
          className={`${isLoading ? 'spin' : 'd-none'}`}
          animation="border"
          size={spinnerSize}
          role="status"
          aria-hidden="true"
        />
        <span className={`${isLoading ? 'invisible' : ''}`}>{text}</span>
      </Button>
    </>
  );
}

export default LoadButton;
