import React, { useState } from "react";
import MinPromisePopup from "./min_promise/MinPromisePopup";

const MinPromise = () => {
    const [showPopup, setShowPopup] = useState(true);
    return (
        <>
            <MinPromisePopup showModal={showPopup} setShowModal={setShowPopup} />
            <h1>20 Min promise</h1>
        </>
    )
}

export default MinPromise;