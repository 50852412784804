import React, { useState, useEffect, useContext, createContext } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { getSession, removeSession } from '../../utils/session.utils';
import { isCampCaptain, isCampMaster, isDependent, isPartner, isSchool } from '../../utils/utils';
import { authViewModel } from './auth.view.model';
import {
  saveDependentUsernameToLocalStorage,
  removeDependentUsernameFromLocalStorage,
} from '../../utils/local.storage.utils';
import { HOME_FEED, KID_LOGIN_ROUTE, LOGIN_ROUTE, PARTNER_LOGIN, SCHOOL_LOGIN } from '../../constants/routes.constants';
import APIError from '../api/api.error';
import {
  fetchInternetStatus,
  removeSessionAndRefresh,
} from '../api/api.error.handler';
import { useCreationHolder } from '../context/creation/creation.holder.context';
import { actionTypes } from '../context/creation/creation.reducer';
import { partnerViewModel } from '../views/school/partner.view.model';
import { schoolViewModel } from '../views/school/school.view.model';
import { getStagingCredentials } from '../../utils/config.utils';
const authContext = createContext();

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = () => {
  const [guardian, setGuardian] = useState(null);
  const [dependent, setDependent] = useState(null);
  const [school, setSchool] = useState(null);
  const [partner, setPartner] = useState(null);
  const [campMaster, setCampMaster] = useState(null);
  const [staging, setStaging] = useState(localStorage.getItem('staging'));
  const [activatedDependents, setActivatedDependents] = useState(null);
  const [deactivatedDependents, setDeactivatedDependents] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetch, setIsFetch] = useState(true);
  const history = useHistory();
  const { dispatchCreationHolder } = useCreationHolder();
  const saveDependentUser = (dependent) => {
    saveDependentUsernameToLocalStorage(dependent.username);
    setDependent(dependent);
  };

  const clearUser = () => {
    removeSession();
    removeDependentUsernameFromLocalStorage();
    localStorage.removeItem('set_dependent');
    localStorage.removeItem('selected_dependent');
    //ensure batch update on async
    ReactDOM.unstable_batchedUpdates(() => {
      dispatchCreationHolder({ type: actionTypes.CLEAR });
      setDependent(null);
      setGuardian(null);
      setSchool(null);
      setPartner(null);
    });
  };

  const clearDependent = () => {
    setDependent(null);
  };

  const clearGuardian = () => {
    setGuardian(null);
  };

  const setUser = (user) => {
    if (isSchool(user.user_type) || isCampCaptain(user?.user_type)) {
      setSchool(user)
      clearGuardian();
      clearDependent();
    } else if (isPartner(user.user_type) || isCampMaster(user.user_type)) {
      setPartner(user);
      clearGuardian();
      clearDependent();
    } else if (isDependent(user.user_type)) {
      setDependent(user);
      clearGuardian();
    } else {
      setGuardian(user);
      setActivatedDependents(getActivatedDependents(true, user));
      setDeactivatedDependents(getActivatedDependents(false, user));
      clearDependent();
    }
  };

  const guardianLogin = (params) => {
    return new Promise((resolve, reject) => {
      fetchAPI(authViewModel.guardianLogin, params)
        .then((user) => {
          ReactDOM.unstable_batchedUpdates(() => {
            setUser(user);
            resolve();
          });
        })
        .catch((error) => reject(error));
    });
  };

  const dependentLogin = (params) => {
    return new Promise((resolve, reject) => {
      fetchAPI(authViewModel.dependentLogin, params)
        .then((user) => {
          ReactDOM.unstable_batchedUpdates(() => {
            setUser(user);
            localStorage.setItem('checkin_modal', true);
            resolve();
          });
        })
        .catch((error) => reject(error));
    });
  };

  const setGuardianDependents = (dependents) => {
    const newGuardian = { ...guardian, dependents: dependents };
    setGuardian(newGuardian);
  };

  const getGuardianDependents = (ids) => {
    const dependents = guardian.dependents;
    return dependents.filter((dependent) =>
      ids.find((id) => dependent.id === parseInt(id, 10))
    );
    // return result;
  };

  const getActivatedDependents = (activated = true, user = guardian) => {
    return user.dependents.filter(
      (dependent) => dependent.profile.is_activated === activated
    );
  };

  const logout = (loc = school ? SCHOOL_LOGIN : partner ? PARTNER_LOGIN : dependent ? KID_LOGIN_ROUTE : LOGIN_ROUTE, state = {}) => {
    authViewModel.logout().finally(() => {
      clearUser();
      history.push({ pathname: loc, state: state });
    });
  };

  const fetchAPI = (apiCall, ...rest) => {
    return new Promise((resolve, reject) => {
      setIsFetch(true);
      apiCall(...rest)
        .then((result) => {
          resolve(result);
        })
        .catch((apiError) => {
          //APIError is created if response is returned from server
          if (!(apiError instanceof APIError)) {
            return fetchInternetStatus();
          }
          //remove session on unauth and forbidden codes
          if ([401, 403].includes(apiError.statusCode)) {
            removeSessionAndRefresh();
          } else {
            reject(apiError);
          }
        })
        .catch((internetStatusError) => {
          reject(internetStatusError);
        })
        .finally(setIsFetch(false));
    });
  };

  const refreshUserState = () => {
    setIsLoading(true);
    if (getSession()) {
      authViewModel
        .getUser()
        .then((user) => {
          ReactDOM.unstable_batchedUpdates(() => {
            setUser(user);
            setIsLoading(false);
          });
        })
        .catch(() => {
          ReactDOM.unstable_batchedUpdates(() => {
            clearUser();
            // refreshSchoolUserState();
            setIsLoading(false);
          });
        });
    } else {
      setIsLoading(false);
    }
  };

  const refreshSchoolUserState = () => {
    setIsLoading(true);
    if (getSession()) {
      authViewModel
        .getSchoolUser()
        .then((user) => {
          ReactDOM.unstable_batchedUpdates(() => {
            setUser(user);
            setIsLoading(false);
          });
        })
        .catch(() => {
          ReactDOM.unstable_batchedUpdates(() => {
            clearUser();
            setIsLoading(false);
          });
        });
    } else {
      setIsLoading(false);
    }
  };

  const schoolLogin = (params) => {
    return new Promise((resolve, reject) => {
      fetchAPI(schoolViewModel.schoolLogin, params)
        .then((user) => {
          ReactDOM.unstable_batchedUpdates(() => {
            setUser(user);
            resolve();
          });
        })
        .catch((error) => reject(error));
    });
  };

  const partnerLogin = (params) => {
    return new Promise((resolve, reject) => {
      fetchAPI(partnerViewModel.partnerLogin, params)
        .then((user) => {
          ReactDOM.unstable_batchedUpdates(() => {
            setUser(user);
            resolve();
          });
        })
        .catch((error) => reject(error));
    });
  };

  const stagingLogin = (params) => {
    return new Promise((resolve, reject) => {
      const credential = getStagingCredentials()
      if(params.username === credential.userName && params.password === credential.password) {
        setStaging(true)
        localStorage.setItem('staging', true)
        resolve();
      } else {
        const error = {
          message: 'Invalid username or password'
        }
        reject(error);
      }
    });
  }

  useEffect(() => {
    refreshUserState();
  }, []);

  return {
    guardian,
    dependent,
    activatedDependents,
    deactivatedDependents,
    isLoading,
    isFetch,
    school,
    partner,
    staging,
    campMaster,
    setStaging,
    logout,
    fetchAPI,
    saveDependentUser,
    refreshUserState,
    refreshSchoolUserState,
    dependentLogin,
    guardianLogin,
    setUser,
    clearDependent,
    clearGuardian,
    setGuardianDependents,
    getActivatedDependents,
    getGuardianDependents,
    clearUser,
    setGuardian,
    schoolLogin,
    partnerLogin,
    stagingLogin,
    setCampMaster
  };
};
