import React, { useState, useMemo, useEffect } from 'react';
import { Row, Col, Form, Container } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FormLabel from '../../common/FormLabel';
import FormInput from '../../common/FormInput';
import ErrorLabel from '../../common/ErrorLabel';
import { ERROR_REQUIRED_PASSWORD } from '../../../constants/error.constants';
import { displayFormErrorsFromApi } from '../../api/api.display.error';
import FormPasswordInput from '../../common/FormPasswordInput';
import ResponseErrorNotification from '../../api/ResponseErrorNotification';
import { useResponseError } from '../../api/response.error';
import ValidHttpStatusError from '../../api/api.error.valid';
import '../account/scss/login.scss';
import LoadButton from '../../common/LoadButton';
import { GoCheck } from '@react-icons/all-files/go/GoCheck'
import { HOME_FEED, MY_SCHOOL_ROUTE, ON_BOARDING_PARTNER, FORGOT_PASSWORD_ROUTE } from '../../../constants/routes.constants';
import { partnerLoginErrors } from '../../../constants/error.constants';
import { useAuth } from '../../auth/Auth';
import '../account/scss/kid.login.scss';
import EmptyHeader from '../../header/EmptyHeader';
import partnerImg from '../../../assets/images/camp-captain.svg';
import CampGirl from '../../../assets/images/girl-login.svg'
import CapmFire from '../../../assets/images/camp-login-object.svg'
import { FaMapMarkerAlt } from "react-icons/fa";

const SchoolLogin = () => {
  const { schoolLogin, dependent, guardian } = useAuth();

  useEffect(() => {
    // changeBodyClassName([bg.GRADIENT]);
    window.scrollTo(0, 0);
    if (dependent || guardian) {
      history.push(HOME_FEED);
    }
  }, []);

  const { register, handleSubmit, setError, errors } = useForm({
    mode: 'onBlur',
  });
  const location = useLocation();
  const defaultEmail = useMemo(() => location?.state?.verified_email);
  const { addError, removeError } = useResponseError();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const onSubmit = (data) => {
    setIsLoading(true);
    schoolLogin(data).then((res) => {
      history.push(MY_SCHOOL_ROUTE);
    }).catch((err) => {
      if (err instanceof ValidHttpStatusError) {
        displayFormErrorsFromApi(err, setError);
      } else {
        addError(err.message);
      }
      setIsLoading(false);
    })
  };

  return (
    // <div className='guardian-login-container login-container login-container-wrapper'>
    <div className='camp-captain-login-bg partner-signup-wrapper partner-login-wrapper login-container-wrapper'>
      <EmptyHeader />
      <div className="mobile-extra-images">
        <img className='camp-login-girl' src={CampGirl} alt='Camp login image' />
        <img className='camp-login-fire' src={CapmFire} alt='Camp login image' />
      </div>
      <Container className="login-container" fluid="md">
        <div className='welcome-back-title'>
          <h5>Welcome back!</h5>
        </div>
        <div className="camp-captain-location">
          <FaMapMarkerAlt /> TaleTree Village
        </div>
        <div className={`login-form-wrapper school-login camp-captain-login`}>
          <div className="guardian-ic-wrapper">
            <div className="guardian-ic">
              <img src={partnerImg} alt='guardian' />
            </div>
          </div>
          <div className="title text-center">Camp Captain Login</div>
          <div className="subTitle text-center">Login to your Camp account</div>
          <ResponseErrorNotification />
          <Row className="input-wrapper mt-5">
            <Col>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                  <Form.Group className="container-fluid">
                    <FormLabel>
                      Email
                      <span>*</span>
                    </FormLabel>
                    {defaultEmail &&
                      <FormLabel className="float-right text-theme">
                        E-Mail validated <span className='support-lable-check'><GoCheck /></span>
                      </FormLabel>
                    }
                    <FormInput
                      defaultValue={defaultEmail}
                      name="username"
                      placeholder="Type email"
                      data-testid="username"
                      ref={register({ required: partnerLoginErrors.username.required })}
                      className="email-color"
                    />
                    <ErrorLabel errors={errors} name="username" as="label" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group className="container-fluid mb-0">
                    <FormLabel>
                      Password<span>*</span>
                    </FormLabel>
                    <FormPasswordInput
                      name="password"
                      placeholder="Password"
                      data-testid="password"
                      ref={register({ required: ERROR_REQUIRED_PASSWORD })}
                    />
                    <ErrorLabel errors={errors} name="password" as="label" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <div className="forgot-password">
                    <Link to={{
                      pathname: FORGOT_PASSWORD_ROUTE,
                      state: {
                        from: 'school'
                      }
                    }}>
                      Forgot password?
                    </Link>
                  </div>
                </Form.Row>
                <Form.Row>
                  <div className="remember-me">
                    <input
                      defaultChecked
                      type="checkbox"
                      name="remember_me"
                      ref={register()}
                    />
                    &nbsp; Remember me
                  </div>
                </Form.Row>
                <Form.Row className="login-submit">
                  <Form.Group className="container-fluid mb-0">
                    <LoadButton
                      text="Login"
                      isDisabled={isLoading}
                      isLoading={isLoading}
                      className="login-btn p-2 gred-btn"
                      testid="submit"
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            </Col>
          </Row>
        </div>
        <Row className="dont-have-acc">
          Don’t have an account? <Link to={ON_BOARDING_PARTNER}> Sign up here</Link>
        </Row>
      </Container>
    </div>
  );
};

export default SchoolLogin;