import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Container, Spinner } from 'react-bootstrap';
import DashboardHeader from '../header/DashboardHeader';
import { useHistory } from 'react-router-dom';
import { Box, Card, CardContent, Divider, Link } from '@mui/material';
import ReactTimeAgo from 'react-time-ago';
import { insightsViewModel } from '../views/dashboard/insights.view.model';
import InfiniteScroll from 'react-infinite-scroller';
import BinkiesIcon from '../../assets/images/Binkies-icon.svg';
import ComplimentIcon from '../../assets/images/Compliment-icon.svg';
import { HiOutlineChevronLeft } from '@react-icons/all-files/hi/HiOutlineChevronLeft';
import { GUARDIAN_SETTINGS_ROUTE } from '../../constants/routes.constants';
import { getCommentUserProfileUrl, getUserProfileUrl } from '../../utils/camp.utils';
import "./binkiesCompliments.css";
import { getUserTypeBadge } from '../../utils/utils';
import ScrollToTop from '../common/ScrollToTop';
import PartnerHeader from '../views/school/PartnerHeader';
import SeeAllWrapper from './SeeAllWrapper';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: 8,
        padding: 2,
        boxShadow: 'none'
    },
    card: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 1,
        padding: 1,
        borderRadius: 8,
        boxShadow: 'none'
    },
    avatar: {
        marginRight: 2,
    },
    content: {
        flex: '1 0 auto',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2,
    },
    message: {
        display: 'inline-block',
        padding: '0.5, 1',
        borderRadius: 4,
    },
    icon: {
        marginRight: '21px',
        paddingLeft: '15px',
        paddingBottom: '5px'
    },
}));

const commentColors = [
    '#ffd29f', '#C0FECF', '#F2F6AB', '#D6D5FD', "#B3E5FE", "#9CF2F1", "#FDE988", "#ffd6fa", "#d6fbff", "#ddd6ff"
]

export default function SeeAllMostCommented() {
    const classes = useStyles();
    const history = useHistory();
    const dependent = JSON.parse(history?.location?.state?.dependent)
    const type = history?.location?.state?.type
    const _PAGE_LIMIT = 5
    const [compliments, setCompliments] = useState([])

    const [query, setQuery] = useState({
        limit: _PAGE_LIMIT,
        has_more: true,
        dependent_id: dependent?.id
    });

    const getComplimentsReceived = () => {
        const API = type === 'Comment' ? insightsViewModel.dependentMostCommentedCreation : insightsViewModel.dependentMostBinkiedCreation
        API(query, compliments).then((res) => {
            ReactDOM.unstable_batchedUpdates(() => {
                setCompliments(res.commentList.map((item, i) => {
                    const colorIndex = i % 10;
                    item.colorIndex = colorIndex;
                    return item;
                }));
                setQuery(res.query)
            })
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleGobackClick = () => {
        history.push(GUARDIAN_SETTINGS_ROUTE);
    }

    const onRedirect = (user) => {
        if (user.user_type === 'dependent') history.push(`/${user.username}`)
    }

    const returnDependentProfile = (user) => {
        const kid = {
            profile: {
                picture_url: user?.picture_url,
                taletree_friend: user?.taletree_friend
            }
        }
        return kid
    }

    return (
        <SeeAllWrapper>
            <Box className={`${classes.root} short_second_card`}>
                <Box className={classes.header}>
                    <Typography variant="h6" className='compliments-received'>
                        <img src={type === 'Comment' ? ComplimentIcon : BinkiesIcon} alt="Comment Icon" className={classes.icon} />
                        {type}
                    </Typography>
                </Box>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={getComplimentsReceived}
                    hasMore={query.has_more}
                    threshold={150}
                    className="home-feed-spinner-wrapper"
                    loader={
                        <Spinner
                            key={0}
                            className="home-feed-spinner"
                            animation="border"
                            variant="info"
                        />
                    }
                >
                    {compliments.map((compliment, index) => (
                        <React.Fragment key={index}>
                            <Divider />
                            <Card className={classes.card}>
                                <Avatar
                                    src={
                                        compliment?.user?.picture_url ? compliment.user.picture_url :
                                            compliment?.user?.user_type === 'dependent' ? getUserProfileUrl(returnDependentProfile(compliment?.user)) : getCommentUserProfileUrl(compliment?.user)
                                    }
                                    className={classes.avatar}
                                />
                                <CardContent className={classes.content}>
                                    <Typography variant="body2" color="textSecondary">
                                        From: {type === 'Given' ? <Link style={{ cursor: 'pointer', color: '#1976d2' }} onClick={() => onRedirect(compliment?.creation?.user)}>{compliment?.creation?.user?.username}</Link> : <Link style={{ cursor: 'pointer', color: '#1976d2' }} onClick={() => onRedirect(compliment?.creation?.user)}>
                                            {compliment?.user?.user_type === 'dependent' ? compliment?.user?.username : `${compliment?.user?.first_name} ${compliment?.user?.last_name}`}
                                            &nbsp;
                                            {compliment?.user?.user_type === 'school_teacher' ||
                                                compliment?.user?.user_type === 'school' ||
                                                compliment?.user?.user_type === 'camp master' ||
                                                compliment?.user?.user_type === 'partner' ?
                                                <button className="blue-admin-button">{getUserTypeBadge(compliment?.user?.user_type)}</button>
                                                : ''}
                                        </Link>}
                                        {type === 'Comment' &&
                                            <span className='message-wrapper' style={{ marginLeft: '15px', "backgroundColor": commentColors[compliment.colorIndex] }}>{compliment.text}</span>
                                        }
                                    </Typography>
                                    <Typography className="time-compliments" variant="caption" color="textSecondary">
                                        <ReactTimeAgo date={compliment.created_at} />
                                    </Typography>
                                </CardContent>
                            </Card>
                        </React.Fragment>
                    ))}
                </InfiniteScroll>
            </Box>
        </SeeAllWrapper>
    );
}
