import React, { useEffect, useState } from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import './css/taletree.header.scss';

const TaletreeHeader = (props) => {
  const [y, setY] = useState(window.scrollY);
  const [show, setShow] = useState(true);

  const handleNavbar = () => {
    // console.log('fired' + window.scrollY);
    setShow(y > window.scrollY);
    setY(window.scrollY);
  };

  useEffect(() => {
    // setY(window.scrollY);
    if (isMobile) {
      document.body.addEventListener('touchmove', handleNavbar);
      return () => {
        document.body.removeEventListener('touchmove', handleNavbar);
      };
    } else {
      // window.addEventListener('scroll', handleNavbar);
      // return () => {
      //   window.removeEventListener('scroll', handleNavbar);
      // };
    }
  }, [handleNavbar]);

  return (
    <Navbar
      className={`${isMobile ? 'taletree-animation-block' : ''
        } taletree-navbar sticky-top ${props.className} ${show ? 'show' : 'hide'
        }`}
      fixed="top"
      expand="lg"
    >
      <Container fluid>{props.children}</Container>
    </Navbar>
  );
};

export default TaletreeHeader;
