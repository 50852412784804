import { accountRepository } from '../../../repository/account.repository';
import { isObjEmpty } from '../../../utils/utils';

function createGuardian(formData) {
  const params = {
    first_name: formData.first_name,
    last_name: formData.last_name,
    password: formData.password,
    email: formData.username,
    token: formData.token,
  };

  return new Promise((resolve, reject) => {
    accountRepository
      .createGuardian(params)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function uploadPhoto(formData, profilePictures, dependents) {
  return new Promise((resolve, reject) => {
    const fileMap = {};
    formData.forEach((data, index) => {
      if (data.profile_pic[0]) {
        fileMap[data.username] = profilePictures[index];
      }
    });
    if (isObjEmpty(fileMap)) {
      resolve(dependents);
    } else {
      const photoUploadPromises = [];
      dependents.forEach((dependent) => {
        if (fileMap[dependent.username]) {
          const formBody = new FormData();
          formBody.append('image', fileMap[dependent.username]);
          photoUploadPromises.push(
            accountRepository.uploadPhoto(dependent.id, formBody)
          );
        }
      });
      Promise.all(photoUploadPromises)
        .then((response) => resolve(dependents)) // here
        .catch(() => reject({ profileUploadFail: true, dependents }));
    }
  });
}

function createChild(formData, profilePictures) {
  return new Promise((resolve, reject) => {
    accountRepository
      .createChild(formData.dependents)
      .then((response) => {
        return uploadPhoto(formData.dependents, profilePictures, response.data);
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
}

const addKid = (props) => {
  return new Promise((resolve, reject) => {
    accountRepository
      .addKid(props)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

function verifyEmail(id, mytoken) {
  const params = {
    uidb64: id,
    token: mytoken,
  };
  return new Promise((resolve, reject) => {
    accountRepository
      .verifyEmail(params)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}

function resendVerificationEmail() {
  return new Promise((resolve, reject) => {
    accountRepository
      .resendVerificationEmail()
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}

function requestResetPasswordEmail(email) {
  return new Promise((resolve, reject) => {
    accountRepository
      .requestResetPasswordEmail(email)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}

function resetPasswordWithToken(id, mytoken, data) {
  const params = {
    uidb64: id,
    token: mytoken,
    password: data.password,
  };
  return new Promise((resolve, reject) => {
    accountRepository
      .resetPasswordWithToken(params)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}

function validateResetPasswordToken(id, mytoken) {
  const params = {
    uidb64: id,
    token: mytoken,
  };
  return new Promise((resolve, reject) => {
    accountRepository
      .resetPasswordWithToken(params)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}

function requestDependentPasswordReset(email) {
  return new Promise((resolve, reject) => {
    accountRepository
      .requestDependentPasswordReset(email)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

const sendNotificationEmailToGuardian = (email) => {
  return new Promise((resolve, reject) => {
    accountRepository
      .sendNotificationEmailToGuardian(email)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const accountViewModel = {
  createGuardian,
  createChild,
  addKid,
  verifyEmail,
  resendVerificationEmail,
  uploadPhoto,
  requestResetPasswordEmail,
  resetPasswordWithToken,
  requestDependentPasswordReset,
  validateResetPasswordToken,
  sendNotificationEmailToGuardian,
};
