import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import FormInput from './FormInput';
import eyeOpen from '../../assets/icon/icon-eye.svg';
import eyeClose from '../../assets/icon/icon-eye-cross.svg';

const FormPasswordInput = React.forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <span className="input-group-append">
        <FormInput
          type={showPassword ? 'text' : 'password'}
          ref={ref}
          {...props}
        />
        <button
          className="btn rounded-pill border-0 ml-n5 shadow-none"
          type="button"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          <Image style={{opacity:'0.5'}} src={showPassword ? eyeClose : eyeOpen} />
        </button>
      </span>
    </>
  );
});

export default FormPasswordInput;
