import React, { useEffect, useRef, useState } from "react";
import Cartoon from "../../../../assets/images/cartoon.svg";
import FormLabel from "../../../common/FormLabel"
import { Col, Form, Row } from 'react-bootstrap';
import { guardianSignUpErrors } from "../../../../constants/error.constants";
import { useAuth } from "../../../auth/Auth";
import { useForm } from "react-hook-form";
import FormInput from "../../../common/FormInput";
import { ErrorMessage } from "@hookform/error-message";
import FormPasswordInput from "../../../common/FormPasswordInput";
import isEmail from "validator/lib/isEmail";
import { settingsViewModel } from "../../dashboard/settings.view.model";
import { onboardingViewModel } from "../onboarding.view.model";
import { displayFormErrorsFromApi } from "../../../api/api.display.error";
import { passwordStrengthValidator } from "../../../../utils/form.utils";
import { changeTextToVoice, getSoundWaveJson } from "../../../../utils/camp.utils";
import Lottie from "react-lottie-player";
import LoadButton from "../../../common/LoadButton";
import Typed from "typed.js";
import notVerified from '../../../../assets/icon/Unverified.svg';
import goldTick from '../../../../assets/icon/gold_tick.svg';
import silverTick from '../../../../assets/icon/silver_tick.svg';
import verfiedIcon from '../../../../assets/icon/Verified-badge.svg';
import SignupModal from "./SignupModal";
import { customEvents } from "../../../../analytics/customEvents";
import Avatar from "../../../../assets/images/Avatar.svg";
import ReactFlagsSelect from "react-flags-select";
import countryList from 'react-select-country-list'
import 'react-phone-input-2/lib/style.css'
import CountryJson from '../../../../assets/json/CountryCodes.json';
import Select from 'react-select';
import { getGeolocationData } from "../../../../utils/utils";
import { useHistory } from "react-router-dom";
import { GUARDIAN_SETTINGS_ROUTE } from "../../../../constants/routes.constants";

export default function SignupStep4(props) {
    const history = useHistory();
    const treehouse = JSON.parse(localStorage.getItem('partner'));
    const { showModal, setShowModal, handleComplete, setSignupProcess } = props;
    const lsFirstName = localStorage.getItem('firstName');
    const _NOT_SELECTED = '';
    const _BUBBLE_TEXT1 = `I can't wait to welcome your little genius into the Emerald Forest!`;
    const [uniqueCode, setUniqueCode] = useState();
    const { setUser, guardian } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isStep4Disable, setIsStep4Disable] = useState(false);
    const [relationship, setRelationship] = useState(_NOT_SELECTED);
    const [isCodeVerified, setIsCodeVerified] = useState();
    const { register, handleSubmit, watch, setError, errors, getValues } = useForm({
        mode: 'onBlur',
    });
    const [text, setText] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [showStep5, setShowStep5] = useState(false);
    const [isPlay, setIsPlay] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [loginUser, setLoginUser] = useState('');
    const [newDependent, setNewDependent] = useState([]);
    const typedRef = useRef(null);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const handleRelationshipChange = (selection) => {
        let analyticsData = {
            category: "Sign up",
            action: selection === 'Dad' ? "dad" : selection === 'Mom' ? 'mom' : 'legal guardian',
            label: selection === 'Dad' ? "dad" : selection === 'Mom' ? 'mom' : 'legal guardian'
        }
        customEvents(analyticsData);
        setRelationship(selection);
    };

    const getUserCountry = async () => {
        try {
            const res = await getGeolocationData()
            const filterdPhoneCodeOption = CountryJson.filter(obj => obj.code === res.country)
            setSelectedPhoneCode(filterdPhoneCodeOption[0]?.dial_code)
            setSelected(res.country)
            setSelectedCountryName(list.filter((data) => data.value === res.country))
        } catch (error) {
            console.log(error)
            setSelectedPhoneCode('+1')
            setSelected('US')
            setSelectedCountryName(list.filter((data) => data.value === 'US'))
        }

    }

    useEffect(() => {
        setText(_BUBBLE_TEXT1)
        getUserCountry()
    }, [])

    const manufactureParams = (data) => {
        const lsFirstName = localStorage.getItem('firstName');
        const lsBirthDate = localStorage.getItem('birthdate');
        const lsGender = JSON.parse(localStorage.getItem('gender'));

        const params = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            password: data.password,
            relationship_to_kid: relationship,
            country_code: selected,
            phone_number: selectedPhoneCode + data.phone
            // dependent: {
            //     first_name: lsFirstName,
            //     last_name: data.last_name,
            //     signup: 'camp',
            //     profile: {
            //         favorites: ['', '', ''],
            //         taletree_friend: '',
            //         gender: lsGender,
            //         birthdate: lsBirthDate.replace(/\//g, '-'),
            //     },
            //     questions: []
            // },
        };
        return params;
    };

    const onSubmit = (data) => {
        let analyticsData = {
            category: "Sign up",
            action: "complete account",
            label: 'complete account'
        }
        customEvents(analyticsData);
        setIsLoading(true);
        // setIsStep4Disable(true);
        const params = manufactureParams(data);
        if (uniqueCode) {
            params.dependent.profile.treehouse_unique_id = uniqueCode;
        }

        onboardingViewModel
            .createGuardian(params)
            .then((res) => {
                //refreshUserState();
                setLoginUser(res);
                if (treehouse && treehouse.user_type === 'camp master') {
                    let params = {
                        partner_id: treehouse.id,
                        dependent_id: res?.dependents[0]?.id
                    }
                    onboardingViewModel.subscribeExpert(params)
                    // handleComplete();
                    setUser(res)
                    history.push(GUARDIAN_SETTINGS_ROUTE)
                } else {
                    setNewDependent(res?.dependents[0])
                    setShowStep5(true);
                    setUser(res)
                    history.push(GUARDIAN_SETTINGS_ROUTE)
                }
                localStorage.clear();
                // setIsStep4Disable(false);
                handleComplete()
                setText(`Amazing ${watch('first_name')}! Nice to meet you.`);
            })
            .catch((err) => {
                console.log(err);
                //* not display reCAPTCHA error
                setIsDisabled(true);
                setIsLoading(false);
                setIsStep4Disable(false);
                setShowStep5(false);
                if (err.resultCode !== 405000) displayFormErrorsFromApi(err, setError);
                grecaptcha.enterprise.reset();
            });
    };

    window.verifyCallback = (res) => {
        setIsDisabled(false);
    };

    useEffect(() => {
        let analyticsData = {
            category: "Sign up",
            action: "guardian info",
            label: 'guardian info'
        }
        customEvents(analyticsData);
        //* Append Google reCAPTCHA Enterprise script
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/enterprise.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const checkTreehouse = (code) => {
        let body = {
            camp_code: code
        }
        settingsViewModel.checkTreehouse(code).then((res) => {
            setIsCodeVerified(true);
            setUniqueCode(code);
            setIsDisabled(false);
        }).catch((err) => {
            setIsDisabled(true);
            setIsCodeVerified(false);
        })
    }

    const handleCode = (e) => {
        let analyticsData = {
            category: "Sign up",
            action: "camp code",
            label: 'camp code'
        }
        customEvents(analyticsData);
        checkTreehouse(e.target.value)
    }

    const handleFirstNameChange = (e) => {
        setText(`Amazing ${e.target.value}! Nice to meet you.`);
        setFirstName(e.target.value);
    }

    const handleVoiceClick = () => {
        setIsPlay(!isPlay);
        changeTextToVoice(text, isPlay, setIsPlay, isPaused, setIsPaused);
    }

    const handleClose = () => {
        setSignupProcess(false);
        setShowModal(false)
    }
    useEffect(() => {
        if (watch('last_name')) {
            let analyticsData = {
                category: "Sign up",
                action: "guaradian name",
                label: 'guaradian name'
            }
            customEvents(analyticsData);
            setText(`Amazing ${watch('first_name')}! Nice to meet you.`)
        }
    }, [watch('last_name')])

    useEffect(() => {
        const typed = new Typed(typedRef.current, {
            strings: [text],
            typeSpeed: 50,
        });

        return () => {
            // Destroy Typed instance during cleanup to stop animation
            typed.destroy();
        };
    }, [text])

    const handleSubmitCode = () => {
        if (uniqueCode) {
            let analyticsData = {
                category: "Sign up",
                action: "enter",
                label: 'enter'
            }
            customEvents(analyticsData);
            let params = {
                dependent_id: newDependent?.id,
                group_code: uniqueCode
            }
            try {
                settingsViewModel.connectTreehouse(params)
            } catch (error) {
                console.log(err)
            }
        } else {
            let analyticsData = {
                category: "Sign up",
                action: "skip",
                label: 'skip'
            }
            customEvents(analyticsData);
        }
        handleComplete()
        setText(`Amazing ${watch('first_name')}! Nice to meet you.`);
        setUser(loginUser)
    }

    useEffect(() => {
        if (watch('password')) {
            let analyticsData = {
                category: "Sign up",
                action: "password",
                label: 'password'
            }
            customEvents(analyticsData);
        }
    }, [watch('password')])

    useEffect(() => {
        if (watch('confirm_password')) {
            let analyticsData = {
                category: "Sign up",
                action: "confirmpassword",
                label: 'confirmpassword'
            }
            customEvents(analyticsData);
        }
    }, [watch('confirm_password')])

    const list = countryList().getData()
    const [selected, setSelected] = useState("");
    const [selectedPhoneCode, setSelectedPhoneCode] = useState('')
    const [selectedCountryName, setSelectedCountryName] = useState('')
    const onSelect = (code) => {
        setSelected(code)
        setSelectedCountryName(list.filter((data) => data.value === code))
        const tempPhone = phoneCodeOptions[phoneCodeOptions.findIndex(obj => obj.country_code === code)]
        setSelectedPhoneCode(tempPhone.value)
    };

    const showSelectedLabel = false;
    const showSecondarySelectedLabel = false
    const selectedSize = 30;
    const showOptionLabel = false;
    const showSecondaryOptionLabel = false;
    const optionsSize = 20
    const searchable = true;
    const searchPlaceholder = "";
    const alignOptionsToRight = false;
    const fullWidth = false;
    const disabled = false;

    const handlePhoneCodeChange = (e) => {
        setSelectedPhoneCode(e.value)
        setSelected(e.country_code)
        setSelectedCountryName(list.filter((data) => data.value === e.country_code))
    }

    const phoneCodeOptions = CountryJson.map((obj) => {
        return { value: obj.dial_code, label: obj.dial_code, country_code: obj.code }
    })

    const customStyles = {
        control: (base) => ({
            ...base,
            height: 48,
            color: '#321b6d',
            fontWeight: '400',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#321b6d',
        }),
        menu: (provided) => ({
            ...provided,
        }),
    };

    return (
        <SignupModal className='auth-popup right-curved-div' showModal={showModal} handleClose={handleClose}>
            <div className='row bg-blue border-40' style={{ display: showStep5 ? 'flex' : 'none' }}>
                <div className="col-md-6 col-lg-5 p-4 bg-blue border-left overflow-hidden">
                    <div className="voice-buble buuble-bounce" id="SpeechBubble">
                        <p id='voice-text'>
                            <span ref={typedRef}>
                                {text}
                            </span>
                        </p>
                    </div>
                    {/* <div className="text-left">
                        <p className="text-white voice-btn" onClick={handleVoiceClick}><BsVolumeUpFill /> Voice on</p>
                        <div className="mt-3">
                            <Lottie
                                loop
                                animationData={getSoundWaveJson()}
                                play={isPlay}
                                className='animate-svg'
                            />
                        </div>
                    </div> */}
                    <div className="cartoon-img">
                        <img className="w-100" src={Cartoon} />
                        <div className="verify-symbol-div" >
                            {treehouse && treehouse.id &&
                                <span className="verify-symbol-span">
                                    <img className="verify-main-icon" src={treehouse.picture_url} />
                                    {treehouse ?
                                        treehouse.user_type === "camp master" ?
                                            <img className="verify-symbol-icon" src={verfiedIcon} />
                                            : treehouse.type_of_organisation === "non profit" ?
                                                <img className="verify-symbol-icon" src={silverTick} />
                                                : <img className="verify-symbol-icon" src={goldTick} />
                                        : <img className="verify-symbol-icon" src={notVerified} />
                                    }
                                </span>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-7 bg-white toparrow border-right topinmius right-bottom-curve right-curve auth-section white-right-arrow">
                    <div className="vertical-flex">
                        <div className="">
                            <p className="auth-entry-text">Do you have a camp code from a camp captain?</p>
                            <p className="">At TaleTree we love it it when kids enroll on our weekly creative camps. Your kid can become part of a weekly camp via the branches plan or through their school or your employer!</p>
                        </div>
                        <div className="h-100">
                            <Form.Row className="auth-form h-100">
                                <div className="inner-v-flex">
                                    <div>
                                        <Form.Group className="container-fluid">
                                            <input
                                                name="Add"
                                                className="input-modal w-100"
                                                onChange={handleCode}
                                                maxLength={40}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <div className="row w-100" >
                                            <div className="col-md-6">
                                                <LoadButton className=" grey-btn btn w-100" onClick={handleSubmitCode} testid="submit" text='Skip' />
                                            </div>
                                            <div className="col-md-6">
                                                <LoadButton className="gred-btn w-100" isDisabled={isDisabled} testid="submit" onClick={handleSubmitCode} text='Enter' />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Form.Row>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row bg-blue' style={{ display: !showStep5 ? 'flex' : 'none' }}>
                <div className="col-md-6 col-lg-5 p-4 bg-blue overflow-hidden">
                    <div className="voice-buble buuble-bounce" id="SpeechBubble" >
                        <p id='voice-text' >
                            <span ref={typedRef}>
                                {watch('last_name') ?
                                    `Amazing ${watch('first_name')}! Nice to meet you.`
                                    : `I can't wait to welcome ${localStorage.getItem('firstName')} into the Emerald Forest!`}
                            </span>
                        </p>
                    </div>
                    <div className="text-left">
                        {/* <p className="text-white voice-btn" onClick={handleVoiceClick}><BsVolumeUpFill /> Voice on</p> */}
                        <div className="" style={{marginTop: '8rem'}}>
                            {/* <Lottie
                                loop
                                animationData={getSoundWaveJson()}
                                play={isPlay}
                                className='animate-svg'
                            /> */}
                        </div>
                    </div>
                    <div className="cartoon-img">
                        <img className="w-100" src={Cartoon} />
                        <div className="verify-symbol-div" >
                            {treehouse && treehouse.id &&
                                <span className="verify-symbol-span">
                                    <img className="verify-main-icon" src={treehouse?.picture_url ? treehouse?.picture_url : Avatar} />
                                    {treehouse && treehouse.is_verified ?
                                        treehouse.type_of_organisation === "non profit" ?
                                            <img className="verify-symbol-icon" src={silverTick} />
                                            : <img className="verify-symbol-icon" src={goldTick} />
                                        : <img className="verify-symbol-icon" src={notVerified} />
                                    }
                                </span>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-7 bg-white border-right right-curve auth-section white-right-arrow toparrow topinmius right-bottom-curve">
                    <p className="auth-entry-text">Guardian Sign up</p>
                    {/* <p className="">We now require your details as {localStorage.getItem('firstName')}'s guardian so that we can complete the account creation and get you and {localStorage.getItem('firstName')} onboarded. </p>
                    <p className="pb-4">Remember, TaleTree is <span className="fw-bold">totally FREE </span>at this stage.</p> */}
                    <div>
                        <Form onSubmit={handleSubmit(() => onSubmit(getValues()))}>
                            <Form.Row className="auth-form">
                                <div className="row first-last-name">
                                    <div className="col-md-6 left-1">
                                        <Form.Group className="container-fluid">
                                            <FormLabel>
                                                First Name<span className="text-danger">*</span>
                                            </FormLabel>
                                            <FormInput
                                                name="first_name"
                                                className="input-modal"
                                                placeholder="First name"
                                                data-testid="first_name"
                                                ref={register({
                                                    required: guardianSignUpErrors.first_name.required,
                                                })}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="first_name"
                                                className="small text-danger"
                                                as="label"
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 left-1 right-1">
                                        <Form.Group className="container-fluid">
                                            <FormLabel>
                                                Last Name<span className="text-danger">*</span>
                                            </FormLabel>
                                            <FormInput
                                                className="input-modal"
                                                name="last_name"
                                                placeholder="Your surname"
                                                data-testid="last_name"
                                                ref={register({
                                                    required: guardianSignUpErrors.last_name.required,
                                                })}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="last_name"
                                                className="small text-danger"
                                                as="label"
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                {watch('last_name') &&
                                    <>
                                        <>
                                            <Form.Group className="container-fluid">
                                                <FormLabel>
                                                    Email<span className="text-danger">*</span>
                                                </FormLabel>
                                                <FormInput
                                                    className="input-modal"
                                                    name="email"
                                                    placeholder="Your e-mail"
                                                    data-testid="email"
                                                    ref={register({
                                                        required: guardianSignUpErrors.email.required,
                                                        validate: (input) =>
                                                            isEmail(input) || guardianSignUpErrors.email.invalid,
                                                    })}
                                                    onChange={(e) => {
                                                        let analyticsData = {
                                                            category: "Sign up",
                                                            action: "email",
                                                            label: 'email'
                                                        }
                                                        customEvents(analyticsData);
                                                        setEmail(e.target.value)
                                                    }}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="email"
                                                    className="small text-danger"
                                                    as="label"
                                                />
                                            </Form.Group>
                                            <Form.Group className="container-fluid phone-num">
                                                <FormLabel>
                                                    Phone number<span className="text-danger">*</span>
                                                </FormLabel>

                                                <Form.Row >
                                                    <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", width: "100%", marginLeft: "5px" }}>
                                                        <span style={{ width: "20%" }} >
                                                            <Select className="phone-num1"
                                                                options={phoneCodeOptions}
                                                                placeholder={""}
                                                                styles={customStyles}
                                                                value={phoneCodeOptions[phoneCodeOptions.findIndex(obj => obj.value === selectedPhoneCode)]}
                                                                // defaultValue={phoneCodeOptions[phoneCodeOptions.findIndex(obj => obj.value === '+1')]}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    borderRadius: '6px',
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary: '#80e4e1',
                                                                    },
                                                                })}
                                                                onChange={handlePhoneCodeChange}
                                                            />
                                                        </span>

                                                        <span className="input-phonenum" style={{ width: "100%" }}>
                                                            <FormInput
                                                                className="input-modal"
                                                                name="phone"
                                                                placeholder="Enter phone number"
                                                                data-testid="phone"
                                                                type="number"
                                                                ref={register({
                                                                    required: guardianSignUpErrors.phone_number.required
                                                                })}
                                                            />
                                                        </span>
                                                    </div>
                                                </Form.Row>

                                                <ErrorMessage
                                                    errors={errors}
                                                    name="phone"
                                                    className="small text-danger"
                                                    as="label"
                                                />
                                            </Form.Group>

                                            <Form.Group className="container-fluid country-field">
                                                <Form.Label className="country-field">Country:</Form.Label>
                                                <Form.Row >
                                                    <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", width: "98%", marginLeft: "5px" }}>
                                                        <span className="country-flag" style={{ width: "20%", marginTop: "5px" }} >

                                                            <ReactFlagsSelect
                                                                selected={selected}
                                                                onSelect={onSelect}
                                                                showSelectedLabel={showSelectedLabel}
                                                                showSecondarySelectedLabel={showSecondarySelectedLabel}
                                                                selectedSize={selectedSize}
                                                                showOptionLabel={showOptionLabel}
                                                                showSecondaryOptionLabel={showSecondaryOptionLabel}
                                                                optionsSize={optionsSize}
                                                                placeholder=" "
                                                                searchable={searchable}
                                                                searchPlaceholder={searchPlaceholder}
                                                                alignOptionsToRight={alignOptionsToRight}
                                                                fullWidth={fullWidth}
                                                                disabled={disabled}
                                                            />
                                                        </span>

                                                        <span className="input-phonenum" style={{ width: "100%" }}>
                                                            <FormInput
                                                                className="input-modal"
                                                                value={selectedCountryName[0]?.label}
                                                                disabled={true}
                                                            />
                                                        </span>
                                                    </div>
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="country"
                                                        className="small text-danger"
                                                        as="label"
                                                    />
                                                </Form.Row>
                                            </Form.Group>

                                            <Form.Group className="container-fluid">
                                                <FormLabel>
                                                    Password<span className="text-danger">*</span>
                                                </FormLabel>
                                                <FormPasswordInput
                                                    className="input-modal"
                                                    name="password"
                                                    placeholder="At least 8 characters"
                                                    data-testid="password"
                                                    ref={register({
                                                        required: guardianSignUpErrors.password.required,
                                                        minLength: {
                                                            value: 8,
                                                            message: guardianSignUpErrors.password.minLength,
                                                        },
                                                        validate: (value) =>
                                                            passwordStrengthValidator(value) ||
                                                            guardianSignUpErrors.password.strength,
                                                    })}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="password"
                                                    className="small text-danger"
                                                    as="label"
                                                />
                                            </Form.Group>
                                            <Form.Group className="container-fluid">

                                                <FormLabel>
                                                    Confirm password<span className="text-danger">*</span>
                                                </FormLabel>
                                                <FormPasswordInput
                                                    className="input-modal"
                                                    name="confirm_password"
                                                    placeholder="Confirm password"
                                                    data-testid="confirm_password"
                                                    ref={register({
                                                        required: guardianSignUpErrors.password.confirm,
                                                        validate: (value) =>
                                                            value === watch('password') ||
                                                            guardianSignUpErrors.password.mismatch,
                                                    })}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="confirm_password"
                                                    className="small text-danger"
                                                    as="label"
                                                />
                                            </Form.Group>
                                            <p className="text-center authntication-text">
                                                By clicking on Create Account, you agree to TaleTree’s <a href="https://web.taletree.com/terms-of-service" target="_blank"> Terms of Service</a> and <a href="https://web.taletree.com/privacy-policy" target="_blank"> Privacy Policy</a>
                                            </p>

                                            <div className="text-center submit-btn" style={{ marginLeft: '40px' }}>
                                                <Form.Group className="">
                                                    <LoadButton
                                                        className="gred-btn"
                                                        isLoading={isLoading}
                                                        isDisabled={isStep4Disable}
                                                        testid="submit"
                                                        text="Complete account"
                                                    />
                                                </Form.Group>
                                            </div>
                                        </>
                                    </>
                                }
                            </Form.Row>
                        </Form>
                    </div>

                </div>
            </div>
        </SignupModal>
    )
}