import React, { useState, useEffect } from 'react';
import { Row, Form, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import FormLabel from '../../common/FormLabel';
import FormInput from '../../common/FormInput';
import ErrorLabel from '../../common/ErrorLabel';
import LoadButton from '../../common/LoadButton';
import { kidLoginErrors } from '../../../constants/error.constants';
import { useAuth } from '../../auth/Auth';
import { accountViewModel } from './account.view.model';
import {
  FORGOT_PASSWORD_EMAIL_ROUTE,
  KID_LOGIN_ROUTE,
} from '../../../constants/routes.constants';
import { bg } from '../../../constants/component.constants';
import { changeBodyClassName } from '../../../utils/utils';
import OnboardingWrapper from '../onboarding/OnboardingWrapper';

import './scss/forgot.password.scss';

function ForgotGuardianPassword() {
  const { register, handleSubmit, setError, errors } = useForm({
    mode: 'onBlur',
  });
  const { fetchAPI } = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    changeBodyClassName([bg.GRADIENT]);
  }, []);

  const onSubmit = (data) => {
    setIsLoading(true);
    fetchAPI(accountViewModel.requestDependentPasswordReset, data)
      .then((reponse) => {
        history.push({
          pathname: FORGOT_PASSWORD_EMAIL_ROUTE,
          state: { loginRoute: KID_LOGIN_ROUTE },
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <OnboardingWrapper className="forgot-password-container login-container reset-password-background login-container-wrapper">
      <div className="login-form-wrapper forgot-kid-password">
        {/* <Row className="title">Can't log in?</Row> */}
        <Row className="title">Update Needed</Row>
        {/* <Row className="subTitle">
          <span>
            Don't worry!
            <br />
            Ask your guardian to help you reset the password.
          </span>
        </Row> */}
        <div className="subTitle mt-3 mb-4">
          <span>
            Oops! There is a small update one of your parents needs to make. Please press the button below and ask your mom or dad for help.
          </span>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
          <div className="wrapper-wrapper">
            <Form.Row>
              <Form.Group className="container-fluid mt-4 mb-0">
                <FormLabel>E-mail<span>*</span></FormLabel>
                <FormInput
                  name="email"
                  placeholder="Type in guardian’s email"
                  data-testid="email"
                  ref={register({
                    required: kidLoginErrors.email.required,
                    validate: (input) =>
                      isEmail(input) || kidLoginErrors.email.invalid,
                  })}
                />
                <ErrorLabel errors={errors} name="email" as="label" />
              </Form.Group>
            </Form.Row>
            <Form.Row className="login-submit mt-5">
              <Form.Group className="container-fluid mb-0">
                <LoadButton
                  className="login-btn p-2 gred-btn"
                  text="Email Guardian"
                  isDisabled={isLoading}
                  isLoading={isLoading}
                />
              </Form.Group>
            </Form.Row>
          </div>
        </Form>
      </div>
      <Row className="go-back-link-container">
        <Link to={KID_LOGIN_ROUTE}>Back to Login</Link>
      </Row>
    </OnboardingWrapper>
  );
}

export default ForgotGuardianPassword;
