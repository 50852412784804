import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import FormLabel from '../../common/FormLabel';
import FormInput from '../../common/FormInput';
import ErrorLabel from '../../common/ErrorLabel';
import LoadButton from '../../common/LoadButton';
import { useAuth } from '../../auth/Auth';
import { useHistory } from 'react-router-dom';
import OnboardingWrapper from '../onboarding/OnboardingWrapper';
import { accountViewModel } from './account.view.model';
import { bg } from '../../../constants/component.constants';
import { changeBodyClassName } from '../../../utils/utils';
import { guardianLoginErrors } from '../../../constants/error.constants';
import {
  KID_LOGIN_ROUTE,
  UPDATE_NEEDED_EMAIL_ROUTE,
} from '../../../constants/routes.constants';

import './scss/update-needed.scss';

const UpdateNeeded = () => {
  const { register, handleSubmit, setError, errors } = useForm({
    mode: 'onBlur',
  });
  const { fetchAPI } = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data) => {
    setIsLoading(true);
    fetchAPI(accountViewModel.sendNotificationEmailToGuardian, data)
      .then((reponse) => {
        history.push({
          pathname: UPDATE_NEEDED_EMAIL_ROUTE,
          state: { loginRoute: KID_LOGIN_ROUTE },
        });
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    changeBodyClassName([bg.GRADIENT]);
  }, []);

  return (
    <OnboardingWrapper className="update-needed-container login-container">
      <div className="update-needed login-form-wrapper">
        <div className="title">Update Needed</div>
        <div className="subTitle">
          Oops! There is a small update one of your parents needs to make.
          Please press the button below and ask your mom or dad for help.
        </div>
        <Row className="input-wrapper">
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
              <Form.Row>
                <Form.Group className="container-fluid mb-0">
                  <FormLabel>
                    Email<span>*</span>
                  </FormLabel>
                  <FormInput
                    name="email"
                    placeholder="Type in guardian's email"
                    data-testid="email"
                    ref={register({
                      required: guardianLoginErrors.email.required,
                      validate: (input) =>
                        isEmail(input) || guardianLoginErrors.email.invalid,
                    })}
                  />
                  <ErrorLabel errors={errors} name="email" as="label" />
                </Form.Group>
              </Form.Row>
              <Form.Row className="login-submit mt-5">
                <Form.Group className="container-fluid mb-0">
                  <LoadButton
                    className="login-btn p-2"
                    text="Email Guardian"
                    isLoading={isLoading}
                    isDisabled={isLoading}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </Col>
        </Row>
      </div>
    </OnboardingWrapper>
  );
};

export default UpdateNeeded;
