import React from 'react';
import './scss/badge.scss';

function MyBadge({ icon, count, title, className = '', isEmeralites = false }) {
  return (
    <div className={`my-badge-wrapper ${className}`}>
      <div className="image-count-wrapper">
        <div className="badge-image-wrapper">
          <img className="badge-image" src={icon} />
        </div>
        <div className="badge-count">{count}{isEmeralites && <span style={{ fontWeight: 'bold' }}>/20</span>}</div>
      </div>
      <div className="badge-title">{title}</div>
    </div>
  );
}

export default MyBadge;
