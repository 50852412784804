import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import {
  MY_SCHOOL_ROUTE,
  MY_PARTNER_ROUTE,
  SCHOOL_LOGIN,
  SCHOOL_ROUTE,
  PARTNER_ROUTE,
} from '../../../constants/routes.constants';
import { Spinner } from 'react-bootstrap';
import { getSession } from '../../../utils/session.utils';
import { useAuth } from '../../auth/Auth';
import CheckEmail from './CheckEmail';
import SchoolRoute from '../../routes/SchoolRoute';

function SchoolRoutes({history, match }) {
  const { path } = match;
  const {
    refreshUserState,
    school,
    partner
  } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const handleSchool = () => {
    let route;
    if (!school.email_verified) {
      route = `${SCHOOL_ROUTE}/check-email`;
      setIsLoading(false);
    } else {
      route = MY_SCHOOL_ROUTE;
    }
    history.push(route);
  }

  const handlePartner = () => {
    let route;
    if (!partner.email_verified) {
      route = `${PARTNER_ROUTE}/check-email`;
      setIsLoading(false);
    } else {
      route = MY_PARTNER_ROUTE;
    }
    history.push(route);
  }

  useEffect(() => {
    if (getSession()) {
      if (school) {
        handleSchool();
      } else if (partner) {
        handlePartner();
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [school, partner]);

  useEffect(() => {
    setIsLoading(true);
    refreshUserState();
  }, []);

  return (
    <div>
      {isLoading && (school || partner || getSession()) ? (
        <div className="w-100 h-100 align-items-center justify-content-center d-flex">
          <Spinner
            className={`${isLoading ? '' : 'd-none'}`}
            animation="border"
            size="lg"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <div>
          <Switch>
            {/* <Route exact path={PARTNER_LOGIN} component={PartnerLogin} />
            <Route exact path={SCHOOL_LOGIN} component={SchoolLogin} /> */}
            <SchoolRoute path={`${SCHOOL_ROUTE}/check-email`} component={CheckEmail} />
            <Route render={() => <Redirect to={{ pathname: SCHOOL_LOGIN }} />} />
          </Switch>
        </div>
      )}
    </div>
  );
}

export default SchoolRoutes;
