//reset
export const VERIFY_ROUTE = '/verify/:id/:token';
export const RESET_PASSWORD_ROUTE = '/reset/:id/:token';
export const FORGOT_PASSWORD_ROUTE = '/reset-password';
export const FORGOT_PASSWORD_GUARDIAN_ROUTE = '/guardian-reset-password';
export const FORGOT_PASSWORD_DEPENDENT_ROUTE = '/kid-reset-password';
export const FORGOT_PASSWORD_EMAIL_ROUTE = '/email-confirmation';
// OnBoarding
export const ON_BOARDING = '/onboarding';
//account
export const ACCOUNT_ROUTE = '/account';
export const SIGN_UP_ROUTE = `${ON_BOARDING}/signup`;
export const LOGIN_ROUTE = `${ACCOUNT_ROUTE}/login`;
// export const LOGIN_ROUTE = `${ACCOUNT_ROUTE}/login/kid`;
export const KID_LOGIN_ROUTE = `${LOGIN_ROUTE}/kid`;
export const KID_OLD_USER_ROUTE = `/update-needed`;
export const UPDATE_NEEDED_EMAIL_ROUTE = `/update-needed/email-confirmation`;
// export const KID_LOGIN_ROUTE = `${ACCOUNT_ROUTE}/login/kid`;
export const GUARDIAN_LOGIN_ROUTE = `${LOGIN_ROUTE}/guardian`;
export const FINISH_SIGNUP_ROUTE = '/finish-signup';
export const CREATE_CHILDREN_ROUTE = `${FINISH_SIGNUP_ROUTE}/create-children`;
//guardian dashboard
export const DASHBOARD_ROUTE = '/dashboard';
export const GUARDIAN_SETTINGS_ROUTE = `${DASHBOARD_ROUTE}/settings`;
export const GUARDIAN_ACCOUNT_DETAILS_ROUTE = `${DASHBOARD_ROUTE}/account`;
export const SEE_ALL = `${DASHBOARD_ROUTE}/see_all`;
export const COMPLIMENTS_RECEIVED = `/compliments-received`;
export const COMPLIMENTS_GIVEN = `/compliments-given`;
export const BINKIES_RECEIVED = `/binkies-received`;
export const BINKIES_GIVEN = `/binkies-given`;
export const MOST_COMMENTED = `/most-commented`;
export const MOST_BINKIED = `/most-binkied`;
export const NOTES = `/notes`;
//export const GUARDIAN_SETTINGS_ROUTE = `/onboarding/childRegistration`;
//dependent view
// export const MY_ROOM_ROUTE = '/my-room';
export const ADD_CHALLENGES_ROUTE = '/add-challenges';
// export const NEXT_CHALLENGE_ROUTE = '/next-challenges';
// export const CHALLENGE_DETAIL_ROUTE = '/next-challenges/:cid';
export const NEXT_CHALLENGE_ROUTE = '/challenges';
export const CHALLENGE_ROUTE = '/challenge';
export const SPECIAL_CHALLENGE_ROUTE = '/special-challenge';
export const EVENT_ROUTE = '/event';
// export const CHALLENGE_DETAIL_ROUTE = '/challenge/:cid';
// export const SPECIAL_CHALLENGE_DETAIL_ROUTE = '/special-challenge/:cid';
// export const EVENT_DETAIL_ROUTE = '/event/:cid';
export const CAMP_SCHEDULE_ROUTE = '/camp-schedule';
export const NOTIFICATION_ROUTE = '/companion';
export const SHARE_MODAL_ROUTE = '/share';
// payment
export const PAYMENT_ROUTE = '/payment';
//last route after payment, needs to be changed for payment
export const SIGNUP_COMPLETE_ROUTE = '/complete';
// export const HOME_FEED = '/home-feed';
export const HOME_FEED = '/';
export const ADD_MORE_BRANCHES = '/add-more-branches';
export const ADD_MORE_TREEHOUSES = '/add-more-experts';
export const VERIFICATION_PAYMENT_ROUTE = '/verification-payment';
export const VERIFICATION_PAYMENT_SUCCESS_ROUTE = '/verification-payment-success';
export const GUEST_LOGIN_ROUTE = KID_LOGIN_ROUTE;
//School/Partner Route
export const PARTNER_ROUTE = `/partner`;
export const SCHOOL_ROUTE = `/school`;
// export const MY_SCHOOL_ROUTE = "/school_room";
// export const MY_SCHOOL_ROUTE = "/camp-room";
export const MY_PARTNER_ROUTE = "/partner_room";
// export const ON_BOARDING_PARTNER = `${ON_BOARDING}/partner`;
export const PARTNER_SIGNUP_ROUTE = `${ON_BOARDING}/school`;
export const ON_BOARDING_PARTNER = `${ON_BOARDING}/partner`;
export const PARTNER_CHECK_EMAIL = `${PARTNER_ROUTE}/check_email`;
export const PARTNER_LOGIN = `${LOGIN_ROUTE}/partner`;
export const TREEHOUSE_LOGIN = `${LOGIN_ROUTE}/treehouse`;
export const SCHOOL_LOGIN = `${LOGIN_ROUTE}/camp`;
// export const SCHOOL_LOGIN = `${LOGIN_ROUTE}/school`;
// export const PARTNER_CHALLENGES = "/partner_challenges";
export const PARTNER_CHALLENGES = "/partner_challenges";
// export const SCHOOL_CHALLENGES = "/school_challenges";
export const PARTNER_ADD_CHALLENGES = "/partner_add_challenges";
// export const SCHOOL_ADD_CHALLENGES = "/school_add_challenges";
export const PARTNER_EDIT_CHALLENGES = "/partner_challenge/:cid";
export const PARTNER_EDIT_EVENT = "/update_event/:cid";
// export const PARTNER_ADD_TREEHOUSE = "/add_treehouse";
// export const PARTNER_EDIT_TREEHOUSE = "/edit_treehouse";
export const PARTNER_ADD_EXPERT = "/add_expert";
export const PARTNER_EDIT_EXPERT = "/edit_expert";
export const PARTNER_CHALLENGE_SINGLE = `${PARTNER_CHALLENGES}/:cid`;
export const FORGOT_PASSWORD_PARTNER_ROUTE = '/partner-reset-password';
//Creator Route
export const CREATOR_ROUTE = "/creator";
// export const SHARE_CREATION_ROUTE = "/creation";
export const SHARE_CREATION_ROUTE_BANNER = "/creation";
// export const SHARE_CREATION_ROUTE = "/share_creation/:cid";
// export const SHARE_CREATION_ROUTE_BANNER = "/share_creation";

export const NEWPAYMENT = "/payment_new";
export const MY_ROOM = "/:id";
export const MY_ROOM_ROUTE = '/my-room';

// export const TREEHOUSE_ROUTE = '/treehouse/:tid';
export const TREEHOUSE_ROUTE = '/treehouse';
export const TREEHOUSE_DETAIL_ROUTE = '/treehouse/:tid';
export const EXPERT_ROUTE = '/expert';
export const EXPERT_DETAIL_ROUTE = '/expert/:tid';
export const CAPTAIN_ROUTE = '/captain';
export const CAPTAIN_DETAIL_ROUTE = '/captain/:tid';
// export const TEACHERS_ROUTE = '/teachers/:tid';

//image crop
export const IMAGE_CROP = '/image-crop';

export const NEW_SIGNUP = '/new-signup';
export const NEW_SIGNUP2 = '/new-signup2';
export const NEW_SIGNUP3 = '/new-signup3';
export const NEW_SIGNUP4 = '/new-signup4';
export const NEW_SIGNUP5 = '/new-signup5';
export const NEW_SIGNUP6 = '/new-signup6';
export const NEW_SIGNUP7 = '/new-signup7';
export const NEW_SIGNUP8 = '/new-signup8';
export const NEW_SIGNUP9 = '/new-signup9';
export const SITE_DOWN = '/site-down';
export const SYSTEM_VERSION_CHECK = '/system-version-check';
//staging
export const STAGING_LOGIN = '/login/staging';

//newvirifiedscreen
export const NEW_VERIFIED_SCREEN = '/new-verified-screen'

//map
export const TALETREE_MAP = '/taletree-map';
export const KIDS_TALETREE = '/kids-taletree';

//camp routes
export const CAMP = '/camp';
export const MY_SCHOOL_ROUTE = `${CAMP}/camp-room`;
export const SCHOOL_CHALLENGES = `${CAMP}/camp-challenges`;
export const SCHOOL_ADD_CHALLENGES = `${CAMP}/add-challenges`;
export const SCHOOL_EDIT_CHALLENGES = `${CAMP}/edit-challenge/:cid`;
export const FORGOT_PASSWORD_SCHOOL_ROUTE = `/camp-reset-password`;
export const SCHOOL_KIDS_ROUTE = `${CAMP}/kids`;
export const PARTNER_ADD_TREEHOUSE = `${CAMP}/add-tribe`;
export const ADD_CAPTAIN = `${CAMP}/add-captain`;
export const PARTNER_EDIT_TREEHOUSE = `${CAMP}/edit-tribe`;
export const EDIT_CAPTAIN = `${CAMP}/edit-captain`;
export const CAPTAIN_NOTES = `${CAMP}/notes`;
export const CAMP_INSIGHTS = `${CAMP}/insights`;
export const CAMP_ACCOUNT = `${CAMP}/account`;
export const CAMP_REASSIGN_KID = `${CAMP}/reassign`;

//sso redirect
export const SSO_REDIRECT_SCREEN = `/redirect/oauth`;

//new payment
export const NEW_PAYMENT_PLAN = `/new-payment-plan`;
export const KAKAO_CALLBACK_SCREEN = `/oauth/kakao/callback`;
export const GOOGLE_CALLBACK_SCREEN = `/google/callback`;
export const MICROSOFT_CALLBACK_SCREEN = `/microsoft/callback`;

//guardian set password
export const GUARDIAN_SET_PASSWORD_ROUTE = '/set-password/:id/:token/:verify_token';