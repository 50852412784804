import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './scss/onboarding.modal.scss';
import closeIcon from '../../../assets/icon/icon-darker-close.svg';

const OnboardingModal = (props) => {
  const { showCloseButton = true } = props;
  const onCloseClick = () => {
    props.onHide()
  }
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      backdrop="static"
      dialogClassName={props.className}
      centered
    >
      <Modal.Header>
      
      <div className="close-button" data-dismiss="modal" onClick={onCloseClick}>
                <img src={closeIcon} alt="Close" />
            </div>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>&nbsp;</Modal.Footer>
    </Modal>
  );
};

export default OnboardingModal;
