import { notesRepository } from "../../../repository/notes.repository";
import { schoolRepository } from "../../../repository/school.repository";

const getNextQuery = (params, list, has_more) => {
  const lastId = list.length > 0 ? list[list.length - 1].id : 0;
  const nextQuery = { ...params };
  nextQuery.starting_after = lastId;
  nextQuery.has_more = has_more;
  return nextQuery;
};

const mergeList = (previousList, newList) => {
  const list = previousList ? [...previousList, ...newList] : newList;
  let newIdSet = new Set();
  const uniqueList = list.filter((item) => {
    let isUnique = false;
    if (!newIdSet.has(item.id)) {
      newIdSet.add(item.id);
      isUnique = true;
    }

    return isUnique;
  });
  return uniqueList;
};

const mergeChallengeList = (previousList, newList) => {
  const list = previousList ? [...previousList, ...newList] : newList;
  let idSet = new Set();

  const uniqueList = list.filter((item) => {
    let isUnique = false;
    if (!idSet.has(item.id)) {
      idSet.add(item.id);
      isUnique = true;
    }
    return isUnique;
  });
  return uniqueList;
};

const getNotes = (params) => {
  return new Promise((resolve, reject) => {
    notesRepository
      .getNotes(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getDependentNotes = (params, dependent_id, previousList) => {
  return new Promise((resolve, reject) => {
    notesRepository
      .getDependentNotes(params, dependent_id)
      .then((result) => {
        const commentList = mergeList(previousList, result.data);
        const query = getNextQuery(params, commentList, result.has_more);
        const data = {
          commentList,
          query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getDependentNotesByMonth = (params, dependent_id, previousList) => {
  return new Promise((resolve, reject) => {
    notesRepository
      .getDependentNotesByMonth(params, dependent_id)
      .then((result) => {
        const commentList = mergeList(previousList, result.data);
        const query = getNextQuery(params, commentList, result.has_more);
        const data = {
          commentList,
          query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const addNote = (params) => {
  return new Promise((resolve, reject) => {
    notesRepository
      .addNote(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
const updateNote = (params) => {
  return new Promise((resolve, reject) => {
    notesRepository
      .updateNote(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
const deleteNote = (note_id) => {
  return new Promise((resolve, reject) => {
    notesRepository
      .deleteNote(note_id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const noteReply = (data) => {
  return new Promise((resolve, reject) => {
    notesRepository
      .noteReply(data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const noteSeen = (id) => {
  return new Promise((resolve, reject) => {
    notesRepository
      .noteSeen(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const notesViewModel = {
  getNotes,
  getDependentNotes,
  getDependentNotesByMonth,
  addNote,
  updateNote,
  deleteNote,
  noteReply,
  noteSeen
}