import React from 'react';
import { Container } from 'react-bootstrap';
import DashboardHeader from '../header/DashboardHeader';
import { useHistory } from 'react-router-dom';
import "./binkiesCompliments.css";
import { HiOutlineChevronLeft } from '@react-icons/all-files/hi/HiOutlineChevronLeft';
import ScrollToTop from '../common/ScrollToTop';
import { useAuth } from '../auth/Auth';
import PartnerHeader from '../views/school/PartnerHeader';

const SeeAllWrapper = ({children}) => {
    const history = useHistory();
    const { guardian, school } = useAuth();

    const handleGobackClick = () => {
        history.goBack();
    }

    return (
        <div style={{background: school && '#fff' }} className={`guardian-setting-wrapper see-all-received ${school && "partner-wrapper-main"}`} id={"guardian_setting_img"}>
            <ScrollToTop />
            {guardian ? <DashboardHeader /> : school ? <PartnerHeader /> : ""}
            <div className='treehouse-back new-payment-plan-back-btn' role="button" onClick={handleGobackClick}>
                <span className="back-btn" style={{ color: guardian ? '#fff' : '#000000' }}><HiOutlineChevronLeft /></span>
            </div>
            <Container fluid="md" className='guardian-details-bg Binkies-all-square' style={{ minHeight: '90vh' }}>
                {children}
            </Container>
        </div>
    );
}

export default SeeAllWrapper;
