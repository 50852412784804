const challengeInit = {
  title: '',
  description: '',
  hash_tags: [],
  id: 0,
  picture_url: '',
  picture_height: 0,
  picture_width: 0,
  start_date_time: '',
  start_date_time_ts: 0,
  end_date_time: '',
  end_date_time_ts: 0,
  has_submitted: false,
  // creationList: null,
  // starting_after: 0,
  // has_more: true,
};

const creationInit = {
  creationList: null,
  starting_after: 0,
  has_more: true,
};

export const creationHolderInit = {
  user: { ...creationInit },
  // challenge: {
  //   challenge_id: {
  //     creationList: null,
  //     starting_after: 0,
  //     has_more: true,
  //   },
  //   starting_after: 0,
  //   has_more: true,
  // },
  // challenge: {
  //   starting_after: 0,
  //   has_more: true,
  //   challengeList: [],
  //   isLoading: true,
  //   media: {
  //     challenge_id: {
  //       creationList: null,
  //       starting_after: 0,
  //       has_more: true,
  //     },
  //   },
  // },
};
export const actionTypes = {
  UPDATE_USER_CREATIONS: 'UPDATE_USER_CREATIONS',
  ADD_CREATION: 'ADD_CREATION',
  DELETE_CREATION_BY_ID: 'DELETE_CREATION_BY_ID',

  // CHALLENGE_LIST_LOADING: 'CHALLENGE_LIST_LOADING',
  // ADD_CHALLENGE_LIST: 'ADD_CHALLENGE_LIST',
  // UPDATE_CHALLENGE_CREATIONS: 'UPDATE_CHALLENGE_CREATIONS',
  // ADD_CHALLENGE: 'ADD_CHALLENGE',
  // DELETE_CHALLENGE: 'DELETE_CHALLENGE',

  CLEAR: 'CLEAR',
};

export const creationHolderReducer = (creationHolder, action) => {
  let challenge_id;
  let payload;
  let newList;
  switch (action.type) {
    case actionTypes.UPDATE_USER_CREATIONS:
      return {
        ...creationHolder,
        user: action.payload,
      };
    case actionTypes.ADD_CREATION:
      let creationList = creationHolder.user.creationList
        ? creationHolder.user.creationList
        : [];
      return {
        ...creationHolder,
        user: {
          ...creationHolder.user,
          creationList: [action.payload, ...creationList],
        },
      };
    case actionTypes.DELETE_CREATION_BY_ID:
      newList = creationHolder.user.creationList.filter(
        (creation) => creation.id !== action.payload
      );
      //todo remove from challenge if exist
      return {
        ...creationHolder,
        user: {
          ...creationHolder.user,
          creationList: newList,
        },
      };
    // case actionTypes.CHALLENGE_LIST_LOADING:
    //     return {
    //       ...creationHolder,
    //       challenge: {
    //         ...creationHolder.challenge,
    //         isLoading: true,
    //       },
    //     };
    // case actionTypes.ADD_CHALLENGE_LIST:
    //   const mediaInit = {};
    //   action.payload.forEach((challenge) => {
    //     console.log(challenge);
    //     if (!creationHolder.challenge.media[challenge.id]) {
    //       mediaInit[challenge.id] = { ...creationInit };
    //     }
    //   });
    //   const newChallengeList = mergeCreationList(
    //     creationHolder.challenge.challengeList,
    //     action.payload
    //   );

    //   return {
    //     ...creationHolder,
    //     challenge: {
    //       ...creationHolder.challenge,
    //       isLoading: false,
    //       challengeList: newChallengeList,
    //       media: {
    //         ...creationHolder.challenge.media,
    //         ...mediaInit
    //       },
    //     },
    //   };
    // case actionTypes.UPDATE_CHALLENGE_CREATIONS:
    //   challenge_id = action.payload.challenge_id;
    //   payload = action.payload.challengeData;
    //   return {
    //     ...creationHolder,
    //     [challenge_id]: payload,
    //   };
    // case actionTypes.ADD_CHALLENGE:
    //   challenge_id = action.payload.challenge_id;
    //   payload = action.payload.challengeData;
    //   return {
    //     ...creationHolder,
    //     user: {
    //       ...creationHolder.user,
    //       creationList: [payload, ...creationHolder.user.creationList],
    //     },
    //     [challenge_id]: {
    //       ...creationHolder[challenge_id],
    //       creationList: [payload, ...creationHolder[challenge_id].creationList],
    //     },
    //   };
    // case actionTypes.DELETE_CHALLENGE:
    //   challenge_id = action.payload.challenge_id;
    //   payload = action.payload.challengeData;
    //   newList = creationHolder[challenge_id].creationList.filter(
    //     (creation) => creation.id !== payload
    //   );
    //   //todo remove from challenge if exist
    //   return {
    //     ...creationHolder,
    //     [challenge_id]: {
    //       ...creationHolder[challenge_id],
    //       creationList: newList,
    //     },
    //   };
    case actionTypes.CLEAR: {
      return creationHolderInit;
    }
    default:
      return creationHolder;
  }
};
