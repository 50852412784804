import React, { useEffect } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { KID_LOGIN_ROUTE } from '../../../constants/routes.constants';
import OnboardingWrapper from '../onboarding/OnboardingWrapper';
import { useAuth } from '../../auth/Auth';
import { bg } from '../../../constants/component.constants';
import { changeBodyClassName } from '../../../utils/utils';

const UpdateNeededEmail = () => {
  const history = useHistory();
  const location = useLocation();
  const { logout } = useAuth();

  useEffect(() => {
    changeBodyClassName([bg.GRADIENT]);
  }, []);

  const goToLoginRoute = () => {
    logout(KID_LOGIN_ROUTE);
  };
  const subTitle = <span>Please ask your parents to check their inbox!</span>;
  return (
    <OnboardingWrapper className="update-needed-container login-container">
      <div className="login-form-wrapper update-needed">
        <div className="title">Email Sent</div>
        <div className="subTitle">{subTitle}</div>
        <Row className="mt-3 mb-3 image-layer"></Row>
        <Button
          block
          onClick={goToLoginRoute}
          className="login-btn p-2"
          variant="primary"
          data-testid="submit"
        >
          Confirm
        </Button>
      </div>
    </OnboardingWrapper>
  );
};

export default UpdateNeededEmail;
