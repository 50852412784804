import React, { useContext, useReducer } from 'react';

import {
  creationHolderInit,
  creationHolderReducer
} from './creation.reducer';

const CreationHolderContext = React.createContext();

export const ProvideCreationHolder = ({ children }) => {
  const state = useProvideCreationHolder();
  return <CreationHolderContext.Provider value={state}>{children}</CreationHolderContext.Provider>;
};

export const useCreationHolder = () => {
  const context = useContext(CreationHolderContext);
  if (context === undefined) {
    throw new Error('CreationProvider must be wrapped for useCreation');
  }
  return context;
};

const useProvideCreationHolder = () => {
  const [creationHolder, dispatchCreationHolder] = useReducer(
    creationHolderReducer,
    creationHolderInit
  );

  return {
    creationHolder,
    dispatchCreationHolder
  };
};
