import React, { useEffect, useState } from "react";
import Cartoon from "../../../../assets/images/cartoon.svg";
import { Button, Modal } from 'react-bootstrap';
import { GUARDIAN_LOGIN_ROUTE } from "../../../../constants/routes.constants";
import { accountViewModel } from "../../account/account.view.model";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../../auth/Auth";
import OnboardingModal from "../OnboardingModal";
import { changeTextToVoice, getSoundWaveJson } from "../../../../utils/camp.utils";
import Lottie from "react-lottie-player";
import notVerified from '../../../../assets/icon/Unverified.svg';
import goldTick from '../../../../assets/icon/gold_tick.svg';
import silverTick from '../../../../assets/icon/silver_tick.svg';
import verfiedIcon from '../../../../assets/icon/Verified-badge.svg';
import SignupModal from "./SignupModal";
import { customEvents } from "../../../../analytics/customEvents";

export default function SignupStep6({ showModal, setShowModal, setSignupProcess }) {
    const treehouse = JSON.parse(localStorage.getItem('partner'));
    const [text, setText] = useState('Enjoy our video!!');
    const history = useHistory();
    const { guardian, setUser, logout } = useAuth();
    const [showResendModal, setShowResendModal] = useState(false);
    const [isPlay, setIsPlay] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => setShowResendModal(false);
    const handleOpen = () => setShowResendModal(true);

    const options = {
        width: '504',
        height: '283',
    };

    const _onReady = (e) => {
        e.target.pauseVideo();
    };

    const handleLoginClick = (e) => {
        // history.replace({
        //     pathname: `${GUARDIAN_LOGIN_ROUTE}`,
        // });
        let analyticsData = {
            category: "Sign up",
            action: "login here",
            label: 'login here'
        }
        customEvents(analyticsData);
        logout(GUARDIAN_LOGIN_ROUTE);
    };

    const resendVerificationLink = () => {
        accountViewModel
            .resendVerificationEmail()
            .then(() => {
                setShowResendModal(true);
            })
            .catch((error) => { });
    };

    const handleVoiceClick = () => {
        setIsPlay(!isPlay);
        changeTextToVoice(text, isPlay, setIsPlay, isPaused, setIsPaused);
    }

    const handleModalClose = () => {
        setSignupProcess(false);
        setShowModal(false)
    }

    useEffect(() => {
        let analyticsData = {
            category: "Sign up",
            action: "confirmation",
            label: 'confirmation'
        }
        customEvents(analyticsData);
    }, [])

    const onVideoClick = () => {
        let analyticsData = {
            category: "Sign up",
            action: "watch video ",
            label: 'watch video '
        }
        customEvents(analyticsData);
    }

    return (
        !showResendModal ?
            <SignupModal className='auth-popup right-curved-div' showModal={showModal} handleClose={handleModalClose}>
                <div className='row bg-blue border-40'>
                    <div className="col-md-6 col-lg-5 p-4 bg-blue border-left overflow-hidden">
                        <div className="voice-buble buuble-bounce" id="SpeechBubble" >
                            <p id='voice-text' >
                                {text}
                            </p>
                        </div>
                        <div className="text-left">
                            {/* <p className="text-white voice-btn" onClick={handleVoiceClick}><BsVolumeUpFill /> Voice on</p> */}
                            <div className="" style={{marginTop: '8rem'}}>
                                {/* <Lottie
                                    loop
                                    animationData={getSoundWaveJson()}
                                    play={isPlay}
                                    className='animate-svg'
                                /> */}
                            </div>
                        </div>
                        <div className="cartoon-img">
                            <img className="w-100" src={Cartoon} />
                            <div className="verify-symbol-div" >
                                {treehouse && treehouse.id &&
                                    <span className="verify-symbol-span">
                                        <img className="verify-main-icon" src={treehouse.picture_url} />
                                        {treehouse ?
                                            treehouse.user_type === "camp master" ?
                                                <img className="verify-symbol-icon" src={verfiedIcon} />
                                                : treehouse.type_of_organisation === "non profit" ?
                                                    <img className="verify-symbol-icon" src={silverTick} />
                                                    : <img className="verify-symbol-icon" src={goldTick} />
                                            : <img className="verify-symbol-icon" src={notVerified} />
                                        }
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-7 bg-white border-right right-curve auth-section white-right-arrow toparrow topinmius right-bottom-curve">
                        <p className="auth-entry-text">We are so happy to have you with us!</p>
                        <p className="">An e-mail has been sent to <a href="#"> {guardian?.email} </a>with a link to verify your account. In the meantime, watch the video to learn more about TaleTree.
                        </p>

                        <p className="pb-4">Didn’t receive the e-mail? <a href="javascript:void(0);" onClick={resendVerificationLink}>Resend </a></p>
                        <div className="pt-3 pb-2">

                            <div onClick={onVideoClick}>
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/BnSvbzNO5s8?si=aJcM3cHiJeG0Cnzs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>
                            {/* <p className="mobile-confirm-verify">Did you already verify your account? <a href="javascript:void(0);" onClick={handleLoginClick}>Log in here</a></p> */}
                        </div>

                    </div>
                </div>
            </SignupModal>
            :
            <OnboardingModal
                show={showResendModal}
                onHide={handleClose}
                className="check-your-email-dialog"
            >
                <div className="upper">
                    <h1>Check your E-mail</h1>
                    <div className="subtitle">
                        We just resent you a link to verify your e-mail address. Please
                        check your e-mail and click the link to activate your account.
                        Rememember to check your Spam or ALL folder if you cannot find the
                        e-mail.
                    </div>
                </div>
                <div className="bottom">
                    <Button
                        type="button"
                        className="common-round-btn close-button gred-btn"
                        onClick={handleClose}
                        style={{ border: '1px solid' }}
                    >
                        OK
                    </Button>
                </div>
            </OnboardingModal>
    )
}