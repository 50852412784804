import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../auth/Auth';
import { LOGIN_ROUTE, PARTNER_LOGIN, SCHOOL_LOGIN } from '../../constants/routes.constants';

function SchoolRoute({ component: Component, ...rest }) {
  const { school, isLoading } = useAuth();
  return (
    <div>
      {isLoading ? (
        //todo add spinner
        <div></div>
      ) : (
        <Route
          {...rest}
          render={(props) =>
            school ? <Component {...props} /> : <Redirect to={SCHOOL_LOGIN} />
          }
        />
      )}
    </div>
  );
}

export default SchoolRoute;
