import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { styled } from '@mui/system';
import closeIcon from '../../../assets/icon/icon-darker-close.svg';
import LoadButton from '../../common/LoadButton';
import './camp-notes.css';
import { notesViewModel } from './notes.view.model';
import { useAuth } from '../../auth/Auth';
import { Divider } from '@mui/material';

const ModalHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    position: 'relative',
}));

const CloseButton = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    cursor: 'pointer',
}));

const Content = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
}));

const bgColors = [
    '#FFD29F', '#C0FECE', '#B4E5FF', '#FDE987', "#F2F6AC"
]

const ViewNoteModal = ({ text, showModal, setShowModal, setShowReplySuccessModal }) => {
    console.log(text)
    const [comment, setComment] = useState(null);
    const { guardian } = useAuth();
    const replyArray = [
        "Thank you so much for taking care of my child",
        "Great! Looking forward to next week",
        "Thank you for your note",
        "We can’t wait for the next Camp"
    ]

    const closeModal = () => {
        setShowModal(false);
    };

    const handleReply = () => {
        const data = {
            content: comment,
            sender: guardian?.id,
            dependent_note: text?.id
        }
        notesViewModel.noteReply(data).then((res) => {
            closeModal();
            setShowReplySuccessModal(true)
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleSeen = () => {
        notesViewModel.noteSeen(text?.id).then((res) => {

        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        handleSeen();
    }, [])

    return (
        <Modal className='view-note-modal' show={showModal} onHide={closeModal} centered>
            <ModalHeader>
                <h4 class="write-notes-h4">View Note</h4>
                <div className='notes-div'>
                    <h5>Note</h5>
                    <p>{text?.content}</p>
                </div>

                <CloseButton onClick={closeModal}>
                    <img src={closeIcon} alt="Close" />
                </CloseButton>
            </ModalHeader>
            <ModalBody>
                <div className='note-reply-select'>
                    {text?.note_reply_list?.length ?
                        <>
                            <Divider />
                            <span className={`thought${replyArray.findIndex(item => item === text?.note_reply_list[0]?.content) + 1}`} style={{ background: bgColors[replyArray.findIndex(item => item === text?.note_reply_list[0]?.content)] }}>
                                {text?.note_reply_list[0]?.content}
                            </span>
                        </>
                        :
                        guardian &&
                        replyArray.length && replyArray.map((thought, index) => {
                            return (
                                <span className={`thought${index + 1} ${comment === thought && 'active'}`} style={{ background: bgColors[index] }} onClick={() => setComment(thought)}>
                                    {thought}
                                </span>
                            )
                        })}
                </div>
            </ModalBody>
            <Content>
                {comment &&
                    <LoadButton
                        text="Reply"
                        className='gred-btn publish-btn'
                        onClick={handleReply}
                    />
                }
                {!guardian &&
                    <LoadButton
                        text="Close"
                        className='gred-btn publish-btn'
                        onClick={closeModal}
                    />
                }
            </Content>
        </Modal>
    );
};

export default ViewNoteModal;
