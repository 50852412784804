import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { getEnvironment, isDev } from './config.utils';

export const sentryMonitorInit = () => {
  if (!isDev()) {
    Sentry.init({
      environment: getEnvironment(),
      dsn:
        'https://2323a9dba7984aaf8e3fb702e4f7d168@o463575.ingest.sentry.io/5468704',
      integrations: [new Integrations.BrowserTracing()],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }
};
