import React from 'react';
import { Form } from 'react-bootstrap';
import './scss/form.label.scss';

function FormLabel(props) {
  const className = props.className || '';
  return (
    <>
      <Form.Label className={`${className} form-label`} {...props}>
        {props.children}
      </Form.Label>
    </>
  );
}

export default FormLabel;
