import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../auth/Auth';
import { LOGIN_ROUTE, PARTNER_LOGIN } from '../../constants/routes.constants';

function PartnerRoute({ component: Component, ...rest }) {
  const { partner, isLoading } = useAuth();
  return (
    <div>
      {isLoading ? (
        //todo add spinner
        <div></div>
      ) : (
        <Route
          {...rest}
          render={(props) =>
            partner ? <Component {...props} /> : <Redirect to={PARTNER_LOGIN} />
          }
        />
      )}
    </div>
  );
}

export default PartnerRoute;
