export const SIMPLE_TYPE = '1';
export const NORMAL_TYPE = '2';
export const FULL_BIG_TYPE = '3';
export const FULL_SMALL_TYPE = '4';
export const VERTICAL_TYPE = '5';
export const VERY_SMALL_TYPE = '6';
export const SMALLEST_TYPE = '7';
export const PARTICIPATING_TYPE = '8';
export const COMPLETED_TYPE = '9';
export const FULL_BIG_TYPE_CREATOR = 10;
