import Cookies from 'js-cookie';
import {
  handleHttpStatusError,
  handleJsonParseError,
} from '../components/api/api.error.handler';

const getCsrfToken = () => {
  return Cookies.get('csrftoken');
};

const buildQuery = (data) => {
  // If the data is already a string, return it as-is
  if (typeof data === 'string') return data;

  var query = [];

  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
    }
  }
  return query.join('&');
};

const handleResponse = (response) => {
  return response.text().then((text) => {
    let data;
    try {
      data = text && JSON.parse(text);
    } catch (error) {
      return handleJsonParseError(response);
    }
    if (!response.ok) {
      data.status = response.status;
      return handleHttpStatusError(data);
    }
    return data;
  });
};

const get = (url) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
    credentials: 'same-origin',
  };
  return fetch(url, requestOptions).then(handleResponse);
};

const newGet = async (url) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
    credentials: 'same-origin',
  };
  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const data = await response.json();
  return data;
};

const post = (url, body) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
    credentials: 'same-origin',
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
};

const patch = (url, body) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
    credentials: 'same-origin',
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
};

const put = (url, body) => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
    credentials: 'same-origin',
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
};

const requestDelete = (url) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
    credentials: 'same-origin',
  };
  return fetch(url, requestOptions).then(handleResponse);
};

const postMultiForm = (url, formData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    credentials: 'same-origin',
    body: formData,
  };
  return fetch(url, requestOptions).then(handleResponse);
};

const postFormEncoded = (url, formData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formData,
  };
  return fetch(url, requestOptions).then(handleResponse);
};

export const fetchUtils = {
  buildQuery,
  get,
  newGet,
  post,
  postFormEncoded,
  postMultiForm,
  patch,
  requestDelete,
  put
};
