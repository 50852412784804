import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();
const DEPENDENTS = 'dependents';
const ONBOARDING_DEPENDENT = 'onboarding_dependent';
const GUARDIANS = 'guardians';
const SCHOOL = 'school';

function guardianLogin(credentials) {
  return fetchUtils.post(`${BASE_URL}/login/guardian`, credentials);
}
function dependentLogin(credentials) {
  return fetchUtils.post(`${BASE_URL}/login/dependent`, credentials);
}

function logout() {
  return fetchUtils.post(`${BASE_URL}/logout`, {});
}

function getUser() {
  return fetchUtils.get(`${BASE_URL}/users/current`);
}

function verifyEmail(params) {
  return fetchUtils.post(`${BASE_URL}/verifications`, params);
}

function resendVerificationEmail() {
  return fetchUtils.post(`${BASE_URL}/verifications/send`);
}

function createGuardian(params) {
  return fetchUtils.post(`${BASE_URL}/${GUARDIANS}`, params);
}

function getGuardianUser() {
  return fetchUtils.get(`${BASE_URL}/${GUARDIANS}/current`);
}

function createChild(params) {
  return fetchUtils.post(`${BASE_URL}/${DEPENDENTS}`, params);
}
const addKid = (params) => {
  return fetchUtils.post(
    `${BASE_URL}/${DEPENDENTS}/${ONBOARDING_DEPENDENT}`,
    params
  );
};
function uploadPhoto(id, params) {
  return fetchUtils.postMultiForm(
    `${BASE_URL}/${DEPENDENTS}/${id}/picture`,
    params
  );
}

function uploadBanner(id, params) {
  return fetchUtils.postMultiForm(
    `${BASE_URL}/${DEPENDENTS}/${id}/banner`,
    params
  );
}

function getDependents(params) {
  const queryString = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/${DEPENDENTS}?${queryString}`);
}

function getDependentUser() {
  return fetchUtils.get(`${BASE_URL}/${DEPENDENTS}/current`);
}

function updateDependentUser(id, params) {
  return fetchUtils.patch(`${BASE_URL}/${DEPENDENTS}/${id}`, params);
}

function updateGuardianUser(id, params) {
  return fetchUtils.patch(`${BASE_URL}/${GUARDIANS}/${id}`, params);
}

function requestResetPasswordEmail(params) {
  return fetchUtils.post(`${BASE_URL}/password_reset`, params);
}

function resetPasswordWithToken(params) {
  return fetchUtils.post(`${BASE_URL}/password_reset_confirm`, params);
}

function requestDependentPasswordReset(params) {
  return fetchUtils.post(`${BASE_URL}/dependent_reset_help`, params);
}

const sendNotificationEmailToGuardian = (params) => {
  return fetchUtils.post(`${BASE_URL}/${DEPENDENTS}/send-email`, params);
};

function getDependentUserByName(params) {
  return fetchUtils.get(`${BASE_URL}/${DEPENDENTS}/current/by_username?username=${params}`);
}

function deleteDependentBanner(id) {
  return fetchUtils.requestDelete(`${BASE_URL}/${DEPENDENTS}/${id}/banner`);
}

function getTribeMembers(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/${DEPENDENTS}/tribe-dependent-list/${params.group_id}?${query}`);
}

function getAllTribeMembers(params, id) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/${SCHOOL}/public-school-dependent/${id}?${query}`);
}

function deleteDependentProfilePic(id) {
  return fetchUtils.requestDelete(`${BASE_URL}/${DEPENDENTS}/${id}/picture`);
}

export const accountRepository = {
  guardianLogin,
  dependentLogin,
  logout,
  createGuardian,
  verifyEmail,
  createChild,
  addKid,
  getUser,
  resendVerificationEmail,
  getDependents,
  uploadPhoto,
  uploadBanner,
  getGuardianUser,
  getDependentUser,
  updateDependentUser,
  updateGuardianUser,
  requestResetPasswordEmail,
  resetPasswordWithToken,
  requestDependentPasswordReset,
  sendNotificationEmailToGuardian,
  getDependentUserByName,
  deleteDependentBanner,
  getTribeMembers,
  deleteDependentProfilePic,
  getAllTribeMembers
};
