import APIError from "./api.error";

export default class ValidHttpStatusError extends APIError {
  /**
   *
   * @param {String} message
   * @param {String} statusCode
   * @param {String} resultCode
   * @param {object} serverResponse
   * @param {String} fieldName
   * @param {Array} values
   */
  constructor(
    message,
    fieldName,
    values,
    statusCode,
    resultCode,
    serverResponse
  ) {
    super(message);
    this.statusCode = statusCode;
    this.fieldName = fieldName;
    this.values = values;
    this.resultCode = resultCode;
    this.serverResponse = serverResponse;
  }
}