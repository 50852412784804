export const ComponentSize = Object.freeze({
  XSMALL: 'xsmall',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
});
export const bg = Object.freeze({
  WHITE: 'white-bg',
  PURPLE: 'purple-bg',
  GRADIENT: 'gradient-bg',
  SIGNUP: 'signup-bg',
  PAYMENT: 'payment-bg',
  CHALLENGES: 'challenges-bg',
  DARK: 'dark-bg'
});
export const theme = Object.freeze({
  FOREST: 'forestheme',
  LOVE: 'lovetheme',
  SPACE: 'spacetheme'
});
