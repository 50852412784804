import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GUARDIAN_LOGIN_ROUTE, PARTNER_LOGIN, SCHOOL_LOGIN } from '../../../constants/routes.constants';
import { accountViewModel } from './account.view.model';
import useTokenVerification from '../../common/useTokenVerification';
import { TokenStatus } from '../../../constants/tokenStatus';
import { useAuth } from '../../auth/Auth';
import { Container } from 'react-bootstrap';
import LoadButton from '../../common/LoadButton';
import EmptyHeaderWithoutSkip from '../../header/EmptyHeaderWithoutSkip';
import partnerImg from '../../../assets/images/camp-captain.svg';

const VerifyAccount = () => {
  const { logout } = useAuth();
  const history = useHistory();
  const { id, token } = useParams();

  const emailStatus = useTokenVerification(
    accountViewModel.verifyEmail,
    id,
    token
  );
  useEffect(() => {
    console.log(emailStatus)
    // if (emailStatus === TokenStatus.VERIFIED)
    // history.replace(GUARDIAN_LOGIN_ROUTE);
    if (emailStatus.tokenStatus === TokenStatus.VERIFIED && emailStatus.userType === 'guardian') {
      // logout(GUARDIAN_LOGIN_ROUTE, { "verified_email": emailStatus.email, "first_name": emailStatus.firstName });
    }
    if (emailStatus.tokenStatus === TokenStatus.VERIFIED && emailStatus.userType === 'school') {
      // logout(SCHOOL_LOGIN, { "verified_email": emailStatus.email, "first_name": emailStatus.firstName });
    }
    if (emailStatus.tokenStatus === TokenStatus.VERIFIED && emailStatus.userType === 'partner') {
      logout(PARTNER_LOGIN, { "verified_email": emailStatus.email, "first_name": emailStatus.firstName });
    }
    if (emailStatus.tokenStatus === TokenStatus.VERIFIED && emailStatus.userType === 'camp master') {
      logout(PARTNER_LOGIN, { "verified_email": emailStatus.email, "first_name": emailStatus.firstName });
    }
  }, [emailStatus]);

  const getBody = () => {
    let subTitle = '';
    if (emailStatus.tokenStatus === TokenStatus.VERIFYING) {
      return <div style={{ display: 'flex', justifyContent: 'center' }}>Verifying...</div>;
    }
    if (emailStatus.tokenStatus === TokenStatus.VERIFIED && emailStatus.userType === 'guardian') {
      return (
        <div className="guardian-login-container partner-signup-wrapper verify-account-wrapper verify-acc">
          <EmptyHeaderWithoutSkip />
          <Container style={{ marginTop: '200px' }} className="onboarding-container" fluid="md">

            <div className={`login-form-wrapper guardian-login`}>

              <div className="guardian-ic-wrapper">
                <div className="guardian-ic">
                  <img src="https://storage.googleapis.com/tale-tree-4a1cfd33/static/b18cdf9e1f6b6bbea63129665262be35.svg" alt="guardian" />
                </div>
              </div>


              <div className="title text-center">Congratulations</div>
              <div className="subTitle text-center sec-head" style={{ fontWeight: 'bold' }}>You have now activated your TaleTree guardian account.</div>
              <div className="subTitle text-center third-head" style={{ fontWeight: 'bold' }}> Verification is crucial for your child's safety. </div>

              {/* <Image style={{ margin: '20px', height: '150px' }} src={Logo} /> */}
              <div className="subTitle text-center" style={{ fontWeight: '600' }}>Please log in to complete verification and enter your child into the Emerald Forest.</div>
              <LoadButton
                text="Ok"
                className="login-btn p-2 gred-btn"
                onClick={() => logout(GUARDIAN_LOGIN_ROUTE, { "verified_email": emailStatus.email, "first_name": emailStatus.firstName })}
              />
            </div>
          </Container>
        </div>
      );
    }
    if (emailStatus.tokenStatus === TokenStatus.VERIFIED && emailStatus.userType === 'school') {
      return (
        <div className="guardian-login-container partner-signup-wrapper verify-account-wrapper verify-acc">
          <EmptyHeaderWithoutSkip />
          <Container style={{ marginTop: '200px' }} className="onboarding-container" fluid="md">

            <div className={`login-form-wrapper guardian-login`}>

              <div className="guardian-ic-wrapper">
                <div className="guardian-ic">
                  <img src={partnerImg} alt="camp" />
                </div>
              </div>


              <div style={{marginTop: '80px'}} className="title text-center">Congratulations</div>
              <div className="subTitle text-center sec-head" style={{ fontWeight: 'bold' }}>You have now activated your account.</div>

              {/* <Image style={{ margin: '20px', height: '150px' }} src={Logo} /> */}
              <LoadButton
                text="Ok"
                className="login-btn p-2 gred-btn"
                onClick={() => logout(SCHOOL_LOGIN, { "verified_email": emailStatus.email, "first_name": emailStatus.firstName })}
              />
            </div>
          </Container>
        </div>
      );
    } else {
      return (
        ''
      );
    }
  };

  return (
    <div className="verify-account-container signup-container">{getBody()}</div>
  );
};

export default VerifyAccount;
