import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl, getAPIUrlV3 } from '../utils/config.utils';
import { useAuth } from '../components/auth/Auth';

const BASE_URL = getAPIUrl();
const BASE_URL_V3 = getAPIUrlV3();
const SCHOOL = 'school';
const PARTNER = 'partner';
const CAMP_MASTER = 'camp-master'
const CAMP_CAPTAIN = 'camp-captain'

function getPartners(params) {
  const queryString = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/${PARTNER}?${queryString}`);
}

function addRemovePartner(params) {
  const queryString = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/${PARTNER}/subscribe?${queryString}`);
}

function createPartner(params) {
  return fetchUtils.post(`${BASE_URL}/${PARTNER}/onboarding-partner`, params);
}

function createExpert(params) {
  return fetchUtils.post(`${BASE_URL}/${CAMP_MASTER}/onboarding-expert`, params);
}

function partnerLogin(credentials) {
  return fetchUtils.post(`${BASE_URL}/login/${PARTNER}`, credentials);
}

function getSchoolUser() {
  return fetchUtils.get(`${BASE_URL}/${SCHOOL}/current`);
}

function getCampCaptainUser() {
  return fetchUtils.get(`${BASE_URL}/${CAMP_CAPTAIN}/current`);
}

function getPartnerUser() {
  return fetchUtils.get(`${BASE_URL}/${PARTNER}/current`);
}

function updatePartnerUser(id, params) {
  return fetchUtils.patch(`${BASE_URL}/${PARTNER}/${id}`, params);
}

function uploadPartnerPhoto(id, params) {
  return fetchUtils.postMultiForm(
    `${BASE_URL}/${PARTNER}/${id}/picture`,
    params
  );
}

function uploadCampMasterPhoto(id, params) {
  return fetchUtils.postMultiForm(
    `${BASE_URL}/${CAMP_MASTER}/upload-picture/${id}`,
    params
  );
}

function uploadPartnerBanner(id, params) {
  return fetchUtils.postMultiForm(
    `${BASE_URL}/${PARTNER}/${id}/banner`,
    params
  );
}

function uploadCampMasterBanner(id, params) {
  return fetchUtils.postMultiForm(
    `${BASE_URL}/${CAMP_MASTER}/upload-banner/${id}`,
    params
  );
}

function addTreeHouse(params) {
  return fetchUtils.post(`${BASE_URL}/${PARTNER}/add-treehouse`, params);
}

function getTreeHouses() {
  return fetchUtils.get(`${BASE_URL}/${PARTNER}/treehouse`);
}

function getTreeHouseDetails(id) {
  return fetchUtils.get(`${BASE_URL}/${PARTNER}/treehouse/${id}`);
}

function uploadBanner(id, params) {
  return fetchUtils.postMultiForm(
    `${BASE_URL}/${SCHOOL}/${id}/banner`,
    params
  );
}

function getDependents(params, groups) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/${SCHOOL}/school-dependent?${query}`);
}

function getSchoolDependents(params, groups) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/${SCHOOL}/school-dependent?${query}`);
}

function addPartnerChallenge(params, type) {
  return fetchUtils.post(`${BASE_URL}/${type === 'partner' ? PARTNER : CAMP_MASTER}/add-challenge`, params);
}

function addPartnerChallengeMedia(id, params, type) {
  return fetchUtils.postMultiForm(`${BASE_URL}/${type === 'partner' ? PARTNER : CAMP_MASTER}/upload-challenge-image/${id}`, params);
}

function getChallenges(params) {
  return fetchUtils.get(`${BASE_URL}/${SCHOOL}/challenges`);
}

function getChallengesByTreehouse(id) {
  const query = fetchUtils.buildQuery(id);
  return fetchUtils.get(`${BASE_URL}/${SCHOOL}/challenges?treehouse_id=${id}`);
}

function getUpcomingChallenges(params, type) {
  const query = fetchUtils.buildQuery(params);
  // return fetchUtils.newGet(`${BASE_URL}/${type === PARTNER ? PARTNER : CAMP_MASTER}/challenges?${query}`);
  return fetchUtils.newGet(`${BASE_URL}/challenges?${query}`);
}

function getLiveChallenges(params, type) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/challenges?${query}`);
  // return fetchUtils.newGet(`${BASE_URL}/${type === PARTNER ? PARTNER : CAMP_MASTER}/challenges?${query}`);
}

function getPastChallenges(params, type) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/challenges?${query}`);
  // return fetchUtils.newGet(`${BASE_URL}/${type === PARTNER ? PARTNER : CAMP_MASTER}/challenges?${query}`);
}

function updatePartnerDescription(id, params) {
  return fetchUtils.patch(`${BASE_URL}/${PARTNER}/update-partner`, params);
}

function updateCampMasterDescription(id, params) {
  return fetchUtils.patch(`${BASE_URL}/${CAMP_MASTER}/update-camp-master`, params);
}

function getBadgeCount() {
  return fetchUtils.get(`${BASE_URL}/${PARTNER}/badge_Count`);
}

function deletePartnerBanner(id) {
  return fetchUtils.requestDelete(`${BASE_URL}/${PARTNER}/${id}/banner`);
}

function deleteChallenge(id) {
  return fetchUtils.requestDelete(`${BASE_URL}/challenges/delete/${id}`);
}

function deleteEvent(id) {
  return fetchUtils.requestDelete(`${BASE_URL}/${PARTNER}/delete-event/${id}`);
}

function addExpert(params) {
  return fetchUtils.post(`${BASE_URL}/${PARTNER}/add-expert`, params);
}

function updateExpert(params) {
  return fetchUtils.post(`${BASE_URL}/${PARTNER}/update-expert`, params);
}

function uploadExpertImage(id, params) {
  return fetchUtils.postMultiForm(`${BASE_URL}/${PARTNER}/${id}/camp-master-picture`, params);
}

function getExperts(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/${PARTNER}/expert-list?partner_id=${params.partner_id}`);
}

function addEvent(params, type) {
  return fetchUtils.post(`${BASE_URL}/${type === PARTNER ? PARTNER : CAMP_MASTER}/add-event`, params);
}

function updateEvent(params, id, type) {
  return fetchUtils.patch(`${BASE_URL}/${type === PARTNER ? PARTNER : CAMP_MASTER}/update-event/${id}`, params);
}

function addEventMedia(id, params, type) {
  return fetchUtils.postMultiForm(`${BASE_URL}/${type === PARTNER ? PARTNER : CAMP_MASTER}/upload-event-image/${id}`, params);
}

function getUpcomingEvents(params, type, id) {
  const query = fetchUtils.buildQuery(params);
  if(type === "partner") {
    return fetchUtils.newGet(`${BASE_URL}/special_camps/${type === PARTNER ? PARTNER : CAMP_MASTER}/events/list?${query}`);
  } else {
    return fetchUtils.newGet(`${BASE_URL}/${CAMP_MASTER}/event-list/${id}?${query}`);
  }
}

function getLiveEvents(params, type, id) {
  const query = fetchUtils.buildQuery(params);
  if(type === "partner") {
    return fetchUtils.newGet(`${BASE_URL}/special_camps/${type === PARTNER ? PARTNER : CAMP_MASTER}/events/list?${query}`);
  } else {
    return fetchUtils.newGet(`${BASE_URL}/${CAMP_MASTER}/event-list/${id}?${query}`);
  }
}

function getPastEvents(params, type, id) {
  const query = fetchUtils.buildQuery(params);
  if(type === "partner") {
    return fetchUtils.newGet(`${BASE_URL}/special_camps/${type === PARTNER ? PARTNER : CAMP_MASTER}/events/list?${query}`);
  } else {
    return fetchUtils.newGet(`${BASE_URL}/${CAMP_MASTER}/event-list/${id}?${query}`);
  }
}

function getEventDetails(id) {
  return fetchUtils.get(`${BASE_URL}/special_camps/${id}`);
}

function addExpertAgoraGroupId(id, params) {
  return fetchUtils.put(`${BASE_URL}/${PARTNER}/update-agora-group-id/${id}`, params);
}

export const partnerRepository = {
  getPartners,
  addRemovePartner,
  createPartner,
  createExpert,
  partnerLogin,
  getSchoolUser,
  getPartnerUser,
  updatePartnerUser,
  updatePartnerDescription,
  uploadPartnerPhoto,
  uploadPartnerBanner,
  addTreeHouse,
  getTreeHouses,
  getTreeHouseDetails,
  getDependents,
  getSchoolDependents,
  addPartnerChallenge,
  addPartnerChallengeMedia,
  getChallenges,
  getChallengesByTreehouse,
  getUpcomingChallenges,
  getLiveChallenges,
  getPastChallenges,
  getBadgeCount,
  deletePartnerBanner,
  deleteChallenge,
  deleteEvent,
  addExpert,
  updateExpert,
  uploadExpertImage,
  getExperts,
  addEvent,
  updateEvent,
  addEventMedia,
  getUpcomingEvents,
  getLiveEvents,
  getPastEvents,
  getEventDetails,
  uploadCampMasterBanner,
  uploadCampMasterPhoto,
  updateCampMasterDescription,
  addExpertAgoraGroupId,
  getCampCaptainUser
};
