// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/email-sent.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "body{padding-bottom:50px;color:#321b6d}body .emphasize{font-weight:bold;color:#32c1bd}html{height:100%;overflow:auto}.form-control-focus,.form-control:focus{color:#495057;background-color:#fff;border-color:#321b6d;outline:0;box-shadow:unset}.form-control::-webkit-input-placeholder{color:#afafaf}.form-control:-moz-placeholder{color:#afafaf}.form-control::-moz-placeholder{color:#afafaf}.form-control:-ms-input-placeholder{color:#afafaf}.form-control::-ms-input-placeholder{color:#afafaf}.update-needed-container{display:grid;grid-template-rows:auto auto 1fr;column-gap:10px}.update-needed-container .update-needed .title{margin-bottom:2rem}.update-needed-container .image-layer{width:100%;height:155px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50% 50%}", ""]);
// Exports
module.exports = exports;
