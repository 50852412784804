import React, { useState, useMemo, useEffect } from 'react';
import { Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FormLabel from '../../common/FormLabel';
import FormInput from '../../common/FormInput';
import ErrorLabel from '../../common/ErrorLabel';
import { ERROR_REQUIRED_PASSWORD } from '../../../constants/error.constants';
import { displayFormErrorsFromApi } from '../../api/api.display.error';
import FormPasswordInput from '../../common/FormPasswordInput';
import ResponseErrorNotification from '../../api/ResponseErrorNotification';
import { useResponseError } from '../../api/response.error';
import ValidHttpStatusError from '../../api/api.error.valid';
// import kakaoImg from "../../../assets/images/kakao_login_large_wide.png";
import kakaoImg from "../../../assets/images/kakao-svg-1.svg";
import GoogleImg from "../../../assets/images/google-svg.svg";
import msImg from "../../../assets/images/ms-svg.svg";
import './scss/login.scss';
import LoadButton from '../../common/LoadButton';
import guardianImg from '../../../assets/images/login-guardian.svg';
import { kakaoLoginURL, microsoftLoginURL } from "../../../utils/utils";
import { GoCheck } from '@react-icons/all-files/go/GoCheck';
import { customEvents } from '../../../analytics/customEvents';
import KakaoImg from "../../../assets/images/kakao-svg.svg";
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleLogin from '../onboarding/signup_popups/GoogleLogin';
import { getGoogleOAuthClientId } from '../../../utils/config.utils';

const LoginForm = ({
  type,
  title,
  subTitle,
  userInput,
  userPlaceholder,
  errorMessageUsername,
  forgotPasswordRoute,
  login,
  forgotPasswordText,
  isGuardianLogin = "false"
}) => {
  const { register, handleSubmit, setError, errors, watch } = useForm({
    mode: 'onBlur',
  });
  const location = useLocation();
  const defaultEmail = useMemo(() => location?.state?.verified_email);
  const { addError, removeError } = useResponseError();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const googleClientId = getGoogleOAuthClientId()

  const onSubmit = (data) => {
    let analyticsData = {
      category: "Guardian Login",
      action: "guardian login button",
      label: 'guardian login button'
    }
    customEvents(analyticsData);
    // removeError;
    setIsLoading(true);
    login(data).catch((error) => {
      if (error instanceof ValidHttpStatusError) {
        displayFormErrorsFromApi(error, setError);
      } else {
        addError(error.message);
      }
      setIsLoading(false);
    });
  };

  const handleKakaoClick = () => {
    setIsDisable(true)
  }

  useEffect(() => {
    if (watch('username')) {
      let analyticsData = {
        category: "Guardian Login",
        action: "guardian email",
        label: 'guardian email'
      }
      customEvents(analyticsData);
    }
  }, [watch('username')])

  useEffect(() => {
    if (watch('password')) {
      let analyticsData = {
        category: "Guardian Login",
        action: "guardian password",
        label: 'guardian password'
      }
      customEvents(analyticsData);
    }
  }, [watch('password')])

  const handleMicrosoftClick = () => {
    history.push({
      state: {
        from: 'login',
      }
    })
  }

  return (
    <div className={`login-form-wrapper guardian-login-1 ${type}-login`}>
      <div className="guardian-ic-wrapper">
        <div className="guardian-ic">
          <img src={guardianImg} alt='guardian' />
        </div>
      </div>
      <div className="title text-center">{title}</div>
      <div className="subTitle text-center">{subTitle}</div>
      <ResponseErrorNotification />
      <Row className="input-wrapper mt-5">
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group className="container-fluid">
                <FormLabel>
                  {userInput}
                  <span>*</span>
                </FormLabel>
                {defaultEmail &&
                  <FormLabel className="float-right text-theme">
                    E-Mail validated <span className='support-lable-check'><GoCheck /></span>
                  </FormLabel>
                }
                <FormInput
                  defaultValue={defaultEmail}
                  name="username"
                  placeholder={userPlaceholder}
                  data-testid="username"
                  ref={register({ required: errorMessageUsername })}
                  className="email-color"
                />
                <ErrorLabel errors={errors} name="username" as="label" />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group className="container-fluid mb-0">
                <FormLabel>
                  Password<span>*</span>
                </FormLabel>
                {type === "guardian" ?
                  <FormPasswordInput
                    name="password"
                    placeholder="Password"
                    data-testid="password"
                    ref={register({ required: ERROR_REQUIRED_PASSWORD })}
                  />
                  :
                  <FormPasswordInput
                    name="password"
                    placeholder="Type password"
                    data-testid="password"
                    ref={register({ required: ERROR_REQUIRED_PASSWORD })}
                  />}
                <ErrorLabel errors={errors} name="password" as="label" />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <div className="forgot-password">
                <Link to={{
                  pathname: forgotPasswordRoute,
                  state: {
                    from: 'guardian'
                  }
                }}>
                  {forgotPasswordText}
                </Link>
              </div>
            </Form.Row>
            <Form.Row>
              <div className="remember-me">
                <input
                  defaultChecked
                  type="checkbox"
                  name="remember_me"
                  ref={register()}
                />
                &nbsp; Remember me
              </div>
            </Form.Row>
            <Form.Row className="login-submit">
              <Form.Group className="container-fluid mb-0">
                <LoadButton
                  text="Login"
                  isDisabled={isLoading || isDisable}
                  isLoading={isLoading}
                  className="login-btn p-2 gred-btn"
                  testid="submit"
                />
              </Form.Group>
            </Form.Row>
            {isGuardianLogin == "true" && <div className="account-main kakao-main">

              {googleClientId &&
                <GoogleOAuthProvider clientId={googleClientId}>
                  <GoogleLogin />
                </GoogleOAuthProvider>
              }
              <a href={microsoftLoginURL()} onClick={handleMicrosoftClick}>
                <div className="account-details"> <span>
                  <img src={msImg} />
                </span>Continue with Microsoft Account</div>
              </a>

              <a href={kakaoLoginURL()} onClick={handleKakaoClick}>
                {isDisable ?
                  <div className='spinner-circle' style={{ margin: "0 auto !important", justifyContent: "center", alignContent: "center" }}>
                    <Spinner
                      as="span"
                      animation="border"
                      size='small'
                      aria-hidden="true"
                    />
                  </div>
                  :
                  // <div className="kakao-image">
                  //   <img src={kakaoImg} className="w-100 h-100" />
                  // </div>

                  <div className="account-details kakao-btn">
                    <span>
                      <img src={kakaoImg} />
                    </span>
                    Continue with Kakao
                  </div>

                }
              </a>

            </div>}
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default LoginForm;