import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();

function getNotes(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/note?${query}`);
}

function getDependentNotes(params, dependent_id) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/note/dependent/${dependent_id}?${query}`);
}

function getDependentNotesByMonth(params, dependent_id) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.newGet(`${BASE_URL}/note/dependent/${dependent_id}?${query}`);
}

function addNote(data) {
  return fetchUtils.post(`${BASE_URL}/note/add`, data);
}

function updateNote(data) {
  return fetchUtils.put(`${BASE_URL}/note/update`, data);
}

function deleteNote(note_id) {
  return fetchUtils.requestDelete(`${BASE_URL}/note/delete/${note_id}`);
}

function noteReply(data) {
  return fetchUtils.post(`${BASE_URL}/note/note_reply`, data);
}

function noteSeen(id) {
  return fetchUtils.put(`${BASE_URL}/note/readable/${id}`);
}

export const notesRepository = {
  getNotes,
  getDependentNotes,
  getDependentNotesByMonth,
  addNote,
  updateNote,
  deleteNote,
  noteReply,
  noteSeen,
};