import React from 'react';
import { Modal, ModalBody, ModalTitle } from 'react-bootstrap';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import closeIcon from '../../../assets/icon/icon-darker-close.svg';
import SuccessNote from '../../../assets/icon/Success_Note.svg';
import LoadButton from '../../common/LoadButton';

const ModalHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    position: 'relative',
}));

const ReplySuccessModal = ({ showModal, setShowModal, getDependentNotes }) => {

    const closeModal = () => {
        setShowModal(false);
        getDependentNotes();
    };

    return (
        <Modal id='delete-note-modal' className='delete-modal-note' show={showModal} onHide={closeModal} centered>
            <ModalHeader>
                <ModalTitle>
                    <div className="close-button" data-dismiss="modal" onClick={closeModal}>
                        <img src={closeIcon} alt="Close" />
                    </div>
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <img id='delete-note-image' className="delete-note-img" src={SuccessNote} />
                <Typography>
                    Congratulations!
                </Typography>
                <Typography className='this-delete-note'>
                    Your reply has been sent successfully
                </Typography>
            </ModalBody>
            <LoadButton
                text="Ok"
                className='gred-btn delete-note-btn'
                onClick={closeModal}
            />
        </Modal>
    );
};

export default ReplySuccessModal;
