import React, { useState } from 'react';
import { isEqualString, isStrNullOrEmpty } from './utils';
import moment from 'moment-timezone';
import letsgoProfile from '../assets/profile/default-profile-letsgo.svg';
import codyProfile from '../assets/profile/default-profile-cody.svg';
import rushmoreProfile from '../assets/profile/default-profile-rushmore.svg';
import domaProfile from '../assets/profile/default-profile-doma.svg';
import rootyProfile from '../assets/profile/default-profile-rooty.svg';
import uniProfile from '../assets/profile/default-profile-uni.svg';
import defaultProfile from '../assets/profile/default-profile-guardian.svg';
import uni from '../assets/images/uni.svg';
import rushmore from '../assets/images/rushmore.svg';
import rooty from '../assets/images/rooty.svg';
import lletsgo from '../assets/images/lletsgo.svg';
import doma from '../assets/images/doma.svg';
import cody from '../assets/images/cody.svg';
import codyChallenge from '../assets/images/cody-onboard-challenge.svg';
import uniChallenge from '../assets/images/uni-onboard-challenge.svg';
import rootyChallenge from '../assets/images/rooty-onboard-challenge.svg';
import lletsgoChallenge from '../assets/images/letsgo-onboard-challenge.svg';
import rushmoreChallenge from '../assets/images/rushmore-onboard-challenge.svg';
import domaChallenge from '../assets/images/doma-onboard-challenge.svg';
import letsgoJson from '../assets/json/letsgo.json';
import codyJson from '../assets/json/cody.json';
import rootyJson from '../assets/json/rooty.json';
import rushmoreJson from '../assets/json/rushmore.json';
import uniJson from '../assets/json/Uni.json';
import domaJson from '../assets/json/doma.json';
import soundWaveJson from '../assets/json/sound_wave.json';
const campStatusMap = new Map([
  ['pending_interview', 'Pending interview'],
  ['pending_group_assignment', 'Pending group assignment'],
  ['pending_payment', 'Pending payment'],
  ['camp_member', 'Camp member'],
  ['cancelled', 'Canceled'],
]);

export const getDefaultProfileImage = (friend) => {
  // console.log("friend", friend)
  let image;
  switch (friend) {
    case 'letsgo':
      image = letsgoProfile;
      break;
    case 'cody':
      image = codyProfile;
      break;
    case 'rushmore':
      image = rushmoreProfile;
      break;
    case 'doma':
      image = domaProfile;
      break;
    case 'rooty':
      image = rootyProfile;
      break;
    case 'uni':
      image = uniProfile;
      break;
    default:
      image = defaultProfile;
  }
  return image;
};

export const getNotificationImage = (friend) => {
  // console.log("friend", friend)
  let image;
  switch (friend) {
    case 'letsgo':
      image = lletsgo;
      break;
    case 'cody':
      image = cody;
      break;
    case 'rushmore':
      image = rushmore;
      break;
    case 'doma':
      image = doma;
      break;
    case 'rooty':
      image = rooty;
      break;
    case 'uni':
      image = uni;
      break;
    default:
      image = defaultProfile;
  }
  return image;
};

export const getSelectChallengePopupImage = (friend) => {
  // console.log("friend", friend)
  let image;
  switch (friend) {
    case 'letsgo':
      image = lletsgoChallenge;
      break;
    case 'cody':
      image = codyChallenge;
      break;
    case 'rushmore':
      image = rushmoreChallenge;
      break;
    case 'doma':
      image = domaChallenge;
      break;
    case 'rooty':
      image = rootyChallenge;
      break;
    case 'uni':
      image = uniChallenge;
      break;
    default:
      image = defaultProfile;
  }
  return image;
};

export const getSelectedFriendJson = (friend) => {
  // console.log("friend", friend)
  let json;
  switch (friend) {
    case 'letsgo':
      json = letsgoJson;
      break;
    case 'cody':
      json = codyJson;
      break;
    case 'rushmore':
      json = rushmoreJson;
      break;
    case 'doma':
      json = domaJson;
      break;
    case 'rooty':
      json = rootyJson;
      break;
    case 'uni':
      json = uniJson;
      break;
    default:
      json = letsgoJson;
  }
  return json;
}

export const getTaletreeFriendJson = (friend) => {
  const friendJson = getSelectedFriendJson(friend)
  return friendJson;
}

export const getSoundWaveJson = () => {
  return soundWaveJson;
}

export const changeTextToVoice = (text, isPlay, setIsPlay, isPaused, setIsPaused) => {
  // const [utterance, setUtterance] = useState(null);
  const synth = window.speechSynthesis;
  const u = new SpeechSynthesisUtterance(text);
  if (!isPlay) {
    // setUtterance(u);
    if (isPaused) {
      synth.resume();
    } else {
      synth.speak(u);
    }
  } else {
    synth.pause();
    setIsPaused(true);
  }
  u.onend = (event) => {
    setIsPlay(false);
    setIsPaused(false);
  };
  return () => {
    synth.cancel();
  };
}

export const getUserProfileUrl = (user) => {
  let userProfileUrl = user?.profile?.picture_url;
  const taletreeFriend = user?.profile?.taletree_friend;
  return isStrNullOrEmpty(userProfileUrl)
    ? getDefaultProfileImage(taletreeFriend)
    : userProfileUrl;
};

export const getCommentUserProfileUrl = (user) => {
  let userProfileUrl = user.picture_url;
  return isStrNullOrEmpty(userProfileUrl)
    ? letsgoProfile
    : userProfileUrl;
};

export const getUserBannerUrl = (user) => {
  let userBannerUrl = user.profile.banner_url;
  // const taletreeFriend = user.profile.taletree_friend;
  return isStrNullOrEmpty(userBannerUrl)
    ? userBannerUrl
    : userBannerUrl;
};

export const getUserNotificationChar = (user) => {
  const taletreeFriend = user.profile.taletree_friend;
  return getNotificationImage(taletreeFriend)
};

export const getUserOnboardingChallengeChar = (user) => {
  const taletreeFriend = user.profile.taletree_friend;
  return getSelectChallengePopupImage(taletreeFriend)
};


export const getStatusColor = (status) => {
  if (isPendingPayment(status)) return 'danger';
  return isCampMemberInSetting(status) ? 'success' : 'light-grey';
};

export const getCampStatus = (status) => {
  return campStatusMap.get(status) || '';
};

export const isCampMember = (status) => {
  return isEqualString(status, 'camp_member');
};

export const isCampMemberInSetting = (status) => {
  return (
    isEqualString(status, 'camp_member') ||
    isEqualString(status, 'pending_interview') ||
    isEqualString(status, 'pending_group_assignment')
  );
};

export const isCanceled = (status) => {
  return isEqualString(status, 'cancelled');
};

export const isPendingInterview = (status) => {
  return isEqualString(status, 'pending_interview');
};

export const isPendingPayment = (status) => {
  return isEqualString(status, 'pending_payment');
};

export const capitalizeFirstLetter = (word) => {
  return isStrNullOrEmpty(word)
    ? ''
    : word.charAt(0).toUpperCase() + word.slice(1);
};

export const guardianNameCorrection = (str) => {
  if (!str) return '';
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const getMyLocalTime = (day, time, timezone) => {
  const dayMap = {
    sunday: '1',
    monday: '2',
    tuesday: '3',
    wednesday: '4',
    thursday: '5',
    friday: '6',
    saturday: '7',
  };
  const date = `2020-03-0${dayMap[day]}`; //placeholder to normalize time 3/1 is a sunday
  const currTime = moment.tz(`${date} ${time}`, timezone);
  const newTime = currTime.local().format('dddd, h:mm A');
  return newTime;
};

const addZero = (data) => {
  let tempTime = data;
  if (tempTime < 10) {
    tempTime = "0" + tempTime
  }
  return tempTime;
}

export const getSpecialCountTime = (day, timezone) => {
  let d = new Date(day)
  let temp_month = d.getMonth() + 1
  let temp_date = d.getDate()
  if (temp_month < 10) {
    temp_month = "0" + String(temp_month)
  }
  if (temp_date < 10) {
    temp_date = "0" + String(temp_date)
  }
  let tempHours = d.getHours()
  let tempMins = d.getMinutes()
  const date = d.getFullYear() + "-" + temp_month + "-" + temp_date
  const time = addZero(tempHours) + ":" + addZero(tempMins)
  const currTime = moment.tz(`${date} ${time}`, timezone);
  const newTime = currTime.local().format('dddd, MMMM D, YYYY h:mm A');
  return new Date(newTime).getTime() - Date.now();
}

export const getUpcomingTime = (day, time, timezone) => {
  const dayMap = {
    sunday: 7,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };
  var d = new Date();
  d.setDate(d.getDate() + (dayMap[day] + 7 - d.getDay()) % 7);
  let temp_month = d.getMonth() + 1
  let temp_date = d.getDate()
  if (temp_month < 10) {
    temp_month = "0" + String(temp_month)
  }
  if (temp_date < 10) {
    temp_date = "0" + String(temp_date)
  }
  const date = d.getFullYear() + "-" + temp_month + "-" + temp_date
  const currTime = moment.tz(`${date} ${time}`, timezone);
  const newTime = currTime.local().format('dddd, MMMM D, YYYY h:mm A');
  return new Date(newTime).getTime() - Date.now();
};

const getNextDayOfWeek = (date, dayOfWeek) => {
  const resultDate = new Date(date.getTime());
  resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
  return resultDate;
};

export const getUpcomingDate = (day, time, timezone) => {
  const dayMap = {
    sunday: 7,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };
  const today = new Date();
  const nextDay = getNextDayOfWeek(today, dayMap[day]); // 1 corresponds to Monday

  return nextDay;
};

export const showPrice = (price) => {
  const _dollar = '$';
  const _dot = '.';
  const _cuttingPoint = price.length - 2;
  const _head = price.slice(0, _cuttingPoint);
  const _tail = (
    <span className="price-tail">{price.slice(_cuttingPoint)}</span>
  );
  return _head.length >= 3 ? (
    <div>
      {_dollar}
      {_head}
    </div>
  ) : (
    <div>
      {_dollar}
      {_head}
      {_dot}
      {_tail}
    </div>
  );
};

export const getPrice = (price) => {
  price = String(price) === '0' ? '000' : String(price);
  const _dollar = '$';
  const _dot = '.';
  const _cuttingPoint = price.length - 2;
  const _head = price.slice(0, _cuttingPoint);
  const _tail = price.slice(_cuttingPoint);
  return _head.length >= 3 ? _dollar + _head : _dollar + _head + _dot + _tail;
};
