import React from "react";
import LogoHorizontal from "../../../../assets/images/tt-hori-logo.svg";
import VarifiedBadge from "../../../../assets/images/verified-badge.png";
import { BsArrowRight } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { KID_LOGIN_ROUTE, LOGIN_ROUTE } from "../../../../constants/routes.constants";
import { getUserProfileUrl } from "../../../../utils/camp.utils";
import SignupModal from "./SignupModal";
import LogoMobile from "../../../../assets/images/logo-white-p.svg";
import SignupFooterLinks from "./SignupFooterLinks";
import { Modal } from "react-bootstrap";

export default function SignupStep2(props) {
    const { showModal, setShowModal, creator, setShowStep3 } = props;
    const history = useHistory();
    const handleSignupClick = () => {
        setShowModal(false);
        setShowStep3(true);
    }

    const handleClose = () => {
        setShowModal(false);
    }

    const onLoginClick = () => {
        localStorage.setItem('kids_login_location', window.location.href)
        history.push(KID_LOGIN_ROUTE)
    }

    return (
        <Modal className='signupPopup signupPopu-treehouse mobile-v-popup' show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className='row step-1-row'>
                    <div className="col-md-6 mobile-up toparrow p-4">
                        <div className="d-flex justify-content-between mobile-none">
                            <div className="logo">
                                <img src={LogoHorizontal} />
                            </div>
                            <div className="login-btn" onClick={onLoginClick}>
                                <button style={{ border: '1px solid #321b6d', color: '#321b6d' }} className="btn btn-outline hover-underline">Login</button>
                            </div>
                        </div>
                        <div className="varified-div">
                            <img style={{ height: '140px', width: '140px', borderRadius: '70px' }} src={creator.profile.picture_url ? creator.profile.picture_url : getUserProfileUrl(creator)} />
                            <img className="varified-badge" src={VarifiedBadge} />
                        </div>
                        <p className="primary-text"><span>{creator.username}'s parents</span> choose the Emerald Forest for their kid to tackle fun Challenges with like-minded friends and get inspirations from one another and role-model professionals.</p>
                        <button className="gred-btn btn" onClick={handleSignupClick}>Get your kid a FREE verified account today <BsArrowRight /></button>
                    </div>
                    <div className="col-md-6 left-side-step-1 bg-p">
                        <div className="d-flex justify-content-between desktop-none">
                            <div className="logo">
                                <img src={LogoMobile} />
                            </div>
                            <div className="login-btn popup-login-btn" onClick={onLoginClick}>
                                <button className="btn btn-outline">Login</button>
                            </div>
                        </div>
                        <div className="popup-bg ">
                            <p>Which TaleTree Friend should be your companion in the Emerald Forest?</p>
                        </div>
                        <SignupFooterLinks />
                    </div>
                </div>
                <div className="for-mobile-only">
                    <SignupFooterLinks />
                </div>
            </Modal.Body>
        </Modal>
    )
}