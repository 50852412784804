import { useGoogleLogin } from "@react-oauth/google";
import React from "react";
import { useHistory } from "react-router";
import { GOOGLE_CALLBACK_SCREEN } from "../../../../constants/routes.constants";
import GoogleImg from "../../../../assets/images/google-svg.svg";

const GoogleLogin = () => {
    const history = useHistory();
    const login = useGoogleLogin({
        ux_mode: "redirect",
        onSuccess: async (response) => {
            history.push({
                pathname: GOOGLE_CALLBACK_SCREEN,
                state: {
                    access_token: response.access_token
                }
            })
        },
        onError: (error) => console.log("Login Failed:", error),
    });

    return (
        <a onClick={() => login()}>
            <div className="account-details"> <span>
                <img src={GoogleImg} />
            </span>Continue with Google</div>
        </a>
    )
}

export default GoogleLogin;