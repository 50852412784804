import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import { GUARDIAN_LOGIN_ROUTE, KID_LOGIN_ROUTE, ON_BOARDING_PARTNER } from "../../../../constants/routes.constants";
import LogoMobile from "../../../../assets/images/taletree-logo.png";
import SignupFooterLinks from "./SignupFooterLinks";
import kakaoImg from "../../../../assets/images/kakao-svg-1.svg";
import msImg from "../../../../assets/images/ms-svg.svg";
import { customEvents } from "../../../../analytics/customEvents";
import "./modals-style.css";
import { kakaoLoginURL, microsoftLoginURL } from "../../../../utils/utils";
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleLogin from "./GoogleLogin";
import { getGoogleOAuthClientId } from "../../../../utils/config.utils";
import { useAuth } from "../../../auth/Auth";
import GuardianGuestModal from "./GuardianGuestModal";

export default function SignupStep1({ showModal, onClose, setShowNextPopup }) {
    const history = useHistory();
    const { guardian, logout } = useAuth();
    const [showModal_parent, setShowModal_parent] = React.useState(showModal);
    const [showModal_child, setShowModal_child] = React.useState(false);
    const [showGuardianGuestModal, setShowGuardianGuestModal] = React.useState(guardian ? true : false);
    const [isDisable, setIsDisable] = useState(false);
    const googleClientId = getGoogleOAuthClientId()
    const handleSignupClick = () => {
        let data = {
            category: "Sign up",
            action: "get your kid verified today",
            label: 'get your kid verified today'
        }
        customEvents(data);
        onClose();
        setShowNextPopup(true)
    }

    const handleClose = () => {
        setShowModal_child(!showModal_child)
        onClose();
    }
    const handleClose_parent = () => {
        setShowModal_parent(!showModal_parent);
        setShowModal_child(!showModal_child)
    }
    const onLoginClick = () => {
        let analyticsData = {
            category: "Sign up",
            action: "did you already verify your account login here",
            label: 'did you already verify your account login here'
        }
        customEvents(analyticsData);
        localStorage.setItem('kids_login_location', window.location.href);
        logout(KID_LOGIN_ROUTE);
    }

    const onGuardianLoginClick = () => {
        let analyticsData = {
            category: "Sign up",
            action: "did you already verify your account login here",
            label: 'did you already verify your account login here'
        }
        customEvents(analyticsData);
        localStorage.setItem('kids_login_location', window.location.href);
        history.push(GUARDIAN_LOGIN_ROUTE);
    }

    const handleKakaoClick = () => {
        setIsDisable(true)
    }

    const handleMicrosoftClick = () => {
        history.push({
            state: {
                from: 'signup',
            }
        })
    }

    const handleMainClose = () => {
        setShowModal_parent(!showModal_parent)
    }

    return (
        <>
            <Modal className={`${guardian ? 'account-varification my-room-modal' : 'signupPopup login-screen-mobile mobile-v-popup signupstep-bg'}`} show={showModal_parent} centered onClose={handleMainClose}>
                {guardian ?
                    <GuardianGuestModal
                        handleClose={handleClose}
                        onLoginClick={onLoginClick}
                    />
                    :
                    <Modal.Body >
                        <div className="row" style={{ marginTop: "0px" }}>
                            <div className="col-md-12 p-0">
                                <div className="popup-bg-first first-screen">
                                    <div className="header-visit signupstep-1">
                                        <SignupFooterLinks />
                                    </div>
                                    <div className="popup-bg-main">
                                        <div style={{ width: "100%", textAlign: "right" }} onClick={handleClose}>
                                            <Modal.Header closeButton>
                                            </Modal.Header>
                                        </div>
                                        <div className="logo logo-details">
                                            <img src={LogoMobile} />
                                            <h1>A Community of
                                                Creative and Kind Kids</h1>
                                            {guardian ?
                                                <h2 className="primary-text restricted-msg userNotification">
                                                    You cannot perform this action as a guardian. But if you login as a kid you can continue with this activity.
                                                </h2>
                                                :
                                                <h2 className="primary-text">
                                                    Where your kid’s imagination flourishes and every idea is celebrated!
                                                </h2>
                                            }
                                        </div>
                                        {guardian ?
                                            <div className="login-btn popup-login-btn" onClick={onLoginClick}>
                                                <button className="btn btn-outline login-btn login-signup1">Login</button>
                                            </div>
                                            :
                                            <>
                                                <div className="buttons-acc">
                                                    <div className="gred-btn btn" onClick={() => handleClose_parent()}>
                                                        <button className="create-btn">Create account </button><span>|</span><button className="create-btn">parent </button></div>
                                                    <div className="gred-btn btn partner" onClick={() => history.push(ON_BOARDING_PARTNER)} >
                                                        <button className="create-btn">Create account </button><span>|</span><button className="create-btn">partner </button></div>
                                                </div>
                                                <div className="see-it-as-a-guest" onClick={handleClose}>
                                                    <span className="continue-span">Continue as a guest</span>
                                                </div>
                                                <div className="login-btn popup-login-btn" onClick={onLoginClick}>
                                                    <button className="btn btn-outline login-btn login-signup1">Login</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                }
            </Modal>
            <Modal className='signupPopup login-screen-mobile mobile-v-popup main-modal' show={showModal_child} onHide={handleClose} centered >
                <Modal.Body className="modal-parents" style={{ width: "100%", height: "auto" }}>
                    <div style={{ width: "100%", textAlign: "right" }}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                    </div>
                    <div className="guardian-heading">Create your guardian account</div>
                    <button className="gred-btn mobile-none btn create-acc-btn" disabled={isDisable} onClick={handleSignupClick}>Create your account <BsArrowRight /></button>
                    <div className="modal-main">
                        <div className="login-btn popup-login-btn" onClick={onGuardianLoginClick}>
                            Already have an account? <button className="login-guardian">Log in</button>
                        </div>
                    </div>
                    <h3 className="title-around-span"><span>Or</span></h3>
                    <div className="account-main kakao-main">
                        {googleClientId &&
                            <GoogleOAuthProvider clientId={googleClientId}>
                                <GoogleLogin />
                            </GoogleOAuthProvider>
                        }
                        <a href={microsoftLoginURL()} onClick={handleMicrosoftClick}>
                            <div className="account-details"> <span>
                                <img src={msImg} />
                            </span>Continue with Microsoft Account</div>
                        </a>
                        <a href={kakaoLoginURL()} onClick={handleKakaoClick}>
                            {isDisable ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size='small'
                                    aria-hidden="true"
                                />
                                :
                                <div className="account-details kakao-btn">
                                    <span>
                                        <img src={kakaoImg} />
                                    </span>
                                    Continue with Kakao
                                </div>
                            }
                        </a>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )
}