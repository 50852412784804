import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import FormLabel from '../../common/FormLabel';
import FormInput from '../../common/FormInput';
import ErrorLabel from '../../common/ErrorLabel';
import LoadButton from '../../common/LoadButton';
import { guardianLoginErrors } from '../../../constants/error.constants';
import { useAuth } from '../../auth/Auth';
import { accountViewModel } from './account.view.model';
import {
  FORGOT_PASSWORD_EMAIL_ROUTE,
  TREEHOUSE_LOGIN,
} from '../../../constants/routes.constants';
import OnboardingWrapper from '../onboarding/OnboardingWrapper';
import { bg } from '../../../constants/component.constants';
import { changeBodyClassName } from '../../../utils/utils';

import './scss/forgot.password.scss';

function ForgotParentPassword() {
  const { register, handleSubmit, setError, errors } = useForm({
    mode: 'onBlur',
  });
  const { fetchAPI } = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    changeBodyClassName([bg.GRADIENT]);
  }, []);

  const onSubmit = (data) => {
    setIsLoading(true);
    fetchAPI(accountViewModel.requestResetPasswordEmail, data)
      .then((reponse) => {
        history.push({
          pathname: FORGOT_PASSWORD_EMAIL_ROUTE,
          state: { loginRoute: TREEHOUSE_LOGIN },
        });
      })
      .catch((error) => {
        console.error(error);
        if (error?.statusCode === 400) {
          setError('email', {
            type: 'manual',
            message: error?.serverResponse?.result_code,
          });
        }
        setIsLoading(false);
      });
  };

  return (
    <OnboardingWrapper className="forgot-password-container login-container">
      <div className="login-form-wrapper forgot-guardian-password">
        <div className="title">Reset Password</div>
        <div className="subTitle mt-3 mb-4">
          Enter your email address to reset your password
        </div>
        <Row className="input-wrapper">
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
              <Form.Row>
                <Form.Group className="container-fluid mb-0">
                  <FormLabel>
                    Email<span>*</span>
                  </FormLabel>
                  <FormInput
                    name="email"
                    placeholder="partner@email.com"
                    data-testid="email"
                    ref={register({
                      required: guardianLoginErrors.email.required,
                      validate: (input) =>
                        isEmail(input) || guardianLoginErrors.email.invalid,
                    })}
                  />
                  <ErrorLabel errors={errors} name="email" as="label" />
                </Form.Group>
              </Form.Row>
              <Form.Row className="login-submit mt-5">
                <Form.Group className="container-fluid mb-0">
                  <LoadButton
                    className="login-btn p-2"
                    text="Reset"
                    isLoading={isLoading}
                    isDisabled={isLoading}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </Col>
        </Row>
      </div>
      <Row className="go-back-link-container">
        <Link to={TREEHOUSE_LOGIN}>Back to Login</Link>
      </Row>
    </OnboardingWrapper>
  );
}

export default ForgotParentPassword;
