import React, { useEffect, useState } from 'react';
import { useAuth } from '../auth/Auth';
import { TokenStatus } from '../../constants/tokenStatus';
function useTokenVerification(apiCall, ...rest) {
  const { fetchAPI } = useAuth();
  const [tokenStatus, setTokenStatus] = useState(TokenStatus.VERIFYING);
  const [verificationStatus, setVerificationStatus] = useState({
    tokenStatus: TokenStatus.VERIFYING,
    userType: '',
    firstName: '',
    email: ''
  });

  useEffect(() => {
    fetchAPI(apiCall, ...rest)
      .then((res) => {
        console.log(res)
        // setTokenStatus(TokenStatus.VERIFIED);
        setVerificationStatus({
          tokenStatus: TokenStatus.VERIFIED,
          userType: res.user_type,
          firstName: res.first_name,
          email: res.email
        })
      })
      .catch(() => {
        setVerificationStatus((prev) => ({...prev, tokenStatus: tokenStatus.FAILED}))
        // setTokenStatus(TokenStatus.FAILED);
      });
  }, []);

  return verificationStatus;
}

export default useTokenVerification;
