import React, { useEffect } from 'react';
import { Image, Navbar } from 'react-bootstrap';
import TaletreeHeader from './TaletreeHeader';
import logo from '../../assets/logo/logo-53x53.svg';
import skipArrow from '../../assets/icon/Arrow-white.svg';

import './css/camp.header.scss';
import { useLocation } from 'react-router-dom';
import { HOME_FEED } from '../../constants/routes.constants';
import { changeBodyClassName } from '../../utils/utils';
import { bg } from '../../constants/component.constants';

const EmptyHeader = () => {

  const location = useLocation();

  useEffect(() => {
    changeBodyClassName([bg.CHALLENGES])
    localStorage.setItem('mode', 'dark')
  }, [])

  return (
    <TaletreeHeader className="camp-header">
      <a href="https://taletree.com">
        <Navbar.Brand>
          <Image src={logo} className="logo" />
        </Navbar.Brand>
      </a>
      {location.pathname === "/account/login/kid" ||
        location.pathname === "/account/login/guardian" ||
        location.pathname === "/account/check-email" ||
        location.pathname === "/onboarding/schoolRegistration" ||
        location.pathname === "/partner/check_email" ||
        location.pathname === "/account/login/school" ||
        location.pathname === "/account/login/partner" ||
        location.pathname.slice(0, 6) === "/reset" ||
        location.pathname.slice(0, 4) === "/kid" ||
        location.pathname.slice(0, 6) === "/email" ?
        "" :
        <Navbar.Text>
          <span className='guest-mode'>
            {location.pathname === "/account/login" ?
              <a href={HOME_FEED} style={{ textDecoration: 'none', marginRight: '0.5em' }}>Skip for now</a>
              : <a href={HOME_FEED} style={{ textDecoration: 'none', marginRight: '0.5em' }}>Enter guest mode</a>}
            <Image src={skipArrow} alt="" className='skip_arrow' />
          </span>
        </Navbar.Text>
      }
    </TaletreeHeader>
  );
};

export default EmptyHeader;
