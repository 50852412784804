import React from 'react';
import { ErrorMessage } from '@hookform/error-message';

function ErrorLabel(props) {
  const className = props.className || 'p1-1 small';
  return (
    <>
      <ErrorMessage
        className={`${className} text-danger`}
        errors={props.errors}
        name={props.name}
        as="label"
      />
    </>
  );
}

export default ErrorLabel;
