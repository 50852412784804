import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Image, Spinner, Table } from 'react-bootstrap';
import DashboardHeader from '../header/DashboardHeader';
import { useHistory } from 'react-router-dom';
import "./binkiesCompliments.css";
import { Box } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import { HiOutlineChevronLeft } from '@react-icons/all-files/hi/HiOutlineChevronLeft';
import { notesViewModel } from '../views/school/notes.view.model';
import { formatDate } from '../../utils/utils';
import challengesCheck from '../../assets/images/challenges-check.svg';
import { getCommentUserProfileUrl } from '../../utils/camp.utils';
import View_Icon from '../../assets/icon/View.svg';
import ViewNoteModal from '../views/school/ViewNoteModal';
import { GUARDIAN_SETTINGS_ROUTE } from '../../constants/routes.constants';
import ReplySuccessModal from '../views/school/ReplySuccessModal';
import SeeAllWrapper from './SeeAllWrapper';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: 8,
        boxShadow: 'none',
        padding: 2,
    },
    card: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 1,
        padding: 1,
        borderRadius: 8,
        boxShadow: 'none'
        // backgroundColor: '#f4f6f8',
    },
    avatar: {
        marginRight: 2,
    },
    content: {
        flex: '1 0 auto',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2,
    },
    message: {
        display: 'inline-block',
        padding: '0.5, 1',
        borderRadius: 4,
    },
}));

export default function SeeAllNotes() {
    const classes = useStyles();
    const history = useHistory();
    const dependent = JSON.parse(history?.location?.state?.dependent)
    const _PAGE_LIMIT = 3
    const [compliments, setCompliments] = useState([]);
    const [viewNoteText, setViewNoteText] = useState('');
    const [showViewNoteModal, setShowViewNoteModal] = useState(false);
    const [showReplySuccessModal, setShowReplySuccessModal] = useState(false);

    const [query, setQuery] = useState({
        limit: _PAGE_LIMIT,
        has_more: true,
        dependent_id: dependent?.id
    });

    const getComplimentsReceived = () => {
        const list = compliments;
        notesViewModel.getDependentNotes(query, dependent?.id, list).then((res) => {
            setCompliments(res.commentList);
            setQuery(res.query)
        }).catch((error) => {
            console.log(error);
        });
    }

    const handleGobackClick = () => {
        history.push(GUARDIAN_SETTINGS_ROUTE);
    }

    const handleViewNote = (text) => {
        setShowViewNoteModal(true);
        setViewNoteText(text);
    }

    return (
        <SeeAllWrapper>
            {showViewNoteModal && <ViewNoteModal text={viewNoteText} showModal={showViewNoteModal} setShowModal={setShowViewNoteModal} setShowReplySuccessModal={setShowReplySuccessModal} />}
            {showReplySuccessModal && <ReplySuccessModal showModal={showReplySuccessModal} setShowModal={setShowReplySuccessModal} getDependentNotes={getComplimentsReceived} />}
            <Box className={`${classes.root} short_second_card`}>
                <div className='treehouse-right-action'>
                    <div className="table-container">
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={getComplimentsReceived}
                            hasMore={query.has_more}
                            threshold={150}
                            className="home-feed-spinner-wrapper"
                            loader={
                                <Spinner
                                    key={0}
                                    className="home-feed-spinner"
                                    animation="border"
                                    variant="info"
                                />
                            }
                        >
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Camp Captain</th>
                                        <th>Notes</th>
                                        <th>Date</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {compliments.length ? compliments.map((item, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <div className='kids_name_img' role='button'>
                                                        <Image className="KidsAvtar"
                                                            src={getCommentUserProfileUrl(item?.sender)}
                                                            style={{ height: '30px', width: '30px', borderRadius: '100%' }}
                                                        />
                                                        {item?.sender?.first_name} {item?.sender?.last_name}
                                                    </div>
                                                </td>
                                                <td className='notes-text-truncate'>{item?.content}</td>
                                                <td>{formatDate(item?.created_at)}</td>
                                                <td className='badge'>
                                                    <Image className='write-notes add-pointer' onClick={() => handleViewNote(item)} src={View_Icon} alt='view' />
                                                    {item?.is_seen ?
                                                        <span className='number-counter-img'><img src={challengesCheck} /></span>
                                                        :
                                                        <span className='number-counter'>1</span>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                        : ''
                                    }
                                </tbody>
                            </Table>
                        </InfiniteScroll>
                    </div>
                </div>
            </Box>
        </SeeAllWrapper>
    );
}
