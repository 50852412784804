import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import logoHeader from '../../../assets/logo/logo-70x70.svg';

import './scss/account.header.scss';

const AccountHeader = (props) => {
  return (
    <div className={`account-header-container ${props.className}`}>
      <LinkContainer to="/">
        <img src={logoHeader} className="logo" />
      </LinkContainer>
      <div className="first-message">
        <span>{props.firstMessage}</span>
      </div>
      <div className="title-container">
        <h1 className="title">{props.title}</h1>
        <p className="sub-title">{props.subTitle}</p>
      </div>
      {props.children}
    </div>
  );
};

AccountHeader.defaultProps = {
  className: '',
  firstMessage: '',
  title: '',
  subTitle: '',
};

export default AccountHeader;
