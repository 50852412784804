import React, { useEffect, useState } from 'react';
import SignupStep3 from './SignupStep3';
import SignupStep4 from './SignupStep4';
import SignupStep6 from './SignupStep6';

export default function SignupProcess({ signupProcess, isVerified, setSignupProcess }) {
    const [showStep3, setShowStep3] = useState(signupProcess);
    const [showStep4, setShowStep4] = useState(false);
    const [showStep6, setShowStep6] = useState(false);

    const togglePopup3 = () => setShowStep3(!showStep3);

    const handleStep3Complete = () => {
        setShowStep3(false);
        setShowStep4(true);
    }

    const handleStep4Complete = () => {
        setShowStep3(false);
        setShowStep6(true);
    }

    useEffect(() => {
        if(!isVerified) {
            setShowStep3(false);
            setShowStep4(false);
            setShowStep6(true);
        }
    })

    return (
        <>
            {/* <SignupStep3 showModal={showStep3} setShowModal={setShowStep3} handleComplete={handleStep3Complete} setSignupProcess={setSignupProcess} /> */}
            {/* {showStep4 && <SignupStep4 showModal={showStep4} setShowModal={setShowStep4} handleComplete={handleStep4Complete} setSignupProcess={setSignupProcess} />} */}
            {showStep3 && <SignupStep4 showModal={showStep3} onClose={togglePopup3} setShowNextPopup={setShowStep6} handleComplete={handleStep4Complete} />}
            <SignupStep6 showModal={showStep6} setShowModal={setShowStep6} setSignupProcess={setSignupProcess} />
        </>
    )
}