import React from 'react';
import EmptyHeader from '../../header/EmptyHeader';
import DashboardHeader from '../../header/DashboardHeader';
import { Container } from 'react-bootstrap';
import './scss/onboarding.scss';

const OnboardingWrapper = ({ children, type = 'type1', className }) => {
  const wrappingClassName = `onboarding-wrapper ${className}`;
  if (type === 'type1') {
    return (
      <div className={wrappingClassName}>
        <EmptyHeader />
        <Container className="onboarding-container login-container" fluid="md">
          {children}
        </Container>
        <div className={type}></div>
        <div className="lensflare"></div>
      </div>
    );
  } else {
    return (
      <div className={wrappingClassName}>
        <DashboardHeader />
        <Container className="onboarding-container" fluid="md">
          {children}
        </Container>
      </div>
    );
  }
};

export default OnboardingWrapper;
