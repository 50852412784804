import React from "react";
import { Image, Modal } from "react-bootstrap";
import GuardianImage from "../../../../assets/images/guardian-modal-popup.svg";

export default function GuardianGuestModal({ onLoginClick, handleClose }) {
    return (
        <>
            <Modal.Body>
                <div style={{ width: "100%", textAlign: "right" }} onClick={handleClose}>
                    <Modal.Header className="close-restricted" closeButton>
                    </Modal.Header>
                </div>
                <Modal.Title>
                    <div className='wonderful_image'>
                        <Image src={GuardianImage} alt="" />
                    </div>
                </Modal.Title>
                <div className='welcome-to-room guardian-restricted-div'>
                    <h3 className='mb-10 mt-20'>
                        You cannot perform this action as a guardian.
                    </h3>
                    <p class="upgraded-p">But if you login as a kid you can continue with this activity.</p>
                </div>
                <div className="login-btn" onClick={onLoginClick}>
                    <button className="btn btn-outline login-btn login-signup1 gred-btn guardian-restricted-msg">Login</button>
                </div>
            </Modal.Body>
        </>
    )
}