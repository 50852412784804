import React from 'react';
import koreanFlag from '../../../../assets/icon/south-korea.svg';

export default function SignupFooterLinks() {
    return (
        <div>
            <ul className="list-menus">
                <li><a href="https://web.taletree.com/">Home</a></li>
                <li><a href="https://web.taletree.com/about">About</a></li>
                <li><a href="https://web.taletree.com/product">Product</a></li>
                <li><a href="https://web.taletree.com/partners">Partners</a></li>
                <li><a href="https://web.taletree.com/safety">Safety</a></li>
                <li><a href="https://web.taletree.com/community">Community</a></li>
                <li>
                    <a href="https://web.taletree.com/ko" target='_blank'>
                        <img src={koreanFlag} alt='Korean' width="28px" />
                    </a>
                </li>
            </ul>
        </div>
    )
}