import React, { useState, useMemo, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { displayFormErrorsFromApi } from '../../api/api.display.error';
import { useResponseError } from '../../api/response.error';
import ValidHttpStatusError from '../../api/api.error.valid';
import '../account/scss/login.scss';
import { MY_PARTNER_ROUTE, SCHOOL_LOGIN, TREEHOUSE_LOGIN } from '../../../constants/routes.constants';
import {
  HOME_FEED,
} from '../../../constants/routes.constants';
import { useAuth } from '../../auth/Auth';
import '../account/scss/kid.login.scss';
import OnboardingWrapper from '../onboarding/OnboardingWrapper';
import RoundButton from '../../common/RoundButton';

const PartnerLogin = () => {
  const { partnerLogin, dependent, guardian } = useAuth();

  useEffect(() => {
    // changeBodyClassName([bg.GRADIENT]);
    window.scrollTo(0, 0);
    if (dependent || guardian) {
      history.push(HOME_FEED);
    }
  }, []);

  const { register, handleSubmit, setError, errors } = useForm({
    mode: 'onBlur',
  });
  const location = useLocation();
  const defaultEmail = useMemo(() => location?.state?.verified_email);
  const { addError, removeError } = useResponseError();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const onSubmit = (data) => {
    setIsLoading(true);
    partnerLogin(data).then((res) => {
      history.push(MY_PARTNER_ROUTE);
    }).catch((err) => {
      if (err instanceof ValidHttpStatusError) {
        displayFormErrorsFromApi(err, setError);
      } else {
        addError(err.message);
      }
      setIsLoading(false);
    })
  };

  return (
    <OnboardingWrapper className="select-login-type login-container login-container-wrapper partner-signup-wrapper">
      <div className="login-head">
        <div className="title-col">
          <h2 className="login-title">Partners</h2>
        </div>
      </div>
      <Row className="login-section partners-login w-100">
        <Col className="treehouses-n-experts-login kid-login login-box">
          <div className="character" />
          <div className="features">
            <ul>
              <li className="feature">Build up subscribers</li>
              <li className="feature">Set Challenges</li>
              <li className="feature">Set (special) premium challenges</li>
              <li className="feature">Set Live (special) events</li>
            </ul>
          </div>
          <div className="button-wrapper">
            <RoundButton
              type="button"
              label="Treehouses and Experts"
              className="login-button gred-btn"
              onClick={() => history.push(TREEHOUSE_LOGIN)}
            />
          </div>
        </Col>
        <Col className="camp-captain-login login-box has-left-margin">
          <div className="character" />
          <div className="features">
            <ul>
              <li className="feature">Manage your camp</li>
              <li className="feature">Monitor your tribe</li>
              <li className="feature">Chat and set challenges</li>
            </ul>
          </div>
          <div className="button-wrapper">
            <RoundButton
              type="button"
              label="I'm a Camp Captain"
              className="login-button gred-btn"
              onClick={() => history.push(SCHOOL_LOGIN)}
            />
          </div>
        </Col>
      </Row>
    </OnboardingWrapper>
  );
};

export default PartnerLogin;