import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import YouTube from 'react-youtube';
import { useAuth } from '../../auth/Auth';
import { SCHOOL_LOGIN } from '../../../constants/routes.constants';
import { bg } from '../../../constants/component.constants';
import { changeBodyClassName } from '../../../utils/utils';
import OnboardingModal from '../onboarding/OnboardingModal';
import '../account/scss/check.email.scss';
import '../onboarding/scss/sign.up.scss';
import RoundButton from '../../common/RoundButton';
import EmptyHeader from '../../header/EmptyHeader';
import { accountViewModel } from '../account/account.view.model';

const CheckEmail = ({ location }) => {
  const { school, logout } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const options = {
    width: '504',
    height: '283',
  };

  const _onReady = (e) => {
    e.target.pauseVideo();
  };

  const handleLoginClick = (e) => {
    logout(SCHOOL_LOGIN)
  };

  const resendVerificationLink = () => {
    accountViewModel
      .resendVerificationEmail()
      .then(() => {
        setShowModal(true);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    changeBodyClassName([bg.GRADIENT]);
  }, []);

  return (
    <div className='sign-up-wrapper partner-signup-wrapper partner_check_email'>
      <EmptyHeader />
      <Container className="onboarding-container" fluid="md">
        <Row className="content-wrapper">
          <Col className="content">
            <div className="title">
              We are so happy to have you with us!
            </div>
            <div className="thin-border-line"></div>
            <div className="sub title">
              An e-mail has been sent to <a href='#' className="resend-email">{school.email}</a> with a link to verify your account. In the meantime, watch
              the video to learn more about TaleTree
              <br />
              <br />
              Didn't receive the email?{' '}
              <span className="resend-email" onClick={resendVerificationLink}>
                Resend Email
              </span>
            </div>
            <div className="youtube-wrapper">
              <YouTube
                videoId="JFZXyJvLcrI"
                opts={options}
                className="player"
                onReady={_onReady}
              />
            </div>
            <div className="sub title login-wrapper">
              <p>
                Did you already verify your account?{' '}
                <span className="login" onClick={handleLoginClick}>
                  Log in Here
                </span>
              </p>
            </div>
          </Col>
        </Row>
        <OnboardingModal
          show={showModal}
          onHide={handleClose}
          className="check-your-email-dialog"
        >
          <div className="upper">
            <h1>Check your E-mail</h1>
            <div className="subtitle">
              We just resent you a link to verify your e-mail address. Please
              check your e-mail and click the link to activate your account.
              Rememember to check your Spam or ALL folder if you cannot find the
              e-mail.
            </div>
          </div>
          <div className="bottom">
            <RoundButton
              type="button"
              label="OK"
              className="close-button"
              onClick={handleClose}
            />
          </div>
        </OnboardingModal>
      </Container>
    </div>
  );
};
export default CheckEmail;
