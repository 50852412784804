import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import {
  GUARDIAN_LOGIN_ROUTE,
  KID_LOGIN_ROUTE,
  PARTNER_LOGIN,
} from '../../../constants/routes.constants';
import RoundButton from '../../common/RoundButton';
import OnboardingWrapper from '../onboarding/OnboardingWrapper';
import './scss/login.scss';

const Login = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  // useEffect(() => {
  //   changeBodyClassName([bg.GRADIENT]);
  // }, []);

  return (
    <>
      <OnboardingWrapper className="select-login-type login-container-wrapper login-container partner-signup-wrapper adults-signup-wrapper">
        <div className="login-head">
          <div className="title-col">
            <h2 className="login-title">Adults</h2>
          </div>
        </div>
        <Row className="login-section adults-login w-100">
          <Col className="i-am-guardian kid-login login-box">
            <div className="character" />
            <div className="features">
              <ul>
                <li className="feature">Add additional kids to your account</li>
                <li className="feature">Check your kids progress</li>
                <li className="feature">Connect camps (schools)</li>
                <li className="feature">Unlock insight reports</li>
                <li className="feature">Upgrade plans</li>
              </ul>
            </div>
            <div className="button-wrapper">
              <RoundButton
                type="button"
                label="I'm a Guardian"
                className="login-button gred-btn"
                onClick={() => history.push(GUARDIAN_LOGIN_ROUTE)}
              />
            </div>
          </Col>
          <Col className="i-am-partner kid-login login-box has-left-margin">
            <div className="character" />
            <div className="features">
              <ul>
                <li className="feature">Experts log in here</li>
                <li className="feature">Camp Captains log in here</li>
                <li className="feature">Treehouse admin log in here</li>
              </ul>
            </div>
            <div className="button-wrapper">
              <RoundButton
                type="button"
                label="I'm a Partner"
                className="login-button gred-btn"
                onClick={() => history.push(PARTNER_LOGIN)}
              />
            </div>
          </Col>
        </Row>
        {/* <Row className="login-bottom">
          <Col className="empty">&nbsp;</Col>
          <Col>
            Don’t have an account?&nbsp;{' '}
            <span className='underline-span' onClick={() => setShowModal(true)}>
              Sign up here</span>
          </Col>
        </Row> */}
        {/* <NewSignUp showModal={showModal} setShowModal={setShowModal} /> */}
      </OnboardingWrapper>
    </>
  );
};

export default Login;
