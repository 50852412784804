import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import loaderJson from '../../../assets/json/Loader.json';
import { useLocation } from 'react-router-dom';
import { onboardingViewModel } from './onboarding.view.model';
import { useAuth } from '../../auth/Auth';
import { useHistory } from 'react-router-dom';
import { GUARDIAN_SETTINGS_ROUTE, HOME_FEED, SSO_REDIRECT_SCREEN } from '../../../constants/routes.constants';

const GoogleCallBackScreen = () => {
    const location = useLocation();
    const [playLoader, setPlayLoader] = useState(true);
    const { setUser } = useAuth();
    const { state } = location
    const access_token = state?.access_token
    const history = useHistory();

    const handleLogin = async () => {
        try {
            const response = await onboardingViewModel.loginGoogleUser(access_token)
            if (response) {
                setUser(response);
                if (response?.dependents.length) {
                    history.push(GUARDIAN_SETTINGS_ROUTE)
                } else {
                    history.push(SSO_REDIRECT_SCREEN)
                }
            }
        } catch (error) {
            console.error('Error:', error);
            history.goBack()
        }
    };

    useEffect(() => {
        if (access_token) handleLogin();
    }, [access_token])

    return (
        <div className="map-loader">
            <Lottie
                loop={true}
                play={playLoader}
                animationData={loaderJson}
                className="map-loader-in"
            />
        </div>
    )
}

export default GoogleCallBackScreen;