import React from 'react';
import { Button } from 'react-bootstrap';
import './scss/round.button.css';
import PropTypes from 'prop-types';

function RoundButton(props) {
  const { disabled = false } = props;
  const buttonStyle = {
    backgroundColor: props.backgroundColor,
  };
  return (
    <Button
      className={`common-round-btn ${props.className}`}
      style={buttonStyle}
      type={props.type}
      block={props.block}
      variant={props.variant}
      data-testid={props.testId}
      onClick={props.onClick}
      disabled={disabled}
    >
      <span>{props.label}</span>
    </Button>
  );
}

// RoundButton.propTypes = {
// label: PropTypes.string.isRequired,
//   backgroundColor: PropTypes.string,
//   className: PropTypes.string,
//   type: PropTypes.string,
//   variant: PropTypes.string,
//   testId: PropTypes.string.isRequired,
//   onClick: PropTypes.func,
// };

RoundButton.defaultProps = {
  type: 'submit',
  backgroundColor: '',
  className: '',
  variant: 'primary',
  block: true,
  onClick: () => {},
  testId: '',
};

export default RoundButton;
