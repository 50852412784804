import { partnerRepository } from '../../../repository/partner.repository';

const getNextQuery = (params, list, has_more) => {
  const lastId = list.length > 0 ? list[list.length - 1].id : 0;
  const nextQuery = { ...params };
  nextQuery.starting_after = lastId;
  nextQuery.has_more = has_more;
  return nextQuery;
};

const mergeList = (previousList, newList) => {
  const list = previousList ? [...previousList, ...newList] : newList;
  let newIdSet = new Set();
  const uniqueList = list.filter((item) => {
    let isUnique = false;
    if (!newIdSet.has(item.id)) {
      newIdSet.add(item.id);
      isUnique = true;
    }

    return isUnique;
  });
  return uniqueList;
}; 

const mergeChallengeList = (previousList, newList) => {
  const list = previousList ? [...previousList, ...newList] : newList;
  let idSet = new Set();

  const uniqueList = list.filter((item) => {
    let isUnique = false;
    if (!idSet.has(item.id)) {
      idSet.add(item.id);
      isUnique = true;
    }
    return isUnique;
  });
  return uniqueList;
};

const createPartner = (params) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .createPartner(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createExpert = (params) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .createExpert(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const partnerLogin = (params) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .partnerLogin(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updatePartnerProfilePic(
  id,
  profilePicture,
  partner
) {
  const profileUpdatePromises = [];

  const formBody = new FormData();
  formBody.append('image', profilePicture);
  profileUpdatePromises.push(partnerRepository.uploadPartnerPhoto(id, formBody));

  return new Promise((resolve, reject) => {
    Promise.all(profileUpdatePromises)
      .then((response) => {
        let newPartner = { ...partner };
        if (response[0]) {
          newPartner = { ...response[0].data };
        }
        if (response[1]) {
          newPartner.profile.picture_url = profilePicture.preview;
        }
        resolve(newPartner);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateCampMasterProfilePic(
  id,
  profilePicture,
  partner
) {
  const profileUpdatePromises = [];

  const formBody = new FormData();
  formBody.append('image', profilePicture);
  profileUpdatePromises.push(partnerRepository.uploadCampMasterPhoto(id, formBody));

  return new Promise((resolve, reject) => {
    Promise.all(profileUpdatePromises)
      .then((response) => {
        let newPartner = { ...partner };
        if (response[0]) {
          newPartner = { ...response[0].data };
        }
        if (response[1]) {
          newPartner.profile.picture_url = profilePicture.preview;
        }
        resolve(newPartner);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updatePartnerBannerPic(
  id,
  bannerPicture,
  partner
) {
  const bannerUpdatePromises = [];

  const formBody = new FormData();
  formBody.append('image', bannerPicture);
  bannerUpdatePromises.push(partnerRepository.uploadPartnerBanner(id, formBody));

  return new Promise((resolve, reject) => {
    Promise.all(bannerUpdatePromises)
      .then((response) => {
        let newPartner = { ...partner };
        if (response[0]) {
          newPartner = { ...response[0].data };
        }
        if (response[1]) {
          newPartner.profile.banner = bannerPicture.preview;
        }
        resolve(newPartner);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateCampMasterBannerPic(
  id,
  bannerPicture,
  partner
) {
  const bannerUpdatePromises = [];

  const formBody = new FormData();
  formBody.append('image', bannerPicture);
  bannerUpdatePromises.push(partnerRepository.uploadCampMasterBanner(id, formBody));

  return new Promise((resolve, reject) => {
    Promise.all(bannerUpdatePromises)
      .then((response) => {
        let newPartner = { ...partner };
        if (response[0]) {
          newPartner = { ...response[0].data };
        }
        if (response[1]) {
          newPartner.profile.banner = bannerPicture.preview;
        }
        resolve(newPartner);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const addTreeHouse = (params) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .addTreeHouse(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getTreeHouses = () => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getTreeHouses()
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getTreeHouseDetails = (id) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getTreeHouseDetails(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getDependents = (params, previousList, groups) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getDependents(params, groups)
      .then((res) => {
        const List = mergeList(previousList, res.data);
        const query = getNextQuery(params, List, res.has_more);
        const data = {
            List,
            query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getSchoolDependents = (params, previousList, groups) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getSchoolDependents(params, groups)
      .then((res) => {
        const List = mergeList(previousList, res.data);
        const query = getNextQuery(params, List, res.has_more);
        const data = {
            List,
            query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const addPartnerChallenge = (params, type) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .addPartnerChallenge(params, type)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function addPartnerChallengeMedia(id, image, type) {
  const formBody = new FormData();
  formBody.append('image', image)
  return new Promise((resolve, reject) => {
    partnerRepository
      .addPartnerChallengeMedia(id, formBody, type)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getChallenges = (params) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getChallenges(params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getChallengesByTreehouse = (id) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getChallengesByTreehouse(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getUpcomingChallenges = (params, upcomingChallengeList, type) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getUpcomingChallenges(params, type)
      .then((res) => {
        const response = res.data;
        const newList = mergeChallengeList(upcomingChallengeList, response);
        const query = getNextQuery(params, newList, res.has_more);
        const data = {
          newList,
          query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getLiveChallenges = (params, liveChallengeList, type) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getLiveChallenges(params, type)
      .then((res) => {
        const response = res.data;
        const newList = mergeChallengeList(liveChallengeList, response);
        const query = getNextQuery(params, newList, res.has_more);
        const data = {
          newList,
          query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getPastChallenges = (params, pastChallengeList, type) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getPastChallenges(params, type)
      .then((res) => {
        const response = res.data;
        const newList = mergeChallengeList(pastChallengeList, response);
        const query = getNextQuery(params, newList, res.has_more);
        const data = {
          newList,
          query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const updatePartnerPassword = (id, email, formData) => {
  const credentials = {
    username: email,
    password: formData.password,
    remember_me: false,
  };
  const newPassword = {
    password: formData.new_password,
  };
  return new Promise((resolve, reject) => {
    partnerRepository
      .partnerLogin(credentials)
      .then(() => {
        return partnerRepository.updatePartnerUser(id, newPassword);
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.resultCode === 400001) {
          error.message = 'Invalid password';
        }
        reject(error);
      });
  });
};

const updatePartnerDescription = (id, params) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .updatePartnerDescription(id, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const updateCampMasterDescription = (id, params) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .updateCampMasterDescription(id, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getBadgeCount = () => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getBadgeCount()
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const deletePartnerBanner = (id) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .deletePartnerBanner(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const deleteChallenge = (id) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .deleteChallenge(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const deleteEvent = (id) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .deleteEvent(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const addExpert = (id) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .addExpert(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const updateExpert = (id) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .updateExpert(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function uploadExpertImage(id, image) {
  const formBody = new FormData();
  formBody.append('image', image)
  return new Promise((resolve, reject) => {
    partnerRepository
      .uploadExpertImage(id, formBody)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getExperts = (params, expertsList) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getExperts(params)
      .then((res) => {
        const response = res.data;
          const newList = mergeChallengeList(expertsList, response);
          const query = getNextQuery(params, newList, res.has_more);
          const data = {
            newList: newList,
            query,
          };
          resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const addEvent = (params, type) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .addEvent(params, type)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const updateEvent = (params, id, type) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .updateEvent(params, id, type)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function addEventMedia(id, image, type) {
  const formBody = new FormData();
  formBody.append('image', image)
  return new Promise((resolve, reject) => {
    partnerRepository
      .addEventMedia(id, formBody, type)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getUpcomingEvents = (params, upcomingEventList, type, id) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getUpcomingEvents(params, type, id)
      .then((res) => {
        const response = res.data;
        const newList = mergeChallengeList(upcomingEventList, response);
        const query = getNextQuery(params, newList, res.has_more);
        const data = {
          newList,
          query,
        };
        resolve(data);
      })
      .catch((error) => {
        console.log(error)
        reject(error);
      });
  });
}

const getLiveEvents = (params, liveEventList, type, id) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getLiveEvents(params, type, id)
      .then((res) => {
        const response = res.data;
        const newList = mergeChallengeList(liveEventList, response);
        const query = getNextQuery(params, newList, res.has_more);
        const data = {
          newList,
          query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getPastEvents = (params, pastEventList, type, id) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getPastEvents(params, type, id)
      .then((res) => {
        const response = res.data;
        const newList = mergeChallengeList(pastEventList, response);
        const query = getNextQuery(params, newList, res.has_more);
        const data = {
          newList,
          query,
        };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const getEventDetails = (id) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .getEventDetails(id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const addExpertAgoraGroupId = (id, params) => {
  return new Promise((resolve, reject) => {
    partnerRepository
      .addExpertAgoraGroupId(id, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const partnerViewModel = {
  createPartner,
  createExpert,
  partnerLogin,
  updatePartnerPassword,
  updatePartnerProfilePic,
  updatePartnerBannerPic,
  updatePartnerDescription,
  addTreeHouse,
  getTreeHouses,
  getTreeHouseDetails,
  getDependents,
  getSchoolDependents,
  addPartnerChallenge,
  addPartnerChallengeMedia,
  getChallenges,
  getChallengesByTreehouse,
  getUpcomingChallenges,
  getLiveChallenges,
  getPastChallenges,
  getBadgeCount,
  deletePartnerBanner,
  deleteChallenge,
  deleteEvent,
  addExpert,
  updateExpert,
  uploadExpertImage,
  getExperts,
  addEvent,
  updateEvent,
  addEventMedia,
  getUpcomingEvents,
  getPastEvents,
  getLiveEvents,
  getEventDetails,
  updateCampMasterBannerPic,
  updateCampMasterProfilePic,
  updateCampMasterDescription,
  addExpertAgoraGroupId
};
