import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import SignUp from './SignUp';
import GuardianLogin from './GuardianLogin';
import CheckEmail from './CheckEmail';
import { useAuth } from '../../auth/Auth';
import {
  FINISH_SIGNUP_ROUTE,
  MY_ROOM_ROUTE,
  LOGIN_ROUTE,
  KID_LOGIN_ROUTE,
  GUARDIAN_LOGIN_ROUTE,
  GUARDIAN_SETTINGS_ROUTE,
  MY_SCHOOL_ROUTE,
  MY_PARTNER_ROUTE,
  PARTNER_LOGIN,
  SCHOOL_LOGIN,
  SCHOOL_ROUTE,
  PARTNER_ROUTE,
  HOME_FEED,
  TREEHOUSE_LOGIN,
  CAMP,
} from '../../../constants/routes.constants';
import Login from './Login';
import KidLogin from './KidLogin';
import { Spinner } from 'react-bootstrap';
import { getSession } from '../../../utils/session.utils';
import GuardianRoute from '../../routes/GuardianRoute';
import PartnerLogin from '../school/PartnersLogin';
import SchoolLogin from '../school/SchoolLogin';
import TreehouseLogin from '../school/TreehouseLogin';
import EventEmitter from '../../../utils/events';
import { HOWS_YOUR_DAY } from '../../../constants/companionPrompt.constants';

function Account({ history, match }) {
  const { path } = match;
  const {
    guardian,
    deactivatedDependents,
    dependent,
    refreshUserState,
    partner,
    school,
    campMaster
  } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const handleDependent = () => {
    if (dependent && !dependent.profile.taletree_friend) {
      history.push(MY_ROOM_ROUTE)
    } else {
      if (localStorage.getItem('kids_login_location')) {
        window.location.replace(localStorage.getItem('kids_login_location'));
        localStorage.removeItem('kids_login_location')
      } else {
        // EventEmitter.dispatch('show_prompt', {
        //   showPrompt: 'block',
        //   promptType: HOWS_YOUR_DAY
        // })
        history.push({
          pathname: HOME_FEED,
          state: {
            from: 'login'
          }
        });
      }
    }
  };
  const handleGuardian = () => {
    // let route;
    // const hasSubscription =
    //   deactivatedDependents.filter(
    //     (dependent) => dependent.subscriptions !== ''
    //   ).length > 0;

    // if (!guardian.is_verified) {
    //   // route = `${path}/check-email`;
    //   // setIsLoading(false);
    //   // } else if (guardian.is_verified && guardian.dependents.length === 0) {
    //   //   route = `${FINISH_SIGNUP_ROUTE}/create-children`;
    // } else if (hasSubscription) {
    //   route = {
    //     pathname: `${GUARDIAN_SETTINGS_ROUTE}/activate-kid`,
    //     state: { dependent: deactivatedDependents[0], refreshState: true },
    //   };
    // } else {
    //   route = GUARDIAN_SETTINGS_ROUTE;
    // }

    history.push(GUARDIAN_SETTINGS_ROUTE);
  };

  const handleSchool = () => {
    let route;
    if (school?.user_type === 'school' && !school.email_verified) {
      route = `${CAMP}/check-email`
      // route = MY_SCHOOL_ROUTE
    } else {
      route = MY_SCHOOL_ROUTE
    }
    history.push(route);
  }

  const handlePartner = () => {
    let route;
    if (partner.user_type === "partner" && !partner.is_verified) {
      route = `${PARTNER_ROUTE}/check-email`;
    } else {
      route = MY_PARTNER_ROUTE
    }
    history.push(route);
  }

  const handleCampMaster = () => {
    let route;
    route = MY_PARTNER_ROUTE
    history.push(route);
  }

  useEffect(() => {
    if (getSession()) {
      if (guardian) {
        handleGuardian();
      } else if (dependent) {
        handleDependent();
      } else if (school) {
        handleSchool();
      } else if (partner) {
        handlePartner();
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [guardian, dependent, school, partner]);

  useEffect(() => {
    setIsLoading(true);
    refreshUserState();
  }, []);

  return (
    <div>
      {isLoading && (guardian || dependent || school || partner || getSession()) ? (
        <div className="w-100 h-100 align-items-center justify-content-center d-flex">
          <Spinner
            className={`${isLoading ? '' : 'd-none'}`}
            animation="border"
            size="lg"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <div>
          <Switch>
            <Route exact path={LOGIN_ROUTE} component={Login} />
            <Route exact path={KID_LOGIN_ROUTE} component={KidLogin} />
            <Route exact path={PARTNER_LOGIN} component={PartnerLogin} />
            <Route exact path={SCHOOL_LOGIN} component={SchoolLogin} />
            <Route exact path={TREEHOUSE_LOGIN} component={TreehouseLogin} />
            <Route
              exact
              path={GUARDIAN_LOGIN_ROUTE}
              component={GuardianLogin}
            />
            <Route render={() => <Redirect to={{ pathname: '/' }} />} />
          </Switch>
        </div>
      )}
    </div>
  );
}

export default Account;
