import APIError from "./api.error";

export default class InvalidHttpStatusError extends APIError {
  /**
   *
   * @param {String} message
   * @param {String} statusCode
   * @param {object} serverResponse
   */
  constructor(
    message,
    statusCode,
    serverResponse
  ) {
    super(message);
    this.statusCode = statusCode;
    this.serverResponse = serverResponse;
  }
}