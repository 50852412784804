import ReactGA from 'react-ga4';

export const customEvents = ( data ) => {
    if (data) {
        ReactGA.event({
            category: data?.category,
            action: data?.action,
            label: data?.label,
            value: data?.value
        })
    }
};
