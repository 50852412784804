import React from 'react';
import { Alert } from 'react-bootstrap';
import { useResponseError } from './response.error';

function ResponseErrorNotification() {
  const { error, removeError } = useResponseError();

  const handleClose = () => {
    removeError();
  };

  return (
    <div>
      {error && error.message && 
        <Alert className="text-center" variant="danger" onClose={handleClose} dismissible>
          {error.message}
      </Alert>
      }
    </div>
  );
  
}

export default ResponseErrorNotification;
