import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../auth/Auth';
import { LOGIN_ROUTE } from '../../constants/routes.constants';
import { Spinner } from 'react-bootstrap';

function GuardianRoute({ component: Component, ...rest }) {
  const { guardian, isLoading } = useAuth();
  return (
    <div>
      {isLoading ? (
        //todo add spinner
        <div>
          <Spinner
          key={0}
          className="home-feed-spinner"
          animation="border"
          variant="info"
        />
        </div>
      ) : (
        <Route
          {...rest}
          render={(props) =>
            guardian ? <Component {...props} /> : <Redirect to={LOGIN_ROUTE} />
          }
        />
      )}
    </div>
  );
}

export default GuardianRoute;
