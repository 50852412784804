import { fetchUtils } from '../utils/fetch.utils';
import { getAPIUrl } from '../utils/config.utils';

const BASE_URL = getAPIUrl();
const TREEHOUSE = 'treehouse';
const PARTNER = 'partner';
const CAMP_MASTER = 'camp-master';
const GROUPS = 'groups';

function getAllTreehouses(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/${CAMP_MASTER}/all-camp-master-list?${query}`);
  // return fetchUtils.get(`${BASE_URL}/partner/all?${query}`);
  // return fetchUtils.get(`${BASE_URL}/${TREEHOUSE}/all?${query}`);
}

function subscribeTreehouse(params) {
  // return fetchUtils.get(`${BASE_URL}/${TREEHOUSE}/subscribe-treehouse?treehouse_id=${params}`);
  // return fetchUtils.get(`${BASE_URL}/${PARTNER}/subscribe-partner?partner_id=${params}`);
  return fetchUtils.get(`${BASE_URL}/${CAMP_MASTER}/subscribe/${params}`);
}

function unSubscribeTreehouse(params) {
  // return fetchUtils.get(`${BASE_URL}/${TREEHOUSE}/unsubscribe-treehouse?treehouse_id=${params}`);
  // return fetchUtils.get(`${BASE_URL}/${PARTNER}/unsubscribe-partner?partner_id=${params}`);
  return fetchUtils.get(`${BASE_URL}/${CAMP_MASTER}/unsubscribe/${params}`);
}

function getSubscribedTreehouses(id) {
  // return fetchUtils.get(`${BASE_URL}/${TREEHOUSE}/subscribed-list`);
  // return fetchUtils.get(`${BASE_URL}/partner/subscribed-list`);
  return fetchUtils.get(`${BASE_URL}/${CAMP_MASTER}/subscribed-list/${id}`);
}

function getSubscribedCampMasters(id) {
  return fetchUtils.get(`${BASE_URL}/${CAMP_MASTER}/subscribed-list/${id}`);
}

function getParmanentTreehouses(params) {
  return fetchUtils.get(`${BASE_URL}/partner/partner-list`);
}

function connectTreehouse(params) {
  return fetchUtils.post(`${BASE_URL}/${GROUPS}/connect-to-group/${params.dependent_id}/${params.group_code}`);
  // return fetchUtils.get(`${BASE_URL}/dependents/connect/school?camp_code=${params.treehouse_id}&dependent_id=${params.dependent_id}`, params);
}

function disConnectTreehouse(params) {
  return fetchUtils.post(`${BASE_URL}/${GROUPS}/disconnect-to-group/${params.dependent_id}/${params.treehouse_id}`);
  // return fetchUtils.get(`${BASE_URL}/dependents/disconnect/school?group_id=${params.treehouse_id}&dependent_id=${params.dependent_id}`);
}

function checkTreehouse(params) {
  return fetchUtils.post(`${BASE_URL}/${GROUPS}/code-verification/${params}`);
  // return fetchUtils.post(`${BASE_URL}/school/code-verification`, params);
}

function getTreehouseDetails(id) {
  return fetchUtils.get(`${BASE_URL}/${PARTNER}/public?partner_id=${id}`);
  // return fetchUtils.get(`${BASE_URL}/${TREEHOUSE}/${id}`);
  // return fetchUtils.get(`${BASE_URL}/school/public-school-teacher?teacher_id=${id}`);
}

function getExpertDetails(id) {
  return fetchUtils.get(`${BASE_URL}/${CAMP_MASTER}/details/${id}`);
}

function getTeachersDetails(id) {
  // return fetchUtils.get(`${BASE_URL}/${TREEHOUSE}/${id}`);
  return fetchUtils.get(`${BASE_URL}/school/public-school-teacher?teacher_id=${id}`);
}

function editTreehouse(id, params) {
  return fetchUtils.patch(`${BASE_URL}/${TREEHOUSE}/update/${id}`, params);
}

function deleteTreehouse(id) {
  return fetchUtils.requestDelete(`${BASE_URL}/${TREEHOUSE}/delete/${id}`);
}

function getUpcomingChallenges(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/${PARTNER}/challenges?${query}`);
  // return fetchUtils.get(`${BASE_URL}/${TREEHOUSE}/public-challenges/?partner_id=${id}&availability=upcoming`);
}

function getLiveChallenges(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/${PARTNER}/challenges?${query}`);
  // return fetchUtils.get(`${BASE_URL}/${TREEHOUSE}/public-challenges/?partner_id=${id}&availability=current`);
}

function getPastChallenges(params) {
  const query = fetchUtils.buildQuery(params);
  return fetchUtils.get(`${BASE_URL}/${PARTNER}/challenges?${query}`);
  // return fetchUtils.get(`${BASE_URL}/${TREEHOUSE}/public-challenges/?partner_id=${id}&availability=previous`);
}

function getSchoolBadgeCount(id) {
  return fetchUtils.get(`${BASE_URL}/${TREEHOUSE}/badge_Count/?user_type=school&user_id=${id}`);
}

function getPartnerBadgeCount(id) {
  return fetchUtils.get(`${BASE_URL}/${PARTNER}/public-badge-count?user_type=partner&user_id=${id}`);
}

function getExpertBadgeCount(id) {
  return fetchUtils.get(`${BASE_URL}/${CAMP_MASTER}/public-badge-count?user_id=${id}`);
}

function getCreatorTreehouses(username) {
  // return fetchUtils.get(`${BASE_URL}/${TREEHOUSE}/subscribed-list-public?username=${username}`);
  return fetchUtils.get(`${BASE_URL}/${PARTNER}/subscribed-list-public?username=${username}`);
}

function getCreatorInterests(username) {
  return fetchUtils.get(`${BASE_URL}/tags/public-user-tags?username=${username}`);
}

function getTreehouseBySlug(params) {
  return fetchUtils.get(`${BASE_URL}/users/by_slug?slug=${params}`);
}

function getTribeDetails(id) {
  // return fetchUtils.get(`${BASE_URL}/${TREEHOUSE}/${id}`);
  return fetchUtils.get(`${BASE_URL}/${GROUPS}/camp-captain-by-group/${id}`);
}

function checkTribeName(group_name) {
  return fetchUtils.get(`${BASE_URL}/${GROUPS}/validate_group_name/${group_name}`);
}

export const treehouseRepository = {
  getAllTreehouses,
  subscribeTreehouse,
  getSubscribedTreehouses,
  unSubscribeTreehouse,
  connectTreehouse,
  disConnectTreehouse,
  checkTreehouse,
  editTreehouse,
  getTreehouseDetails,
  getTeachersDetails,
  deleteTreehouse,
  getUpcomingChallenges,
  getPastChallenges,
  getLiveChallenges,
  getSchoolBadgeCount,
  getPartnerBadgeCount,
  getCreatorTreehouses,
  getCreatorInterests,
  getTreehouseBySlug,
  getParmanentTreehouses,
  getSubscribedCampMasters,
  getExpertBadgeCount,
  getExpertDetails,
  getTribeDetails,
  checkTribeName
};
