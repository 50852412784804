import React from 'react';
import { Dropdown, Row, Col, Image } from 'react-bootstrap';
import RoundButton from '../common/RoundButton';
import Avatar from '../common/Avatar';
import { useAuth } from '../auth/Auth';
import { useHistory } from 'react-router-dom';
import { CAMP_ACCOUNT, GUARDIAN_ACCOUNT_DETAILS_ROUTE, GUARDIAN_SETTINGS_ROUTE, MY_ROOM_ROUTE, MY_SCHOOL_ROUTE } from '../../constants/routes.constants';
import { SIMPLE_TYPE } from '../../constants/avatarType.constants';
import veryfiedOrNot from '../../assets/icon/Verified-badge.svg';
import notVerified from '../../assets/icon/Unverified.svg';
import goldTick from '../../assets/icon/gold_tick.svg';
import silverTick from '../../assets/icon/silver_tick.svg';
import greenTick from '../../assets/icon/green_tick.svg';

import './css/profile.menu.scss';

const ProfileMenu = ({ onSettingsClick }) => {
  const { logout, saveDependentUser, dependent, school, partner, guardian } = useAuth();
  const history = useHistory();
  const onDependentClick = (dependent) => {
    // console.log(dependent);
    saveDependentUser(dependent);
    history.replace(MY_ROOM_ROUTE);
  };

  return (
    <Dropdown className="partner-avatar-drop">
      <Dropdown.Toggle
        className={`rounded-pill avatar-button ${partner && 'partner-res-header'}`}
        id="dropdown-basic"
      >
        <Avatar type={SIMPLE_TYPE} />
        {partner ?
          partner.user_type === 'partner' ?
            <span className='varyfied-or-not-partner'>
                <Image src={!partner.is_verified ? notVerified : partner.type_of_organisation === "non profit" ? silverTick : goldTick} alt="" />
            </span>
            : partner?.user_type === 'camp master' ?
              <span style={{ top: '-3px', left: '37px' }} className='varyfied-or-not-partner'>
                <Image src={partner?.is_verified ? veryfiedOrNot : notVerified} alt="" />
              </span>
              : ''
          :
          school ?
            <span className='varyfied-or-not'>
              <Image src={!school.is_verified ? notVerified : school?.user_type === 'school' ? greenTick : veryfiedOrNot} alt="" />
            </span>
            :
            <span className='varyfied-or-not'>
              {dependent && !dependent.profile.is_activated ?
                <Image src={notVerified} alt="" />
                : dependent ? <Image src={veryfiedOrNot} alt="" /> : ''}
            </span>
        }
      </Dropdown.Toggle>
      <Dropdown.Menu className={`${guardian || school ? 'profile-menu-header-guardian' : 'profile-menu-header'} dropdown-menu-right`}>
        <Row className="align-items-center profile-menu-item">
          <Col className="profile-menu-action-text">
            {guardian &&
              <div className='profile-menu-item-wrapper'>
                <p className={`link ${history?.location?.pathname === GUARDIAN_SETTINGS_ROUTE ? 'active' : ''}`} onClick={() => history.push(GUARDIAN_SETTINGS_ROUTE)}>
                  <span className='menu-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" version="1.2" id="home"><path d="M12 3s-6.186 5.34-9.643 8.232A1.041 1.041 0 0 0 2 12a1 1 0 0 0 1 1h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a1 1 0 0 0 1-1 .98.98 0 0 0-.383-.768C18.184 8.34 12 3 12 3z"></path></svg>
                  </span>
                  Dashboard</p>
                <p className={`link ${history?.location?.pathname === GUARDIAN_ACCOUNT_DETAILS_ROUTE ? 'active' : ''}`} onClick={() => history.push(GUARDIAN_ACCOUNT_DETAILS_ROUTE)}>
                  <span className='menu-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" id="user"><path d="M12 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 2a9.985 9.985 0 0 0-8 4 9.985 9.985 0 0 0 8 4 9.985 9.985 0 0 0 8-4 9.985 9.985 0 0 0-8-4z"></path></svg>
                  </span>
                  Account details
                </p>
              </div>
            }
            {school &&
              <div className='profile-menu-item-wrapper'>
                <p className={`link ${history?.location?.pathname === MY_SCHOOL_ROUTE ? 'active' : ''}`} onClick={() => history.push(MY_SCHOOL_ROUTE)}>
                  <span className='menu-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" version="1.2" id="home"><path d="M12 3s-6.186 5.34-9.643 8.232A1.041 1.041 0 0 0 2 12a1 1 0 0 0 1 1h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a1 1 0 0 0 1-1 .98.98 0 0 0-.383-.768C18.184 8.34 12 3 12 3z"></path></svg>
                  </span>
                  Dashboard</p>
                <p className={`link ${history?.location?.pathname === CAMP_ACCOUNT ? 'active' : ''}`} onClick={() => history.push(CAMP_ACCOUNT)}>
                  <span className='menu-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" id="user"><path d="M12 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 2a9.985 9.985 0 0 0-8 4 9.985 9.985 0 0 0 8 4 9.985 9.985 0 0 0 8-4 9.985 9.985 0 0 0-8-4z"></path></svg>
                  </span>
                  Account details
                </p>
              </div>
            }
            <RoundButton
              label="Log out"
              type="button"
              className="logout-button"
              onClick={() => logout()}
            />
          </Col>
        </Row>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileMenu;
