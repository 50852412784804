import React from "react";
import { Dialog, DialogContent, Button, Grid, Typography } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import { Close } from "@mui/icons-material"; // Import ArrowBack icon
import GuardianImage from "../../../../assets/icon/Add_guardian.svg";


const MinPromisePopup = ({ showModal, setShowModal }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const onClose = () => {
        setShowModal(false);
    };

    return (
        <Dialog
            open={showModal}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                style: { borderRadius: "15px", padding: "16px" }
            }}
        >
            {/* Close button */}
            <Button
                onClick={onClose}
                sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    minWidth: "0",
                    padding: "5px",
                }}
            >
                <Close style={{ color: 'black' }} />
            </Button>


            {/* Dialog Content */}
            <DialogContent>
                    <Grid container direction="column" alignItems="center" spacing={3}>
                        {/* Image */}
                        <Grid item>
                            <img
                                src={GuardianImage}
                                alt="Add Guardian"
                                style={{
                                    width: isMobile ? "150px" : "200px",
                                    height: "auto",
                                    marginBottom: "10px",
                                }}
                            />
                        </Grid>

                        {/* Title */}
                        <Grid item>
                            <Typography variant="h6" align="center" fontWeight="bold" color="#321b6d">
                            Join the 20-Minute Promise!
                            </Typography>
                        </Grid>

                    </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default MinPromisePopup;
