const DEPENDENT_USERNAME = 'dependentUsername';

export const saveDependentUsernameToLocalStorage = (user) => {
  localStorage.setItem(DEPENDENT_USERNAME, user);
};

export const removeDependentUsernameFromLocalStorage = (user) => {
  localStorage.removeItem(DEPENDENT_USERNAME);
};

export const getDependentUsernameFromLocalStorage = (user) => {
  return localStorage.getItem(DEPENDENT_USERNAME);
};
