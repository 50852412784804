import React, { useEffect, useState } from 'react';
import SignupStep2 from './SignupStep2';
import SignupStep1 from './SignupStep1';
import { useAuth } from '../../../auth/Auth';
import { Spinner } from 'react-bootstrap';
import SignupProcess from './SignupProcess';
import SignupStep4 from './SignupStep4';
import SignupStep6 from './SignupStep6';

function OptimizeSignup(props) {
    const { guardian } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [signupProcess, setSignupProcess] = useState(false);
    const [isVerified, setIsVerifed] = useState(true);
    const [showPopup1, setShowPopup1] = useState(props.showModal);
    const [showPopup2, setShowPopup2] = useState(false);
    const [showPopup3, setShowPopup3] = useState(false);
    const [showPopup4, setShowPopup4] = useState(false);
    const [showPopup5, setShowPopup5] = useState(false);

    const togglePopup1 = () => {
        props.setShowModal(!props.showModal)
        setShowPopup1(!showPopup1)
    };
    const togglePopup2 = () => setShowPopup2(!showPopup2);
    const togglePopup3 = () => setShowPopup3(!showPopup3);
    const togglePopup4 = () => setShowPopup4(!showPopup4);
    const togglePopup5 = () => setShowPopup5(!showPopup5);

    useEffect(() => {
        if (guardian && !guardian?.is_verified) {
            setIsVerifed(false);
            setSignupProcess(true);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }, [])

    useEffect(() => {
        setShowPopup1(props.showModal)
    }, [props.showModal])

    const handleStep4Complete = () => {
        togglePopup3();
        togglePopup4();
        // setShowStep6(true);
    }

    return (
        isLoading ?
            <Spinner
                as="span"
                className={`${isLoading ? 'spin' : 'd-none'}`}
                animation="border"
                role="status"
                aria-hidden="true"
            />
            :
            <>
                {/* Render popups conditionally */}
                {showPopup1 && <SignupStep1 showModal={showPopup1} onClose={togglePopup1} setShowNextPopup={setShowPopup3} />}
                {showPopup2 && <SignupStep2 onClose={togglePopup2} />}
                {showPopup3 && <SignupStep4 showModal={showPopup3} onClose={togglePopup3} setShowNextPopup={setShowPopup4} handleComplete={handleStep4Complete} />}
            </>
    )
}

export default OptimizeSignup;