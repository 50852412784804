import React, { useEffect } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { LOGIN_ROUTE } from '../../../constants/routes.constants';
import OnboardingWrapper from '../onboarding/OnboardingWrapper';
import { bg } from '../../../constants/component.constants';
import { changeBodyClassName } from '../../../utils/utils';

const ForgotPasswordEmail = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    changeBodyClassName([bg.GRADIENT]);
  }, []);

  const goToLoginRoute = () => {
    let nextRoute = LOGIN_ROUTE;
    if (location.state && location.state.loginRoute) {
      nextRoute = location.state.loginRoute;
    }
    history.replace(nextRoute);
  };
  const subTitle = (
    <span>
      Please check your inbox!
      <br />
      You may need to check your spam folder
    </span>
  );
  return (
    <OnboardingWrapper className="reset-password-container login-container reset-password-background login-container-wrapper">
      <div className="login-form-wrapper reset-password">
        <div className="title">Email Sent</div>
        <div className="subTitle">{subTitle}</div>
        <Row className="mt-3 mb-3 image-layer"></Row>
        <Button
          block
          onClick={goToLoginRoute}
          className="login-btn p-2 gred-btn"
          variant="primary"
          data-testid="submit"
        >
          Confirm
        </Button>
      </div>
    </OnboardingWrapper>
  );
};

export default ForgotPasswordEmail;
