import React from 'react';
import { Image, Navbar } from 'react-bootstrap';
import TaletreeHeader from './TaletreeHeader';
import logo from '../../assets/logo/logo-53x53.svg';
import './css/camp.header.scss';

const EmptyHeaderWithoutSkip = () => {
  return (
    <TaletreeHeader className="camp-header">
      <a href="https://taletree.com">
        <Navbar.Brand>
          <Image src={logo} className="logo" />
        </Navbar.Brand>
      </a>
    </TaletreeHeader>
  );
};

export default EmptyHeaderWithoutSkip;
