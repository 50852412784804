import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { guardianLoginErrors } from '../../../constants/error.constants';
import {
  FORGOT_PASSWORD_ROUTE,
  KID_LOGIN_ROUTE,
} from '../../../constants/routes.constants';
import { useAuth } from '../../auth/Auth';
import OnboardingWrapper from '../onboarding/OnboardingWrapper';
import LoginForm from './LoginForm';
import { FaMapMarkerAlt } from '@react-icons/all-files/fa/FaMapMarkerAlt';
import loginObjectLeft from '../../../assets/images/girl-login.svg'
import loginObjectRight from '../../../assets/images/login-kid-boy.svg'

import './scss/guardian.login.scss';
import { customEvents } from '../../../analytics/customEvents';

const GuardianLogin = () => {
  const location = useLocation();
  // let { message } = location;
  const { guardian, guardianLogin } = useAuth();
  const welcomeMessageRow =
    guardian !== null ? (
      <div className="welcome mt-3 mb-3">Welcome back, {guardian.first_name}!</div>
    ) : (
      location?.state?.first_name ? (<div className="welcome mt-3 mb-3">Welcome back, {location.state.first_name}!</div>) :
        <div className="welcome">&nbsp;</div>
    );

  // useEffect(() => {
  //   changeBodyClassName([bg.GRADIENT]);
  // }, []);

  const onKidLoginClick = () => {
    let analyticsData = {
      category: "Guardian Login",
      action: "are you a kid login here",
      label: 'are you a kid login here'
    }
    customEvents(analyticsData);
  }

  return (
    <OnboardingWrapper className="guardian-login-container login-container login-container-wrapper">
      {/* {welcomeMessageRow} */}
      <div className="welcome mt-3 mb-3">Welcome back!</div>
      <div className="guardian-dashboard">
        <FaMapMarkerAlt /> Guardian Dashboard
      </div>
      <div className="mobile-extra-images">
        <img className='camp-login-girl' src={loginObjectLeft} alt='Camp login image' />
        <img className='camp-login-fire' src={loginObjectRight} alt='Camp login image' />
      </div>
      <LoginForm
        type="guardian"
        title="Guardian Login"
        subTitle="Log in to your family account"
        userInput="Email"
        userPlaceholder="Your e-mail"
        forgotPasswordRoute={FORGOT_PASSWORD_ROUTE}
        errorMessageUsername={guardianLoginErrors.email.required}
        login={guardianLogin}
        forgotPasswordText="Forgot password?"
        isGuardianLogin="true"
      />

      <Row className="are-you" onClick={onKidLoginClick}>
        Are you a kid?&nbsp;
        <Link to={KID_LOGIN_ROUTE}>Log in here</Link>
      </Row>
    </OnboardingWrapper>
  );
};

export default GuardianLogin;
