import React, { useEffect, useState } from 'react';
import { ProvideCreationHolder } from '../context/creation/creation.holder.context';
import { ProvideAuth } from '../auth/Auth';
import { ProvideResponseError } from '../api/response.error';
import StagingAuth from '../staging/StagingAuth.jsx';

export default function MainRoute() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (window.location.hostname === "portal.taletree.app") {
            var hostname = window.location.hostname;
            var newUrl = new URL(window.location.href).toString().replace(hostname, "taletree.com");
            window.location.replace(newUrl);
        } else {
            if (window.location.hostname === "taletree.com") {
                if (window.location.protocol === "http:") {
                    const newUrl = new URL(window.location.href);
                    newUrl.protocol = "https:";
                    window.location.replace(newUrl);
                } else {
                    setLoading(false)
                }
            } else {
                setLoading(false)
            }
        }
    }, [])

    return (
        loading ?
            '' :
            <ProvideCreationHolder>
                <ProvideAuth>
                    <ProvideResponseError>
                        <StagingAuth />
                    </ProvideResponseError>
                </ProvideAuth>
            </ProvideCreationHolder>
    )
}