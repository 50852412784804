import React from 'react';
import { Modal } from 'react-bootstrap';

export default function SignupModal({ className = '', showModal, handleClose, children }) {
    return (
        <Modal className={`signupPopup mobile-v-popup ${className}`} show={showModal} centered>
            <Modal.Body >
                {children}
            </Modal.Body>
        </Modal>
    )
}