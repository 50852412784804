import './assets/scss/main.scss';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { sentryMonitorInit } from './utils/monitor.utils';
import MainRoute from './components/routes/MainRoute';
import { isIOS, osVersion } from 'react-device-detect';

sentryMonitorInit();

function App() {

  const getIosClass = () => {
    // Split the iOS version string by dot (.) to get an array of version segments
    const versionSegments = osVersion.split('.');
    // Extract the first two segments and join them with a dot to get the first two digits
    const firstTwoDigits = versionSegments[0];
    switch (firstTwoDigits) {
      case "14":
        return "ios-fourteen"
      case "13":
        return "ios-thirteen"
      case "12":
        return "ios-twelve"
      case "11":
        return "ios-eleven"
      case "10":
        return "ios-ten"
      default:
        break;
    }
  }

  useEffect(() => {
    let body = document.body;
    if (isIOS) {
      body.classList.add(getIosClass());
    }
  }, [])

  return (
    <BrowserRouter>
      <MainRoute />
    </BrowserRouter>
  );
}
export default App;

const container = document.getElementById('root');
ReactDOM.render(<App />, container);
