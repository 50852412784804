import React from 'react';
import { Form } from 'react-bootstrap';

import './scss/form.input.scss';

const FormInput = React.forwardRef((props, ref) => {
  return (
    <>
      <Form.Control
        {...props}
        ref={ref}
        className={`${props.className} form-input`}
      >
        {props.children}
      </Form.Control>
    </>
  );
});

export default FormInput;
